import { Injectable } from '@angular/core';

import { environment } from 'environments/environment';
import {
  WindowCommand,
  OpenUrlCommand,
  TulltaxanCommand,
  RenderPdfCommand,
  DownloadFileCommand,
  CaseValidationSummaryCommand
} from './windows';
import { DocumentDto, ClearanceCaseDto } from 'api/models';
import { Utils } from 'app/utils';
import { TULLTAXAN_STATNR_URL_TEMPLATE } from 'app/app-constants';
import { OpenCaseCommand } from './windows/open-case-command';
import { LocalStorageService } from 'ngx-webstorage';
import { ValidateCalculateCaseResponse } from 'api/responses';

@Injectable({ providedIn: 'root' })
export class WindowService {
  private static QUEUE_POLL_INTERVAL = 150;

  private reference: Window;
  private ready = false;
  private queue: WindowCommand[] = [];
  private timer: NodeJS.Timeout;
  private lastCommand: WindowCommand;

  public constructor(private readonly localStorage: LocalStorageService) {
    window.addEventListener(
      'message',
      (ev: MessageEvent) => this.handleIncomingMessage(ev),
      false
    );
  }

  public openUrl(url: string): void {
    this.sendCommand(new OpenUrlCommand(url));
  }

  /**
   * Open Tulltaxan in the superwindow
   * @param code Statnr to look up
   * @param direction E for export, I for import
   * @param date Date to use for export/import
   * @param countryCode Country code (ISO 3166 Alpha-2). For exports, this is the receiving country. For imports, this is the origin country
   */
  public tulltaxan(
    code: string,
    direction: 'E' | 'I',
    date: Date,
    countryCode: string
  ): void {
    let url = TULLTAXAN_STATNR_URL_TEMPLATE.replace('{code}', code)
      .replace('{direction}', direction)
      .replace('{date}', Utils.formatDate(date))
      .replace('{countryCode}', countryCode);

    this.sendCommand(new TulltaxanCommand(url));
  }

  public openCase(clearanceCase: ClearanceCaseDto) {
    this.openCaseById(clearanceCase.id);
  }

  public openCaseById(id: string): void {
    let url = `/cases/${id}?windowed=1`;
    this.sendCommand(new OpenCaseCommand(url));
  }

  public openCaseDocumentById(id: string): void {
    let url = `/cases/${id}/documents?windowed=1`;
    this.sendCommand(new OpenCaseCommand(url));
  }

  public openCaseRegistrationById(id: string): void {
    let url = `/cases/convert/${id}?windowed=1`;
    this.sendCommand(new OpenCaseCommand(url));
  }

  public resetCaseValidationSummary() {
    this.localStorage.store('case-validation-response', null);
  }

  public openCaseValidationSummary(data: ValidateCalculateCaseResponse) {
    this.localStorage.store('case-validation-response', data.body);
    let url = `/cases/validation-summary?windowed=1`;
    this.sendCommand(new CaseValidationSummaryCommand(url));
  }

  public openCustomerCompany(id: string): void {
    let url = `/admin/customers/${id}?windowed=1`;
    this.sendCommand(new OpenCaseCommand(url));
  }

  public openCustomerCompanyActor(customerId: string, actorId: string): void {
    let url = `/admin/customers/${customerId}/senders-receivers/${actorId}/edit?windowed=1`;
    this.sendCommand(new OpenCaseCommand(url));
  }

  public renderFile(file: DocumentDto): void {
    switch (file.contentType) {
      case 'application/pdf':
        this.sendCommand(
          new RenderPdfCommand(file.id, file.fileName, file.downloadUrl)
        );
        return;
    }

    this.sendCommand(
      new DownloadFileCommand(file.id, file.fileName, file.downloadUrl)
    );
  }

  private sendCommand(command: WindowCommand): void {
    /*
    Tulltaxan is somewhat of an edge case we need to handle.
    It is:
      * an AngularJS app, so the reverse-proxy and deep linking isn't really working
      * served over HTTP, so using a regular iframe is out
      * unable to detect that the hash part of the URL changes, so reusing it will not work

    The workaround is to just load it directly and pretend that we don't have the superwindow any more.
    Any further actions towards the window will recreate it, reusing the existing window and everything should work fine again.
    */
    if (
      command instanceof TulltaxanCommand ||
      command instanceof OpenCaseCommand ||
      command instanceof CaseValidationSummaryCommand
    ) {
      this.lastCommand = command;

      if (this.reference && !this.reference.closed) {
        if (
          command instanceof CaseValidationSummaryCommand &&
          this.reference.location.href &&
          this.reference.location.href.endsWith(command.url)
        ) {
          return;
        }
        this.reference.location.href = 'about:blank';
        setTimeout(() => {
          this.reference.location.href = command.url;
        }, 100);
      } else {
        this.reference = window.open(
          command.url,
          'ECOMWorker',
          'dependent=yes'
        );
      }

      return;
    }

    if (
      this.reference == null ||
      this.reference.closed ||
      this.lastCommand instanceof TulltaxanCommand
    ) {
      console.debug('Superwindow does not exist or has been closed. Opening');
      this.reference = window.open(
        `${environment.windowOrigin}/js/index.html`,
        'ECOMWorker',
        'dependent=yes'
      );
      this.ready = false;
    }

    this.queue.push(command);
    setTimeout(() => this.processQueue(), WindowService.QUEUE_POLL_INTERVAL);
  }

  private handleIncomingMessage(ev: MessageEvent): void {
    if (
      !environment.apiHost.startsWith(ev.origin) &&
      ev.origin !== environment.windowOrigin
    ) {
      console.warn('Rejected unauthorized message from', ev.origin, ev);
      return;
    }

    if (ev.data === 'ready') this.ready = true;
  }

  private processQueue(): void {
    if (!this.ready) {
      console.debug('Not ready yet, sleeping some more');
      this.startProcessingQueue();
      return;
    }

    const nextCommand = this.queue.shift();
    if (!nextCommand) return;

    this._sendMessage(nextCommand);
    if (this.queue.length > 0) this.startProcessingQueue();
  }

  private _sendMessage(command: WindowCommand): void {
    console.log(
      'Sending message',
      command.toMessage(),
      'to superwindow',
      this.reference
    );
    this.reference.focus();
    this.reference.postMessage(command.toMessage(), environment.windowOrigin);
  }

  private startProcessingQueue(): void {
    if (this.timer) clearTimeout(this.timer);
    this.timer = setTimeout(
      () => this.processQueue(),
      WindowService.QUEUE_POLL_INTERVAL
    );
  }
}
