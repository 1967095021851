import { Translations } from 'app/translations';
import { CaseDifficulties } from 'api/enums';

export class CaseDifficultyLookup {
  public static translate(value: CaseDifficulties | number): string {
    if (typeof value === 'number')
      return Translations.CaseDifficulties[CaseDifficulties[value]];

    return Translations.CaseDifficulties[value];
  }
}
