import {
  Directive,
  OnInit,
  OnDestroy,
  ElementRef,
  Host,
  Optional
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Keyboard } from 'shared/keyboard.enum';
import { Calendar } from 'primeng/calendar';
import { Dropdown } from 'primeng/dropdown';
import { ObjectUtils } from 'primeng/utils';

@Directive({
  selector: '[p-keyboard]'
})
export class PrimeNGKeyboardDirective implements OnInit, OnDestroy {
  readonly KEY_DOWN_EVENT: string = 'keydown';
  readonly FOCUS_IN_EVENT: string = 'focusin';
  readonly FOCUS_OUT_EVENT: string = 'focusout';
  readonly CLICK_EVENT: string = 'click';

  static readonly OPEN_DROPDOWN_KEYS: number[] = [
    Keyboard.DownArrow,
    Keyboard.Enter,
    Keyboard.UpArrow
  ];
  static readonly CLOSE_DROPDOWN_KEYS: number[] = [
    Keyboard.Tab,
    Keyboard.Enter
  ];

  unsubscribe$: Subject<any> = new Subject<any>();

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    @Host() @Optional() private dropdown: Dropdown,
    @Host() @Optional() private calendar: Calendar
  ) {}

  ngOnInit(): void {
    this.replaceDropdownKeyDownAction();
    this.replaceDropdownFilterKeyDownAction();
    this.setCalendarFocusOnClick();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.unsubscribe();
  }

  private setCalendarFocusOnClick() {
    if (!this.calendar) {
      return;
    }

    this.calendar.showOnFocus = false;
    // Focus the calendar input field when an item is selected.
    this.calendar.onSelect
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.calendar.inputfieldViewChild.nativeElement.focus());

    // Show calendar when the component is clicked
    fromEvent(this.elementRef.nativeElement, this.CLICK_EVENT)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(event => {
        this.calendar.showOverlay();
        this.calendar.cd.markForCheck();
        event.stopPropagation();
      });
  }

  private replaceDropdownFilterKeyDownAction() {
    if (!this.dropdown) {
      return;
    }

    const onKeyDownOriginFn = this.dropdown.onFilterKeyDown.bind(this.dropdown);
    this.dropdown.onFilterKeyDown = (event: KeyboardEvent) => {
      if (event.key !== 'Tab') {
        onKeyDownOriginFn(event);
        return;
      }
      this.dropdown.hide();
      this.dropdown.focus();
    };
    this.dropdown.onFilter.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      const options = this.dropdown.visibleOptions();
      if (options.length > 0) {
        const value = !!this.dropdown.optionValue
          ? ObjectUtils.resolveFieldData(options[0], this.dropdown.optionValue)
          : options[0];
        this.dropdown.focusedOptionIndex.set(0);
        this.dropdown.selectedOption = value;
        this.dropdown.selectedOptionUpdated = true;
      }
    });
  }

  private replaceDropdownKeyDownAction() {
    if (!this.dropdown) {
      return;
    }

    const onKeyDownOriginFn = this.dropdown.onKeyDown.bind(this.dropdown);
    this.dropdown.onKeyDown = (event: KeyboardEvent, search: boolean) => {
      if (
        this.isDropdownOpenEvent(event) ||
        (!this.dropdown.overlayVisible && this.hasLengthOne(event))
      ) {
        this.dropdown.show();
        event.stopPropagation();
      } else if (this.isDropdownClearEvent(event)) {
        this.dropdown.clear(null);
        event.stopPropagation();
      } else {
        const wasOpen = this.dropdown.overlayVisible;
        onKeyDownOriginFn(event, search);
        if (
          wasOpen &&
          !this.dropdown.overlayVisible &&
          !this.dropdown.focused &&
          PrimeNGKeyboardDirective.CLOSE_DROPDOWN_KEYS.includes(event.which)
        ) {
          this.dropdown.focus();
        }
      }
    };
  }

  private hasLengthOne(event: KeyboardEvent): boolean {
    return event.key.length === 1;
  }

  private isDropdownOpenEvent(event: KeyboardEvent): boolean {
    return (
      PrimeNGKeyboardDirective.OPEN_DROPDOWN_KEYS.includes(event.which) &&
      !this.dropdown.overlayVisible
    );
  }

  private isDropdownClearEvent(event: KeyboardEvent): boolean {
    return (
      this.dropdown &&
      !this.dropdown.overlayVisible &&
      this.dropdown.showClear &&
      (event.which === Keyboard.Delete || event.which === Keyboard.Backspace)
    );
  }
}
