import {
  Input,
  Component,
  ViewChild,
  ChangeDetectorRef,
  ViewEncapsulation,
  ElementRef
} from '@angular/core';
import { isObservable } from 'rxjs';

@Component({
  selector: 'base-modal-layout',
  styleUrls: ['./base-modal-layout.component.css'],
  encapsulation: ViewEncapsulation.None,
  template: ` <div
    [id]="identifier"
    uk-modal
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    #modalDiv
  >
    <div
      class="uk-modal-dialog {{ size ? 'uk-modal-dialog-' + size : '' }}"
      uk-overflow-auto
    >
      <div class="uk-modal-header">
        <h4 class="modal-title pull-left">
          <strong>{{ title }}</strong>
        </h4>
        <button class="uk-modal-close-default" type="button" uk-close></button>
      </div>
      <div class="uk-modal-body">
        <ng-content></ng-content>
      </div>
      <div class="uk-modal-footer" #modalFooterContainer *ngIf="hasFooter">
        <ng-content select="[modal-footer]"></ng-content>
      </div>
    </div>
  </div>`
})
export class BaseModalLayoutComponent {
  @Input() title: string;
  @Input() identifier: string;
  @Input() class: string;
  @Input() size: string;

  @ViewChild('modalFooterContainer') modalFooterContainer;
  @ViewChild('modalDiv') modalDiv: ElementRef<HTMLElement>;

  // Has to be true so the first check can be run and update
  public hasFooter = true;

  constructor(private cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.hasFooter =
      this.modalFooterContainer.nativeElement &&
      this.modalFooterContainer.nativeElement.children.length > 0;

    // Have a quick look around to avoid the Expression Changed After It Has Been Checked error
    this.cdRef.detectChanges();
  }
}
