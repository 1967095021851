import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

@Injectable()
export class CachedRequestService {
  public handleRequestWithCache<T>(
    cache: Map<string, T>,
    ongoingRequests: Map<string, Observable<T>>,
    key: string,
    requestGenerator: () => Observable<T>,
    errorHandler: (error: any) => Observable<T>
  ): Observable<T> {
    const cachedResult = cache.get(key);

    if (cachedResult) {
      return of(cachedResult);
    } else {
      let ongoingRequest = ongoingRequests.get(key);

      if (!ongoingRequest) {
        ongoingRequest = requestGenerator().pipe(
          map(response => {
            cache.set(key, response);
            ongoingRequests.delete(key);
            return response;
          }),
          catchError(error => {
            ongoingRequests.delete(key);
            return errorHandler(error);
          }),
          shareReplay(1)
        );

        ongoingRequests.set(key, ongoingRequest);
      }

      return ongoingRequest;
    }
  }
}
