import {
  Input,
  Component,
  ViewEncapsulation,
  EventEmitter,
  Output,
  AfterContentInit,
  ContentChildren,
  TemplateRef,
  QueryList,
  OnInit,
  OnDestroy,
  ChangeDetectorRef
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, of, merge, Subject } from 'rxjs';

import { PrimeTemplate } from 'primeng/api';

import { SelectItem } from 'shared/select-item.class';
import { Collapse } from 'shared/animations/collapse.animation';
import { AddonConfig } from './models/addon-config';
import { AddonAction } from './models/addon-action';
import { DatepickerLocale } from 'shared/datepicker_locale';
import { AbstractControlWithWarnings } from 'shared/AbstractControlWithWarnings';
import { SelectItemGroup } from 'shared/select-item-group.class';
import { flatMap } from 'lodash-es';
import { Disablable } from './disablable.interface';
import { filter, takeUntil, debounceTime } from 'rxjs/operators';
import { KeyFilterPattern } from 'primeng/keyfilter';

type FormElementType =
  | 'text'
  | 'textarea'
  | 'select'
  | 'checkbox'
  | 'selectbutton'
  | 'listbox'
  | 'password'
  | 'email'
  | 'switch'
  | 'errors'
  | 'date'
  | 'chips'
  | 'number';

@Component({
  selector: 'uikit-form-element',
  styleUrls: ['./uikit-form-element.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [Collapse()],
  templateUrl: './uikit-form-element.component.html'
})
export class UikitFormElementComponent
  implements AfterContentInit, OnInit, Disablable, OnDestroy
{
  @Input() control: AbstractControlWithWarnings;
  @Input() label: string | Observable<string> = '';
  @Input() name: string;
  @Input() id: string;
  @Input() inputId: string;
  @Input() step: string | number = 1;
  @Input() multiple = false;
  @Input() items: SelectItem[];
  @Input() itemGroups: SelectItemGroup[];
  @Input() placeholder: string | Observable<string> = '';
  @Input() showClear = false;
  @Input() hidden = false;
  @Input() selectedDisplayTitle: (item: SelectItem) => string = item =>
    item.label;
  @Input() itemDisplayTitle: (item: SelectItem) => string = item => item.label;
  @Input() displayOnly;
  @Input() displayOnlyValueOverrideText: string;
  @Input() forceDisplayOnlyPlaceholder = false;
  @Input() groupLabel = '';
  @Input() helpTexts: string[] = [];
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
  @Input() errorGroup: AbstractControl;
  @Input() readonly = false;
  @Input() customKeyFilter: KeyFilterPattern = null;
  @Input() calendarLocale: DatepickerLocale;
  @Input() showWeek: true;
  @Input() dateformat = 'yy-mm-dd';
  @Input() styleClass: '';
  @Input() inputStyleClass = '';
  @Input() noLabel = false;
  @Input() autocomplete: 'default' | 'off' | 'on' = 'off';
  @Input() autoCompleteMethod: (query: string) => string[] = null;
  @Input() autoCompleteSuggestions: string[];
  @Input() tabIndex: number = 0;
  @Input() format:
    | 'none'
    | 'int'
    | 'decimal'
    | 'price'
    | 'currency'
    | 'statnr' = 'none';
  @Input() flag: string;
  @Input() disabled: boolean;
  @Input() hideValidation: boolean;
  @Input() filterBy = 'filterValue,label';
  @Input() filterByFirstMatch = true;
  @Input() readOnlyInput = true;
  @Input() canCalculate = false;
  @Input() labelTooltip: string;
  @Input() fixedSize: boolean = false;
  @Input() fixedMinSize: boolean = false;

  _submitted: boolean = false;
  @Input() set submitted(value: boolean) {
    this._submitted = value;

    if (this.triggerChange$) {
      this.triggerChange$.next();
    }
  }

  @Input() type: FormElementType = 'text';
  typeIsInputGroup: boolean;
  typeIsInput: boolean;
  inputType: string;

  @Input() set addons(addons: AddonConfig[]) {
    this.addonsLeft = addons
      ? addons
          .filter(a => a.left)
          .map(a => {
            a.tabIndex = a.tabIndex === undefined ? null : a.tabIndex;
            a.disabled = this.control.disabled ? true : false;
            return a;
          })
      : [];

    this.addonsRight = addons
      ? addons
          .filter(a => !a.left)
          .map(a => {
            a.tabIndex = a.tabIndex === undefined ? null : a.tabIndex;
            a.disabled = this.control.disabled ? true : false;
            return a;
          })
      : [];
  }
  addonsLeft: AddonConfig[];
  addonsRight: AddonConfig[];

  @Output() onInit: EventEmitter<any> = new EventEmitter();
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onAddonAction: EventEmitter<AddonAction> = new EventEmitter();
  @Output() onHide: EventEmitter<boolean> = new EventEmitter();
  @Output() onCalendarInput: EventEmitter<any> = new EventEmitter();

  validationStatusClass: string = '';
  hasError: boolean;
  hasSuccess: boolean;
  hasWarning: boolean;
  showError: boolean;
  showWarning: boolean;
  showSuccess: boolean;
  errors: string[];
  warnings: string[];

  @ContentChildren(PrimeTemplate) templates: QueryList<any>;
  selectedItemTemplate: TemplateRef<any>;
  itemTemplate: TemplateRef<any>;

  ngUnsubscribe$ = new Subject();
  triggerChange$ = new Subject();

  ngAfterContentInit() {
    this.templates.forEach((item: PrimeTemplate) => {
      if (item.name == 'selectedItem') {
        this.selectedItemTemplate = item.template;
      } else if (item.name == 'item') {
        this.itemTemplate = item.template;
      }
    });

    this.triggerChange$.next();
  }

  public isType(...types: string[]) {
    return types.includes(this.type);
  }

  public isFormat(...formats: string[]) {
    return formats.includes(this.format);
  }

  get inputClass(): string {
    return `ukfe ukfe-${this.size}${this.fixedSize ? ' fixed-size' : ''}${this.fixedMinSize ? ' fixed-min-size' : ''}`;
  }

  get selectedItem() {
    if (this.items) return this.items.find(i => i.value === this.control.value);

    if (this.itemGroups)
      return flatMap(this.itemGroups.map(g => g.items)).find(
        i => i.value === this.control.value
      );

    return null;
  }

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.onInit.emit();

    this.typeIsInputGroup = this.isType(
      'text',
      'email',
      'password',
      'select',
      'date',
      'addon-only',
      'number'
    );
    this.typeIsInput = this.isType('text', 'email', 'password', 'number');

    if (!this.control) {
      return;
    }

    let validationObservables = [
      this.control.statusChanges,
      this.triggerChange$.asObservable()
    ];

    if (this.errorGroup) {
      validationObservables.push(this.errorGroup.statusChanges);
    }

    merge(...validationObservables)
      .pipe(
        filter(
          () => this.control.dirty || this.control.touched || this._submitted
        ),
        debounceTime(100),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(() => {
        const errors = Object.keys(this.control.errors || {});
        const groupErrors =
          this.errorGroup != null
            ? Object.keys(this.errorGroup.errors || {})
            : [];
        const hasError = errors.length > 0;
        const hasGroupError = groupErrors.length > 0;
        this.hasError = hasError || hasGroupError;
        this.hasWarning =
          this.control.warnings != null &&
          Object.keys(this.control.warnings).length > 0;
        this.hasSuccess = !this.hasWarning && !this.hasError;
        this.showWarning =
          !this.displayOnly && !this.hideValidation && this.hasWarning;
        this.showError =
          !this.displayOnly && !this.hideValidation && this.hasError;
        this.showSuccess =
          !this.displayOnly && !this.hideValidation && this.hasSuccess;
        this.errors = errors;
        this.warnings = this.hasWarning
          ? Object.keys(this.control.warnings)
          : [];

        if (this.showError) {
          this.validationStatusClass = 'uk-form-danger';
        } else if (this.showWarning) {
          this.validationStatusClass = 'uk-form-warning';
        } else if (this.showSuccess) {
          this.validationStatusClass = 'uk-form-success';
        } else {
          this.validationStatusClass = '';
        }

        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.triggerChange$.complete();
  }

  public onSelectChange(event) {
    this.onChange.emit(event);
  }

  public onHideDropdown() {
    this.onHide.emit(true);
  }

  public calendarShown(event: any): void {
    let active = event.element.querySelector('.ui-state-active');
    if (active) {
      active.focus();
      return;
    }

    let highlight = event.element.querySelector('.ui-state-highlight');
    if (highlight) {
      highlight.focus();
      return;
    }

    let def = event.element.querySelector('.ui-state-default');
    if (def) {
      def.focus();
      return;
    }
  }

  get isRequired(): boolean {
    if (this.control && this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  get isAutoComplete(): boolean {
    return this.autoCompleteMethod != null;
  }

  public get labelText(): Observable<string> {
    if (typeof this.label === 'string')
      return of(this.label != null && this.label != null ? this.label : '');

    return this.label;
  }

  public getError(key) {
    return this.control?.errors ? this.control?.errors[key] : null;
  }

  public addonIsClickable(cfg: AddonConfig): boolean {
    return !!cfg.action;
  }

  public addonIsCheckbox(cfg: AddonConfig): boolean {
    return !!cfg.checkControl && !!cfg.checkName;
  }
  public clickAddon(addon: AddonConfig, event: any) {
    this.onAddonAction.emit({
      controlValue: this.control.value,
      action: addon.action,
      clickEvent: event
    });
  }

  public get emptyPlaceholder(): boolean {
    const noPlaceholder =
      this.placeholder == null ||
      (typeof this.placeholder === 'string' && this.placeholder) === '';

    return noPlaceholder && this.type === 'select' && this.showClear;
  }

  public get placeholderText(): Observable<string> {
    if (this.disabled) {
      return of('-');
    }

    if (typeof this.placeholder === 'string') {
      return of(
        this.placeholder != null && this.placeholder != null
          ? this.placeholder
          : ''
      );
    }

    return this.placeholder;
  }

  public get displayOnlyInputText(): Observable<string> {
    if (this.forceDisplayOnlyPlaceholder) return this.placeholderText;

    if (this.control && this.control.value) return of(this.control.value);

    return of('');
  }

  public get displayOnlyTextareaText(): string {
    return this.control.value;
  }

  public tooltipText(addon: AddonConfig): Observable<string> {
    if (!addon.tooltip || addon.disabled) return of('');

    if (typeof addon.tooltip === 'string')
      return of(
        addon.tooltip != null && addon.tooltip != undefined ? addon.tooltip : ''
      );

    return addon.tooltip;
  }

  public disable(disable: boolean) {
    this.disabled = disable;
    this.hideValidation = disable;
  }

  preventDrag(event: DragEvent) {
    event.preventDefault();
  }

  emitOnCalendarInput(event: any) {
    this.onCalendarInput.emit(event);
  }
}
