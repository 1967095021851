import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Subscription, Observable, isObservable, of } from 'rxjs';
import { OverlayPanel } from 'primeng/overlaypanel';
import { ConfirmConfig } from './confirm-config';

@Component({
  selector: 'ecom-confirm-popover',
  templateUrl: './confirm.popover.html',
  styleUrls: ['./confirm.popover.css']
})
export class ConfirmPopover implements OnDestroy {
  protected _identifier: string;
  protected serviceDestroyCallback: (id: string) => void;
  subscriptions: Subscription[] = [];
  config: ConfirmConfig;

  @ViewChild(OverlayPanel, { static: true })
  confirmPopover: OverlayPanel;

  constructor() {
    this.config = new ConfirmConfig();
    this.config.title = 'shared.form.confirm';
    this.config.message = 'shared.form.confirmMessage';
    this.config.confirmText = 'shared.form.confirm';
    this.config.cancelText = 'shared.form.cancel';
  }

  initializeComponent(
    identifier: string,
    serviceDestroyCallback: (id: string) => void
  ) {
    this._identifier = identifier;
    this.serviceDestroyCallback = serviceDestroyCallback;
    this.subscriptions.push(
      this.confirmPopover.onHide.subscribe(() =>
        this.serviceDestroyCallback(this._identifier)
      )
    );
  }

  setTitle(title: string): ConfirmPopover {
    this.config.title = title;
    return this;
  }

  setMessage(message: string): ConfirmPopover {
    this.config.message = message;
    return this;
  }

  setConfirmButton(
    onConfirm: () => void,
    text: string = '',
    icon: string = '',
    buttonClass: string = ''
  ): ConfirmPopover {
    this.config.onConfirm = onConfirm;
    this.config.confirmText = text || this.config.confirmText;
    this.config.confirmIcon = icon || this.config.confirmIcon;
    this.config.confirmClass = buttonClass || this.config.confirmClass;

    return this;
  }

  setCancelButton(
    onCancel: () => void = () => {},
    text: string = '',
    icon: string = '',
    buttonClass: string = ''
  ): ConfirmPopover {
    this.config.onCancel = onCancel;
    this.config.cancelText = text || this.config.cancelText;
    this.config.cancelIcon = icon || this.config.cancelIcon;
    this.config.cancelClass = buttonClass || this.config.cancelClass;

    return this;
  }

  confirm(originalEvent: any) {
    this.confirmPopover.show(originalEvent);
  }

  onConfirm() {
    this.config.onConfirm();
    this.confirmPopover.hide();
  }

  onCancel() {
    this.config.onCancel();
    this.confirmPopover.hide();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
  }

  textAsync(text: string | Observable<string>): Observable<string> {
    return isObservable(text) ? text : of(text);
  }
}
