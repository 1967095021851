import {
  Component,
  Input,
  ViewEncapsulation,
  Output,
  EventEmitter,
  OnChanges,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
  ViewChild
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { Collapse } from 'shared/animations/collapse.animation';
import { Disablable } from './disablable.interface';
import { QuickEntryBaseComponent } from './quickentry-base.component';
import { LazyLoadEvent, SortEvent } from 'primeng/api';
import { takeUntil } from 'rxjs/operators';
import { ClearanceCaseRowForm } from 'cases/models/forms';
import { PaginationRowService } from 'shared/services/pagination-row-service';
import { Table, TableLazyLoadEvent } from 'primeng/table';
import { equals } from 'shared/busy/util';

@Component({
  selector: 'ecom-direct-access-quickentry',
  templateUrl: 'direct-access-quickentry.component.html',
  styleUrls: ['./direct-access-quickentry.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [Collapse()],
  providers: [PaginationRowService]
})
export class DirectAccessQuickEntryComponent
  extends QuickEntryBaseComponent
  implements Disablable, AfterViewInit, OnChanges, OnInit {
  // Named formArrayInternal to match the template.
  @Input('formArray') formArrayInternal: UntypedFormArray;
  @Input() searchForm: UntypedFormGroup;
  // Named removeRowEmitter since removeRow is the name of the function used in the template.
  // Emits tuple containing the row index and the click event.
  @Output('removeRow') removeRowEmitter = new EventEmitter<[number, any]>();

  @ViewChild('quickentryPaginator') quickentryPaginator: Table;
  filterValue: string;
  filteredRows: ClearanceCaseRowForm[];
  sortedRows: ClearanceCaseRowForm[];
  constructor(
    public paginationRowService: PaginationRowService,
    public cdr: ChangeDetectorRef
  ) {
    super(cdr);
  }

  canRemoveRow(row: UntypedFormGroup): boolean {
    return !this.displayOnly && !this.disabled;
  }

  removeRow(i: number, clickEvent: any) {
    this.removeRowEmitter.emit([i, clickEvent]);
  }

  ngOnInit() {
    this.filterValue = this.searchForm.get('searchField').value;
    if (this.filterValue != null) {
      this.filterRows();
    }
    this.searchForm.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(filter => {
        if (this.filterValue == filter.searchField) return;
        this.filterValue = filter.searchField;
        this.filterRows();
      });
  }

  filterRows() {
    this.paginationRowService.updateMetaValues(
      this.quickentryPaginator?.value ?? [],
      true
    );
    this.cdr.markForCheck();
    this.updateFilteredRows();
  }

  updateFilteredRows() {
    setTimeout(() => {
      this.filteredRows = this.paginationRowService.searchFilter(
        this.rows,
        this.filterValue
      );
      this.updateSortField();
      this.paginationRowService.updateIsLoading(
        this.quickentryPaginator?.value ?? []
      );

      this.paginationRowService.first = 0;
      this.cdr.markForCheck();
    }, 50);
  }

  updateSortField() {
    if (!!this.paginationRowService.sortField) {
      this.sortedRows = this.paginationRowService.sortFields(this.rowsToShow);
    }
  }

  getRowIndex(rowForm: ClearanceCaseRowForm): number {
    const rows = this.rows;
    return rows.indexOf(rowForm);
  }

  loadRows(event: TableLazyLoadEvent) {
    this.paginationRowService.updateMetaValues(
      this.quickentryPaginator?.value ?? [],
      true
    );
    this.cdr.markForCheck();
    setTimeout(() => {
      this.paginationRowService.setEventValues(event);
      this.updateSortField();
      this.paginationRowService.updateIsLoading(
        this.quickentryPaginator?.value ?? []
      );
      this.cdr.markForCheck();
    }, 50);
  }

  get leng() {
    return this.formArrayInternal.controls.length;
  }
  get rows(): ClearanceCaseRowForm[] {
    const rows = this.formArrayInternal.controls as ClearanceCaseRowForm[];

    return !!this.rowFilter
      ? rows.filter(row => this.rowFilter.showRow(row))
      : rows;
  }

  get rowsToShow() {
    return this.filteredRows || this.rows;
  }

  get rowsToSort() {
    return this.rowsToShow.filter(r => r.isInitialized);
  }

  addInitialRow() {
    const rows = !!this.sortedRows ? this.sortedRows : this.rowsToShow;
    if (!rows.find(a => !a.isInitialized) && rows.length < this.rows.length) {
      rows.push(this.rows[this.rows.length - 1]);
    }
  }

  get rowsToEdit() {
    const rows = !!this.sortedRows ? this.sortedRows : this.rowsToShow;
    if (!rows.find(a => !a.isInitialized) && rows.length < this.rows.length) {
      rows.push(this.rows[this.rows.length - 1]);
    }
    return this.paginationRowService.getRows(rows);
  }

  get isLoading() {
    if (!this.quickentryPaginator || !this.paginationRowService.isLoading)
      return false;
    const isLoading = this.paginationRowService.getIsLoading(
      this.quickentryPaginator?.value ?? []
    );

    if (!isLoading) {
      this.paginationRowService.updateMetaValues(
        this.quickentryPaginator?.value ?? [],
        false
      );
      this.cdr.markForCheck();
    }

    return this.paginationRowService.isLoading;
  }
}
