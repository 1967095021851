import { CustomsOfficeDto } from 'api/models';
import { CustomsOfficeTypes } from 'api/enums';

export class CustomsOffice {
  id: string;
  idEu: string;
  idNo: string;
  name: string;
  types: number;

  text: string;

  public constructor(json: CustomsOfficeDto) {
    this.id = json.id;
    this.idEu = json.idEu;
    this.idNo = json.idNo;
    this.name = json.name;
    this.types = parseInt(json.types.toString(10), 10);

    this.text = `[${this.idEu || this.idNo}] ${this.name}`;
  }

  isTransit(): boolean {
    return (
      (this.types & CustomsOfficeTypes.Transit) === CustomsOfficeTypes.Transit
    );
  }

  isTransitDeparture(): boolean {
    return (
      (this.types & CustomsOfficeTypes.TransitDeparture) ===
      CustomsOfficeTypes.TransitDeparture
    );
  }

  isTransitDestination(): boolean {
    return (
      (this.types & CustomsOfficeTypes.TransitDestination) ===
      CustomsOfficeTypes.TransitDestination
    );
  }

  isBorderSe(): boolean {
    return (
      (this.types & CustomsOfficeTypes.BorderSe) === CustomsOfficeTypes.BorderSe
    );
  }

  isBorderNo(): boolean {
    return (
      (this.types & CustomsOfficeTypes.BorderNo) === CustomsOfficeTypes.BorderNo
    );
  }
}
