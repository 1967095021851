import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetAllErpArticlesResponse } from 'api/financials/responses/get-all-erp-articles-response';
import { GetCustomerInvoiceRecipientsResponse } from 'api/financials/responses/get-customer-invoice-recipients-response';
import { DefaultGetResult } from 'api/results';
import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SharedFinancialsService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/financials/', errorService);
  }

  public getAllErpArticles(): Observable<GetAllErpArticlesResponse> {
    return this.get<GetAllErpArticlesResponse, DefaultGetResult>(
      'erp-articles/all'
    ).pipe(
      map(response => new GetAllErpArticlesResponse(response)),
      catchError(error => this.onError(new GetAllErpArticlesResponse(error)))
    );
  }

  getInvoiceRecipients(
    customerId: string
  ): Observable<GetCustomerInvoiceRecipientsResponse> {
    return this.get<GetCustomerInvoiceRecipientsResponse, DefaultGetResult>(
      `customers/${customerId}/invoicerecipients`
    ).pipe(
      map(response => new GetCustomerInvoiceRecipientsResponse(response)),
      catchError(error =>
        this.onError(new GetCustomerInvoiceRecipientsResponse(error))
      )
    );
  }
}
