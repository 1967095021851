<table
  class="uk-table uk-table-hover"
  [class.sticky-top]="sticky == 'top'"
  [class.sticky-toolbar]="sticky == 'toolbar'"
>
  <thead>
    <tr>
      <th *ngFor="let column of orderedColumns" class="{{ column.thClass }}">
        {{ getColumnTitleText(column) | async }}
        <form-tooltip-icon
          *ngIf="column.labelTooltip"
          [tooltipText]="column.labelTooltip"
        >
        </form-tooltip-icon>
      </th>

      <th class="microCol"></th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let formGroup of rows; let i = index">
      <tr
        *ngIf="
          i + 1 < formArrayInternal.controls.length ||
          (!displayOnly && !disabled)
        "
        [class]="trClassFunc(formGroup)"
        data-uk-tooltip="{pos:'top'}"
        [title]="trToolTipFunc(formGroup)"
      >
        <td *ngFor="let column of orderedColumns" class="{{ column.tdClass }}">
          <ng-container
            *ngTemplateOutlet="
              column.columnTemplate;
              context: { formGroup: formGroup, displayOnly: displayOnly }
            "
          ></ng-container>
        </td>
        <td class="uk-flex">
          <ng-container *ngIf="canRemoveRow(formGroup)">
            <a
              class="remove-entry pull-right"
              title="{{ 'shared.form.delete' | translate }}"
              (click)="removeRow(i)"
              *ngIf="i + 1 < formArrayInternal.controls.length"
              uk-tooltip
            >
              <i class="fa fa-times fa-fw"></i>
            </a>
          </ng-container>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>
