import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { GetAdministrativeUnitsResponse } from 'api/responses';
import { GetAdministrativeUnitsResult } from 'api/results';

@Injectable()
export class AdministrativeUnitsService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/administrativeunits/', errorService);
  }

  getAll(): Observable<GetAdministrativeUnitsResponse> {
    return this.get<
      GetAdministrativeUnitsResponse,
      GetAdministrativeUnitsResult
    >('').pipe(
      map(response => new GetAdministrativeUnitsResponse(response)),
      catchError(error =>
        this.onError(new GetAdministrativeUnitsResponse(error))
      )
    );
  }
}
