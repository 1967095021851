import { Input, OnInit, OnDestroy, Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'delayed-input',
  template: `<input
    name="{{ name }}"
    id="{{ id || name }}"
    [formControl]="inputControl"
    type="text"
    placeholder="{{ placeholder }}"
    class="{{ !customClass ? 'uk-input' : customClass }}"
    (change)="onChange()"
  />`
})
export class DelayedInputComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$ = new Subject();

  @Input() control: UntypedFormControl;
  @Input() name: string;
  @Input() id: string;
  @Input() delay: number = 1000;
  @Input() placeholder: string = '';
  @Input() customClass: string = '';
  @Input() updateOnChange: boolean = false;

  inputControl: UntypedFormControl;
  initialized: boolean = false;

  subscriptions: Subscription[] = [];

  constructor() {}
  onChange() {
    if (this.updateOnChange) {
      const term = this.inputControl.value;
      if (this.control.value === term) {
        return;
      }
      this.control.setValue(term);
    }
  }
  ngOnInit(): void {
    this.inputControl = new UntypedFormControl(this.control.value);

    this.inputControl.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(term => {
        if (this.control.value === term) {
          return;
        }
        this.control.setValue(term);
      });
    this.control.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(term => this.inputControl.setValue(term));

    this.initialized = true;
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
