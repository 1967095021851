import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { GetPresenceResponse } from 'api/responses';
import { GetPresenceResult } from 'api/results';

@Injectable()
export class PresenceService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/presence/', errorService);
  }

  getPresence(): Observable<GetPresenceResponse> {
    return this.get<GetPresenceResponse, GetPresenceResult>('').pipe(
      map(response => new GetPresenceResponse(response)),
      catchError(error => this.onError(new GetPresenceResponse(error)))
    );
  }
}
