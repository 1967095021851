import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'shared/select-item.class';
import { Observable, forkJoin } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { SelectItemGroup } from 'shared/select-item-group.class';

export abstract class BaseRepositoryService {
  constructor(protected translateService: TranslateService) {}

  protected translateSelectItems(
    items: SelectItem[]
  ): Observable<SelectItem[]> {
    return forkJoin([
      ...items.map(i => this.translateService.get(i.label))
    ]).pipe(
      map(res =>
        items.map((item, i, a) => {
          if (item.filterValue == item.label) {
            item.filterValue = res[i];
          }
          item.label = res[i];
          return item;
        })
      ),
      shareReplay()
    );
  }

  protected translateSelectItemGroups(groups: {
    [key: string]: Observable<SelectItem[]>;
  }): Observable<SelectItemGroup[]> {
    return forkJoin([
      ...Object.keys(groups).map(key =>
        forkJoin({
          title: this.translateService.get(key),
          items: groups[key]
        }).pipe(map(g => new SelectItemGroup(g.title, g.items)))
      )
    ]).pipe(shareReplay());
  }
}
