import { UntypedFormArray } from '@angular/forms';
import { ClearanceCaseInvoiceDto } from 'api/models';
import { InvoiceForm } from './invoice.form';

export class InvoicesFormArray extends UntypedFormArray {
  constructor(invoices: ClearanceCaseInvoiceDto[] = null) {
    super((invoices || []).map(i => new InvoiceForm(i)));
  }

  setInvoices(invoices: ClearanceCaseInvoiceDto[]) {
    alert(invoices);
  }
}
