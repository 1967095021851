import { SelectItem as PrimeNgSelectItem } from 'primeng/api/selectitem';

export class SelectItem implements PrimeNgSelectItem {
  public label?: string;
  public filterValue?: string;
  public value: any;
  public styleClass?: string;
  public icon?: string;
  public title?: string;
  public disabled?: boolean;

  public constructor(
    value: string | number,
    label: string,
    icon?: string,
    filterValue?: string
  ) {
    this.value = value;
    this.label = label;
    this.icon = icon;
    this.filterValue = filterValue || label;
  }
}
