import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { environment } from 'environments/environment';
import { ErrorService } from 'core/error.service';
import { IError } from 'core/ierror.interface';
import { IResponseWithErrorGeneric } from 'api/shared';

import * as FileSaver from 'file-saver';

export class BaseService {
  protected http: HttpClient;
  protected prefix = '';
  protected errorService: ErrorService;

  constructor(http: HttpClient, prefix = '', errorService: ErrorService) {
    this.http = http;
    this.prefix = prefix;
    this.errorService = errorService;
  }

  protected mapResultAndError<TResult>(
    body: any,
    response: IResponseWithErrorGeneric<TResult>,
    defaultResult: TResult
  ) {
    response.result = body.result || defaultResult;
    response.error = body.error || body.modelState;
  }

  protected mapError<TResult>(
    error: Response | any,
    response: IResponseWithErrorGeneric<TResult>,
    defaultResult: TResult
  ) {
    if (error instanceof Response) {
      const body = error.json() || '';
      this.mapResultAndError(body, response, defaultResult);
    } else {
      response.result = defaultResult;
      response.error = error.message ? error.message : error.toString();
    }
    console.error(`${response.result} - ${response.error}`);
    this.errorService.next({ message: response.error });
    return observableThrowError(response);
  }

  protected onError<TResult>(apiResponse: IResponseWithErrorGeneric<TResult>) {
    console.error(`${apiResponse.result} - ${apiResponse.error}`, apiResponse);
    const error: IError = {
      message: apiResponse.error,
      validationErrors: apiResponse.requestValidationErrors
    };

    if (apiResponse.hasOwnProperty('httpStatus')) {
      error.httpStatus = apiResponse['httpStatus'];
      error.httpStatusText = apiResponse['httpStatusText'];
    }

    this.errorService.next(error);
    return observableThrowError(apiResponse);
  }

  protected post<T extends IResponseWithErrorGeneric<TResult>, TResult>(
    path: string,
    request: any = ''
  ) {
    return this.http.post<T>(`${this.apiWithPrefix}${path}`, request, {
      observe: 'response'
    });
  }

  protected get<T extends IResponseWithErrorGeneric<TResult>, TResult>(
    path: string,
    params?: any
  ) {
    if (params) {
      return this.http.get<T>(`${this.apiWithPrefix}${path}`, {
        observe: 'response',
        params: new HttpParams({ fromObject: params })
      });
    }

    return this.http.get<T>(`${this.apiWithPrefix}${path}`, {
      observe: 'response'
    });
  }

  protected put<T extends IResponseWithErrorGeneric<TResult>, TResult>(
    path: string,
    request: any = ''
  ) {
    return this.http.put<T>(`${this.apiWithPrefix}${path}`, request, {
      observe: 'response'
    });
  }

  protected delete<T extends IResponseWithErrorGeneric<TResult>, TResult>(
    path: string
  ) {
    return this.http.delete<T>(`${this.apiWithPrefix}${path}`, {
      observe: 'response'
    });
  }

  protected download(path: string, fileName: string, postData: any = null) {
    (postData != null
      ? this.http.post(`${this.apiWithPrefix}${path}`, postData, {
          responseType: 'blob'
        })
      : this.http.get(`${this.apiWithPrefix}${path}`, { responseType: 'blob' })
    )
      .toPromise()
      .then(d => FileSaver.saveAs(d, fileName));
  }

  protected get apiWithPrefix(): string {
    return environment.apiHost + this.prefix;
  }
}
