<p-table
  #quickentryPaginator
  [columns]="orderedColumns"
  [value]="rowsToEdit"
  [customSort]="true"
  [lazy]="true"
  styleClass="p-datatable-sm"
  sortMode="single"
  (onLazyLoad)="loadRows($event)"
  [paginator]="true"
  [rows]="25"
  [totalRecords]="rowsToShow.length"
  [class.cover-spin]="isLoading"
  [first]="paginationRowService.first"
  paginatorPosition="both"
>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col
        *ngFor="let col of orderedColumns"
        [class]="col.thClass || 'paginatied-quickentry-column-default'"
      />
      <col class="microCol" />
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pSortableColumn]="col.filterValue"
        *ngFor="let col of orderedColumns"
      >
        {{ getColumnTitleText(col) | async }}
        <form-tooltip-icon
          *ngIf="col.labelTooltip"
          [tooltipText]="col.labelTooltip"
        >
        </form-tooltip-icon>
        <p-sortIcon
          *ngIf="col?.filterValue != null"
          [field]="col.filterValue"
        ></p-sortIcon>
      </th>
      <th class="microCol no-padding"></th>
      <th class="microCol no-padding"></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-formGroup let-column="columns" let-i="index">
    <tr
      *ngIf="
        i + 1 < formArrayInternal.controls.length || (!displayOnly && !disabled)
      "
      [class]="trClassFunc(formGroup)"
      data-uk-tooltip="{pos:'top'}"
      [title]="trToolTipFunc(formGroup)"
    >
      <td *ngFor="let column of orderedColumns" class="{{ column.tdClass }}">
        <ng-container
          *ngTemplateOutlet="
            column.columnTemplate;
            context: { formGroup: formGroup, displayOnly: displayOnly }
          "
        ></ng-container>
      </td>
      <td class="microCol no-padding"></td>
      <td class="microCol no-padding">
        <ng-container *ngIf="canRemoveRow(formGroup)">
          <a
            class="remove-entry pull-right"
            title="{{ 'shared.form.delete' | translate }}"
            (click)="removeRow(getRowIndex(formGroup), $event)"
            uk-tooltip
          >
            <i class="fa fa-times fa-fw"></i>
          </a>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
