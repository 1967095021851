export const NON_NUMERIC_OR_DECIMAL = /[^\d\,\.]/g;
export const NON_NUMERIC_OR_DECIMAL_OR_MINUS = /[^\d\,\.\-]/g;
export const DECIMALS = /(,)/g;
export const NON_NUMERIC_OR_DECIMAL_OR_MATH = /[^\d\,\.\*\/\+\-]/g;
export const NON_NUMERIC = /[^\d]/g;
export const NON_NUMERIC_OR_MINUS = /[^\d\-]/g;
export const COMMAS_AS_SEPERATOR = /([\,])(.*\..*)/g;
export const TRAILING_ZERO_DECIMALS = /\,([1-9]+)0+$/gm;
export const TRAILING_COMMA = /\,0+$/gm;

export function numberWithSeparators(
  x,
  allowDecimal: boolean = false,
  minDecimals: number = 0,
  maxDecimals = 0,
  allowNegative = false
) {
  let remove: RegExp;
  if (allowDecimal) {
    remove = allowNegative
      ? NON_NUMERIC_OR_DECIMAL_OR_MINUS
      : NON_NUMERIC_OR_DECIMAL;
  } else {
    remove = allowNegative ? NON_NUMERIC_OR_MINUS : NON_NUMERIC;
  }

  let test = x
    .toString()
    .replace(remove, '')
    .replace(COMMAS_AS_SEPERATOR, '$2')
    .replace('.', ',')
    .padDecimals(minDecimals)
    .trimDecimals(maxDecimals)
    .separateThousands(' ');
  return test;
}

export function numberAsStatNr(x) {
  return x
    .toString()
    .replace(NON_NUMERIC, '')
    .replace(/^(.{6})(.*)$/, '$1 $2');
}

export function filterInput(x, allowDecimal: boolean = false) {
  return x
    .toString()
    .replace(allowDecimal ? NON_NUMERIC_OR_DECIMAL : NON_NUMERIC, '')
    .replace('.', ',');
}

export function filterMath(x) {
  return x
    .toString()
    .replace(NON_NUMERIC_OR_DECIMAL_OR_MATH, '')
    .replace(DECIMALS, '.');
}

export function unFormatNumber(x, allowDecimal: boolean = false) {
  return x
    .toString()
    .replace(allowDecimal ? NON_NUMERIC_OR_DECIMAL : NON_NUMERIC, '')
    .replace('.', ',')
    .replace(TRAILING_COMMA, '')
    .replace(TRAILING_ZERO_DECIMALS, ',$1');
}

declare global {
  interface String {
    padDecimals(numDecimals: number): string;
    separateThousands(seperator: string): string;
    trimDecimals(numDecimals: number): string;
  }
}

String.prototype.padDecimals = function (numDecimals: number): string {
  let input = this as string;

  if (numDecimals == 0) return input;

  let commaIndex = input.lastIndexOf(',');

  if (commaIndex < 0) {
    return input + ','.padEnd(numDecimals + 1, '0');
  }

  let curDecimals = input.length - commaIndex - 1;

  if (curDecimals >= numDecimals) return input;

  return input.padEnd(input.length + numDecimals - curDecimals, '0');
};

String.prototype.trimDecimals = function (numDecimals: number): string {
  let input = this as string;

  if (numDecimals == 0) return input;

  let commaIndex = input.lastIndexOf(',');

  if (commaIndex < 0) return input;

  return input.substring(0, commaIndex + numDecimals + 1);
};

String.prototype.separateThousands = function (seperator: string): string {
  let input = this as string;

  return input
    .split(',')
    .map((part, i) =>
      i === 0 ? part.replace(/\B(?=(\d{3})+(?!\d))/g, seperator) : part
    )
    .join(',');
};
