import { WindowCommand } from './window-command';

export class RenderPdfCommand extends WindowCommand {
  private downloadUrl: string;
  private fileName: string;
  private fileId: string;

  public constructor(fileId: string, fileName: string, downloadUrl: string) {
    super('render-pdf');
    this.fileId = fileId;
    this.fileName = fileName;
    this.downloadUrl = downloadUrl;
  }

  public get data() {
    return {
      fileId: this.fileId,
      fileName: this.fileName,
      downloadUrl: this.downloadUrl
    };
  }
}
