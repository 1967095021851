import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency } from 'app/utils';

@Pipe({
  name: 'ecomcurrency'
})
export class CurrencyPipe implements PipeTransform {
  transform(
    value: any,
    locale = 'sv-SE',
    currency = 'SEK',
    currencyDisplay: 'code' | 'symbol' | 'name' = 'code'
  ): any {
    if (!value) return formatCurrency(0, locale, currency, currencyDisplay);
    return formatCurrency(value, locale, currency, currencyDisplay);
  }
}
