import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AdministrationRole } from 'api/enums';
import { AuthenticationService } from 'core/authentication.service';

@Directive({
  selector: '[requireAdmin]'
})
export class RequireAdminDirective implements OnInit {
  @Input('requireAdmin')
  adminRoles: AdministrationRole[];
  displayStyle: any;

  constructor(
    private element: ElementRef,
    private readonly auth: AuthenticationService
  ) {
    this.displayStyle = this.element.nativeElement.style
      ? this.element.nativeElement.style.display
      : '';
    this.element.nativeElement.style.display = 'none';
  }

  ngOnInit(): void {
    if (!this.auth.hasAnyRole(0, this.mapAdminRoles())) {
      this.element.nativeElement.style.display = 'none';
    } else {
      this.element.nativeElement.style.display = this.displayStyle;
    }
  }

  private mapAdminRoles(): AdministrationRole {
    return (this.adminRoles || []).reduce((roles, role) => roles | role, 0);
  }
}
