import { WindowCommand } from './window-command';

export class CaseValidationSummaryCommand extends WindowCommand {
  public url: string;

  public constructor(url: string) {
    super('case-validation-summary');
    this.url = url;
  }

  public get data() {
    return {
      url: this.url
    };
  }
}
