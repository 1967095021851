import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ReplaySubject, Observable, from } from 'rxjs';

import { IError } from 'core/ierror.interface';

@Injectable()
export class ErrorService {
  private _errors: ReplaySubject<IError> = new ReplaySubject<IError>();
  private _errors$: Observable<IError> = from(this._errors);
  private _keep: IError;

  constructor(private router: Router) {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((_event: NavigationEnd) => {
        this._errors.next(this._keep || null);
        this._keep = null;
      });
  }

  next(newError: IError) {
    this._errors.next(newError);

    if (newError && newError.keepAfterNavigation) this._keep = newError;
  }

  clear() {
    this.next(null);
  }

  get errors(): Observable<IError> {
    return this._errors$;
  }
}
