import { Pipe } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Pipe({
  name: 'caseDocumentSort',
  pure: false
})
export class CaseDocumentSortPipe {
  transform(
    array: Array<AbstractControl>,
    args: string
  ): Array<UntypedFormGroup> {
    const sortFields = args.split(',');

    if (array !== undefined) {
      return array
        .sort((a: any, b: any) => {
          for (let i = 0; i < sortFields.length; i++) {
            const field = sortFields[i];
            const aValue = a.controls[field].value;
            const bValue = b.controls[field].value;

            if (!aValue) return 1; // Let null values fall down

            if (aValue < bValue) {
              return -1;
            } else if (aValue > bValue) {
              return 1;
            }

            // Only return same if we are on the last field
            if (i == sortFields.length - 1) return 0;
          }

          return 0;
        })
        .map((control: AbstractControl) => control as UntypedFormGroup);
    }

    return Array<UntypedFormGroup>();
  }
}
