import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ErrorType } from 'core/error-type.enum';
import { ErrorService } from 'core/error.service';
import { IError } from 'core/ierror.interface';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ecom-error-toaster',
  templateUrl: './error-toaster.component.html',
  styleUrls: ['./error-toaster.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EcomErrorToasterComponent implements AfterViewInit, OnDestroy {
  private error$: Subscription;

  toasterConfig: Partial<IndividualConfig<any>> = {
    positionClass: 'toast-bottom-right',
    timeOut: 8000
  };

  constructor(
    private errorService: ErrorService,
    private toastService: ToastrService,
    private i18n: TranslateService
  ) {}

  ngAfterViewInit(): void {
    this.error$ = this.errorService.errors.subscribe(async err => {
      if (!err) {
        return;
      }

      let extracted = await this.extractErrorInfo(err);
      this.toastService.show(
        extracted.message,
        extracted.title,
        this.toasterConfig,
        this.getToastClass(err)
      );
    });
  }

  ngOnDestroy(): void {
    if (this.error$) this.error$.unsubscribe();
  }

  getToastClass(
    error: IError
  ): 'error' | 'warning' | 'success' | 'info' {
    if (this.isError(error)) return 'error';
    if (this.isWarning(error)) return 'warning';
    if (this.isSuccess(error)) return 'success';

    return 'info';
  }

  isWarning(error: IError): boolean {
    return error && error.type === ErrorType.Warning;
  }

  isError(error: IError): boolean {
    return error && error.type === ErrorType.Error;
  }

  isSuccess(error: IError): boolean {
    return error && error.type === ErrorType.Success;
  }

  private async extractErrorInfo(
    error: IError
  ): Promise<{ title: string; message: string }> {
    const extracted = {
      title: '',
      message: ''
    };

    if (error.title) {
      if (error.title instanceof Observable)
        extracted.title = await error.title.toPromise();
      else extracted.title = error.title;
    }

    if (error.title) {
      if (error.message instanceof Observable)
        extracted.message = await error.message.toPromise();
      else extracted.message = error.message;
    }

    if (error.httpStatus === 401) {
      extracted.title =
        extracted.title ||
        (await this.i18n.get('shared.flash.permissionDeniedTitle').toPromise());
      extracted.message =
        extracted.message ||
        (await this.i18n
          .get('shared.flash.permissionDeniedMessage')
          .toPromise());
    }

    if (error.httpStatus === 500) {
      extracted.title =
        extracted.title ||
        (await this.i18n
          .get('shared.flash.internalServerErrorTitle')
          .toPromise());
      extracted.message =
        extracted.message ||
        (await this.i18n
          .get('shared.flash.internalServerErrorMessage')
          .toPromise());
    }

    extracted.title =
      extracted.title || (await this.fallbackTitle(error).toPromise());
    extracted.message = extracted.message || null;

    return extracted;
  }

  private fallbackTitle(error: IError): Observable<string> {
    if (!error) return this.i18n.get('shared.flash.defaultErrorTitle');

    switch (error.type) {
      case ErrorType.Error:
        return this.i18n.get('shared.flash.defaultErrorTitle');
      case ErrorType.Success:
        return this.i18n.get('shared.flash.defaultSuccessTitle');
      case ErrorType.Warning:
        return this.i18n.get('shared.flash.defaultWarningTitle');
    }

    return this.i18n.get('shared.flash.defaultErrorTitle');
  }
}
