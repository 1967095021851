import { InjectionToken } from '@angular/core';
import { DatepickerLocale } from './datepicker_locale';

export const DATEPICKER_LANG = new InjectionToken<DatepickerLocale>(
  'datepicker.locale'
);
export const DATEPICKER_LOCALE: DatepickerLocale = {
  firstDayOfWeek: 1,
  dayNames: [
    'Söndag',
    'Måndag',
    'Tisdag',
    'Onsdag',
    'Torsdag',
    'Fredag',
    'Lördag'
  ],
  dayNamesShort: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
  dayNamesMin: ['Sö', 'Må', 'Ti', 'On', 'To', 'Fr', 'Lö'],
  monthNames: [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'Oktober',
    'November',
    'December'
  ],
  monthNamesShort: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'Maj',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec'
  ],
  today: 'Idag',
  clear: 'Rensa',
  dateFormat: 'yy-mm-dd',
  weekHeader: 'Vk'
};
