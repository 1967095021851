import { AfterContentInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UikitFormElementComponent } from './uikit-form-element.component';
import { ControlValueAccessor } from '@angular/forms';
@Component({
  selector: 'ecom-form-element-text-with-addon',
  templateUrl: './ecom-form-element-text-with-addon.component.html'
})
export class EcomFormElementTextWithAddonComponent
  extends UikitFormElementComponent
  implements OnInit, AfterContentInit, OnDestroy {}
