import {
  trigger,
  state,
  style,
  transition,
  animate,
  keyframes,
  sequence
} from '@angular/animations';

export function Collapse(duration: number = 300) {
  return trigger('collapse', [
    state(
      'void',
      style({
        height: '0',
        opacity: '0',
        overflow: 'hidden'
      })
    ),
    state(
      '*',
      style({
        height: '*',
        opacity: '1',
        overflow: 'visible'
      })
    ),
    transition('void => *', [
      sequence([
        animate(duration + 'ms ease', style({ opacity: '1', height: '*' })),
        animate('1ms', style({ overflow: 'visible' }))
      ])
    ]),
    transition('* => void', [
      sequence([
        animate('1ms', style({ overflow: 'hidden' })),
        animate(duration + 'ms ease', style({ height: '0', opacity: '0' }))
      ])
    ])
  ]);
}
