<div class="panel panel-default" *ngIf="uploader">
  <p-confirmDialog [key]='confirmationKey'></p-confirmDialog>
  <div class="panel-heading">{{ title }}</div>
  <div class="panel-body">
    <!-- Standard Form -->
    <strong>{{ 'shared.uploader.selectFilesTitle' | translate }}</strong>
    <div id="js-upload-form">
      <div uk-form-custom>
        <input type="file" ng2FileSelect [uploader]="uploader" multiple />
        <button class="uk-button uk-button-default" type="button" tabindex="-1">
          {{ 'shared.uploader.selectFiles' | translate }}
        </button>
      </div>
      <button
        type="button"
        class="uk-button uk-margin-small-left"
        (click)="uploader.uploadAll()"
        [disabled]="!uploader.getNotUploadedItems().length"
      >
        {{ 'shared.uploader.transferFiles' | translate }}
      </button>
    </div>
    <br />

    <!-- Drop Zone -->
    <strong>eller dra och släpp filer i rutan nedan</strong>
    <div
      ng2FileDrop
      [uploader]="uploader"
      (fileOver)="fileOver($event)"
      class="upload-drop-zone uk-placeholder"
    >
      {{ 'shared.uploader.dragAndDrop' | translate }}
    </div>

    <!-- Progress Bar -->
    <div class="uk-progress uk-progress-small">
      <div
        class="uk-progress-bar"
        [style.width]="uploader.progress + '%'"
      ></div>
    </div>

    <!-- Upload Finished -->
    <div class="js-upload-finished">
      <strong>Överförda filer</strong>
      <ul class="uk-list uk-list-striped">
        <li
          [class.uk-text-success]="item.isSuccess"
          [class.uk-text-danger]="item.isError"
          [class.uk-text-warning]="item.isCancel"
          [class.uk-text-info]="item.isUploading"
          *ngFor="let item of uploader.queue"
        >
          <span
            class="uk-badge uk-badge-warning uk-float-right"
            *ngIf="item.isCancel"
            >Avbruten</span
          >
          <span
            class="uk-badge uk-badge-danger uk-float-right"
            *ngIf="item.isError"
            >Fel</span
          >
          <span
            class="uk-badge uk-badge-success uk-float-right"
            *ngIf="item.isSuccess"
            >Slutförd</span
          >
          <span
            class="uk-badge uk-badge-info uk-float-right"
            *ngIf="item.isUploading"
            >Överför</span
          >
          {{ item?.file?.name }}

        </li>
        <li *ngFor='let item of existingFiles'>
          <div class='upload-file-overview'>
            <a [href]='item.downloadUrl' class='uk-text-success'>
              {{ item.file?.name }}
            </a>
            <span class='upload-file-comments' *ngIf='showComments'>
              - {{ item.comment }}
            </span>
            <button
              pButton
              type='button'
              icon='pi pi-pencil'
              (click)='op.toggle($event)'>
            </button>
            <button
              *ngIf='showDelete'
              pButton
              type='button'
              icon='pi pi-times'
              class='uk-button'
              (click)='confirmDelete(item); $event.stopPropagation()'
            ></button>
            <p-overlayPanel #op>
              <div class='upload-file-comments'>
                <input
                  type='text'
                  class='uk-input'
                  [placeholder]="'admin.customerCompanies.model.comment' | translate"
                  [(ngModel)]='item.comment'
                >
                <button
                  pButton
                  type='button'
                  icon='pi pi-times'
                  class='uk-button upload-file-comments-close'
                  (click)='updateComment(item); op.hide()'
                ></button>
              </div>
            </p-overlayPanel>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
