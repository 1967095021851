import { Component, Input } from '@angular/core';

@Component({
  selector: 'check-flag',
  template:
    '' +
    '<span class="uk-text-success" *ngIf="toggle"><i class="fa fa-check"></i></span>' +
    '<span class="uk-text-danger" *ngIf="!toggle"><i class="fa fa-times"></i></span>'
})
export class CheckFlagComponent {
  @Input()
  public toggle: boolean;
}
