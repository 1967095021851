import {
  Injectable,
  ComponentRef,
  ViewContainerRef,
  Type
} from '@angular/core';
import { ConfirmPopover } from './confirm.popover';
import { Guid } from 'guid-typescript';
import { BasePopover } from './base-popover';

@Injectable({ providedIn: 'root' })
export class PopoverService {
  public viewContainerRef: ViewContainerRef;
  public popovers: { [id: string]: ComponentRef<any> } = {};

  constructor() {}

  public createConfirm(): ConfirmPopover {
    const identifier = Guid.create().toString();
    const componentRef = this.viewContainerRef.createComponent(ConfirmPopover);

    componentRef.instance.initializeComponent(identifier, (id: string) => {
      this.popovers[id].destroy();
      delete this.popovers[id];
    });

    this.popovers[identifier] = componentRef;

    return componentRef.instance;
  }

  public create<T extends BasePopover>(
    component: Type<T>,
    initialData: any
  ): T {
    const identifier = Guid.create().toString();
    const componentRef = this.viewContainerRef.createComponent(component);

    Object.assign(componentRef.instance, initialData || {});

    componentRef.instance.initializeComponent(identifier, (id: string) => {
      this.popovers[id].destroy();
      delete this.popovers[id];
    });

    this.popovers[identifier] = componentRef;

    return componentRef.instance;
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
}
