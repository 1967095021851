<ng-container *ngIf="!!cfg && !!items">
  <div class="uk-card uk-card-default table-padding">
    <div [class.scrollable]="scrollable">
      <table
        class="uk-table uk-table-hover cropper has-loading"
        [class.top]="valignTop"
        [class.withMain]="hasMainColumn"
        [class.loading]="isLoading"
        [class.sticky-top]="sticky == 'top'"
        [class.sticky-toolbar]="sticky == 'toolbar'"
      >
        <thead>
          <tr>
            <th *ngIf="isSelectable" class="miniCol"></th>
            <ng-container *ngFor="let column of cfg.columns">
              <th
                *ngIf="!column.condition || column.condition()"
                class="{{ column.thClass }}"
                [ngClass]="getThClass(column.field)"
              >
                <div [class.cropperIgnore]="column.disableSort">
                  <i
                    class="fa"
                    [ngClass]="column.headerIcon"
                    *ngIf="column.headerIcon"
                  ></i>
                  <a
                    (click)="changeSorting(column.field)"
                    *ngIf="!column.disableSort"
                  >
                    <i
                      class="th-sorting fa"
                      [ngClass]="getSortingIconClass(column.field)"
                    ></i>
                    {{ column.headerName | translate }}
                  </a>
                  <ng-container *ngIf="column.disableSort">
                    {{ column.headerName | translate }}
                  </ng-container>
                </div>
              </th>
            </ng-container>
            <th *ngIf="cfg.menuItems"></th>
          </tr>
        </thead>
        <tbody class="ecus-tbody">
          <ng-container *ngFor="let item of allItems; let i = index">
            <tr
              (dblclick)="onRowClick($event, item)"
              [ngClass]="{ 'full-row-url': hasRowClickEvent || isSelectable }"
              class="{{
                isSelected(item) ? cfg.selectable.selectedTrClass : ''
              }}"
            >
              <td *ngIf="isSelectable">
                <i
                  class="fa {{
                    isSelected(item)
                      ? cfg.selectable.selectedIcon
                      : cfg.selectable.unselectedIcon
                  }}"
                ></i>
              </td>
              <ng-container *ngFor="let column of cfg.columns">
                <td
                  *ngIf="!column.condition || column.condition()"
                  class="{{ column.tdClass }}"
                >
                  <ng-container *ngIf="!hasTemplate(column)">
                    <a
                      *ngIf="!!column.onClick"
                      (click)="column.onClick(item)"
                      [innerHTML]="
                        !!column.displayValue
                          ? column.displayValue(item)
                          : item[column.field]
                      "
                    ></a>
                    <span
                      *ngIf="!column.onClick"
                      [innerHTML]="
                        !!column.displayValue
                          ? column.displayValue(item)
                          : item[column.field]
                      "
                    ></span>
                  </ng-container>
                  <ng-container
                    *ngTemplateOutlet="
                      templateFor(column);
                      context: { $implicit: item }
                    "
                  ></ng-container>
                </td>
              </ng-container>
              <td *ngIf="!!cfg.menuItems" class="table-dropdown uk-text-right">
                <ng-container *ngFor="let menuItem of cfg.menuItems">
                  <a
                    *ngIf="
                      (menuItem.condition == undefined ||
                        menuItem.condition(item)) &&
                      menuItem.primary &&
                      menuItem.icon
                    "
                    (click)="menuItem.onClick(item, $event)"
                    data-uk-tooltip
                    title="{{ menuItem.text | translate }}"
                    class="primary-action"
                  >
                    <i class="fa fa-{{ menuItem.icon }}"></i>
                  </a>
                </ng-container>
                <section
                  class="uk-button-dropdown uk-text-left"
                  *ngIf="hasEnabledMenuItems(item)"
                >
                  <a><i class="fa fa-ellipsis-v"></i></a>
                  <div
                    class="uk-dropdown uk-dropdown-medium"
                    uk-dropdown="mode: click; pos: left-center"
                  >
                    <ul class="uk-nav uk-nav-dropdown">
                      <ng-container *ngFor="let menuItem of cfg.menuItems">
                        <li [class.uk-nav-divider]="!!menuItem.divider">
                          <a
                            *ngIf="
                              !menuItem.divider &&
                              (menuItem.condition == undefined ||
                                menuItem.condition(item))
                            "
                            (click)="menuItem.onClick(item, $event)"
                          >
                            <i
                              *ngIf="menuItem.icon"
                              class="fa fa-fw fa-{{
                                menuItem.icon
                              }} fa-margin-right"
                            ></i>
                            <span>{{ menuItem.text | translate }}</span>
                          </a>
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </section>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div>
      <uk-pagination
        [boundaryLinks]="false"
        [totalItems]="cfg.totalCount"
        [itemsPerPage]="cfg.pageSize"
        [(ngModel)]="cfg.page"
        class="uk-flex-center"
        (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
        aria-label="Pagination"
      ></uk-pagination>
    </div>
  </div>
</ng-container>
