import { Component } from '@angular/core';
import { UikitFormElementComponent } from './uikit-form-element.component';
import { FilterService } from 'primeng/api';
import { DropdownFilterService } from 'shared/services';

@Component({
  selector: 'ecom-form-element-dropdown',
  templateUrl: './ecom-form-element-dropdown.component.html',
  providers: [
    {
      provide: FilterService,
      useFactory: (component: EcomFormElementDropdownComponent) =>
        new DropdownFilterService(component.filterByFirstMatch),
      deps: [EcomFormElementDropdownComponent]
    }
  ]
})
export class EcomFormElementDropdownComponent extends UikitFormElementComponent {}
