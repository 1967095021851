import {
  Component,
  HostBinding,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ActorDto } from 'api/models';
import { Utils } from 'app/utils';
import { AbstractControlWithWarnings } from 'shared/AbstractControlWithWarnings';
import { ActorForm } from 'shared/forms/actor.form';
import { SharedDataRepository } from 'shared/services/repositories/shared-data.repository';

export type ActorModalAction = 'create' | 'edit';

@Component({
  selector: 'ecom-edit-actor-data',
  templateUrl: './edit-actor-data.component.html',
  styleUrls: ['./edit-actor-data.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EditActorDataComponent {
  @HostBinding('class') get hostClass(): string {
    return `uk-width-${this.width}`;
  }

  @Input() isReadOnly: boolean;
  @Input() width = '1-1';
  @Input() form: ActorForm;
  @Input()
  set actor(value: ActorDto) {
    if (value != null) {
      this.form.fromActor(value);
      this.form.markAllAsTouched();
      this.needsApproval = value.needsApproval;
    }
  }
  @Input() fullEdit: boolean;

  countryCodeItems = Utils.countryCodeItems;
  needsApproval: boolean;

  constructor(public repository: SharedDataRepository) {}

  public addMailRecipient(): void {
    this.form.fMailRecipients.push(this.form.buildRecipient('', '', 0));
  }

  public removeMailRecipient(index: number): void {
    this.form.fMailRecipients.removeAt(index);
  }

  public approveActor(): void {
    this.form.patchValue({ needsApproval: false });
  }

  public getRecipientAddressControl(
    control: AbstractControl
  ): AbstractControlWithWarnings {
    return control.get('mailAddress') as AbstractControlWithWarnings;
  }

  public getRecipientDocumentsControl(
    control: AbstractControl
  ): AbstractControlWithWarnings {
    return control.get('documentsToReceive') as AbstractControlWithWarnings;
  }
}
