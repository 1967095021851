<ng-template
  #internalColumnTemplate
  let-formGroup="formGroup"
  let-displayOnly="displayOnly"
>
  <ng-container *ngIf="controlName">
    <uikit-form-element
      *ngIf="!!customKeyFilter"
      [name]="controlName"
      [control]="formGroup.get(controlName)"
      [noLabel]="true"
      [displayOnly]="displayOnly"
      [customKeyFilter]="customKeyFilter"
      [placeholder]="placeholder"
    ></uikit-form-element>
    <uikit-form-element
      *ngIf="!customKeyFilter"
      [name]="controlName"
      [control]="formGroup.get(controlName)"
      [noLabel]="true"
      [displayOnly]="displayOnly"
      [placeholder]="placeholder"
    ></uikit-form-element>
  </ng-container>
  <ng-container *ngIf="externalTemplate">
    <ng-container
      *ngTemplateOutlet="
        externalTemplate;
        context: { formGroup: formGroup, displayOnly: displayOnly }
      "
    ></ng-container>
  </ng-container>
</ng-template>
