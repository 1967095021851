import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { GetTenantSettingsResponse } from 'api/responses/get-tenant-settings-response';
import { GetTenantSettingsResult } from 'api/results/get-tenant-settings-result.enum';

const MAX_AGE = 86400000; // One day in milliseconds

@Injectable()
export class TenantService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/tenant/', errorService);
  }

  private _settings: GetTenantSettingsResponse;
  private _settingsObservable: Observable<GetTenantSettingsResponse>;
  private _settingsFetched: number;

  getSettings(): Observable<GetTenantSettingsResponse> {
    if (
      this._settings &&
      this._settings.result === GetTenantSettingsResult.Success && // We have a value
      new Date().getTime() - this._settingsFetched < MAX_AGE // Cache was last refreshed recently
    ) {
      return of(this._settings);
    } else if (this._settingsObservable) {
      // We're currently refreshing
      return this._settingsObservable;
    } else {
      // FIXME: This should also check etags and so on. See also https://trello.com/c/9dKOxWKw/177-move-service-level-cache-up-to-http-interceptor-level
      this._settingsObservable = this.get<
        GetTenantSettingsResponse,
        GetTenantSettingsResult
      >('settings').pipe(
        map(http => {
          const response = new GetTenantSettingsResponse(http);

          // Only cache a successful response
          if (response.result === GetTenantSettingsResult.Success) {
            this._settings = response;
            this._settingsFetched = new Date().getTime();
          }

          // Don't keep this around any more, so we can try again
          this._settingsObservable = null;

          return response;
        }),
        catchError(error => {
          this._settingsObservable = null;
          return this.onError(new GetTenantSettingsResponse(error));
        })
      );

      return this._settingsObservable;
    }
  }
}
