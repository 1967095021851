import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  numberWithSeparators,
  numberAsStatNr,
  unFormatNumber,
  filterInput,
  filterMath
} from './input-formatters';

@Directive({
  selector: 'input[inputFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFormatDirective),
      multi: true
    }
  ]
})
export class InputFormatDirective {
  @Input() inputFormat: string;
  @Input() canCalculate: boolean;

  private _value: string | null;
  private _unFormatedValue: string | null;

  constructor(private elementRef: ElementRef<HTMLInputElement>) {}

  get value(): string | null {
    return this._value;
  }

  @Input('value')
  set value(value: string | null) {
    this._value = value;
    this.formatValue(value);
  }

  public formatValue(value: string | null) {
    if (value !== null) {
      switch (this.inputFormat) {
        case 'int':
          this.elementRef.nativeElement.value = numberWithSeparators(value);
          break;
        case 'price':
          this.elementRef.nativeElement.value = numberWithSeparators(
            value,
            true,
            2,
            2
          );
          break;
        case 'currency':
          this.elementRef.nativeElement.value = numberWithSeparators(
            value,
            true,
            2,
            2,
            true
          );
          break;
        case 'decimal':
          this.elementRef.nativeElement.value = numberWithSeparators(
            value,
            true,
            0,
            3
          );
          break;
        case 'statnr':
          this.elementRef.nativeElement.value = numberAsStatNr(value);
          break;
        case 'none':
        default:
          this.elementRef.nativeElement.value = value;
          break;
      }
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  private calculateValue() {
    if (this.canCalculate && !!this._unFormatedValue) {
      let toCalculate = filterMath(this._unFormatedValue);
      this._value = eval(toCalculate) ?? this.value;
    }
    return this._value;
  }

  private unFormatValue() {
    const value = this.elementRef.nativeElement.value;
    if (value) {
      switch (this.inputFormat) {
        case 'int':
          this.elementRef.nativeElement.value = unFormatNumber(value);
          break;
        case 'price':
        case 'decimal':
          this.elementRef.nativeElement.value = unFormatNumber(value, true);
          break;
        case 'statnr':
          this.elementRef.nativeElement.value = unFormatNumber(value);
          break;
        case 'none':
        default:
          this.elementRef.nativeElement.value = value;
          break;
      }
    } else {
      this.elementRef.nativeElement.value = '';
    }
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value) {
    this._unFormatedValue = value;
    switch (this.inputFormat) {
      case 'int':
        this._value = filterInput(value);
        break;
      case 'decimal':
      case 'price':
        this._value = filterInput(value, true);
        break;
      case 'statnr':
        this._value = filterInput(value);
        break;
      case 'none':
      default:
        this._value = value;
        break;
    }
    this._onChange(this._value);
  }

  @HostListener('blur')
  _onBlur() {
    this.formatValue(this.calculateValue());
    this._onChange(this._value);
  }

  @HostListener('focus')
  onFocus() {
    this.unFormatValue();
    this.elementRef.nativeElement.select();
  }

  _onChange(value: any): void {}

  writeValue(value: any) {
    this._value = value;
    this.formatValue(this._value);
  }

  registerOnChange(fn: (value: any) => void) {
    this._onChange = fn;
  }

  registerOnTouched() {}
}
