import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class CaseRowService {
  constructor() {
    this.refreshRow$ = this.refreshRowSubject.asObservable();
    this.refreshReadonly$ = this.refreshReadonlySubject.asObservable();
    this.refreshActors$ = this.refreshActorsSubject$.asObservable();
  }

  private refreshRowSubject = new Subject<boolean>();
  public refreshRow$ = new Observable<boolean>();
  public refreshActors$ = new Observable<boolean>();
  public refreshActorsSubject$ = new Subject<boolean>();
  private refreshReadonlySubject = new Subject<boolean>();
  public refreshReadonly$ = new Observable<boolean>();

  public toggleReadonly(readOnly: boolean) {
    this.refreshReadonlySubject.next(readOnly);
  }

  public toggleRefreshActors() {
    this.refreshActorsSubject$.next(true);
  }

  public toggleRefresh() {
    this.refreshRowSubject.next();
  }
}
