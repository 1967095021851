<div
  class="uk-form-stacked {{ validationStatusClass }}"
  [class.uk-form-nolabel]
  *ngIf="(control || type == 'errors') && name && !hidden"
  [@collapse]
>
  <label *ngIf="type == 'checkbox' && !noLabel">{{ groupLabel }}</label>
  <label
    for="{{ name }}"
    *ngIf="type != 'checkbox' && type != 'errors' && !noLabel"
  >
    <span *ngIf="!!flag" class="flag-icon flag-icon-{{ flag }}"></span>
    {{ labelText | async }}
    <span
      *ngIf="isRequired && !displayOnly && !hideValidation"
      class="required-star"
      >*</span
    >
  </label>
  <form-tooltip-icon *ngIf="labelTooltip" [tooltipText]="labelTooltip">
  </form-tooltip-icon>
  <div
    *ngIf="!displayOnly"
    class=""
    [class.uk-form-controls-check]="type == 'checkbox'"
  >
    <div class="ui-inputgroup {{ inputClass }}" *ngIf="typeIsInputGroup">
      <ng-container *ngFor="let addon of addonsLeft">
        <span
          class="ui-inputgroup-addon {{ addon.class }}"
          *ngIf="!addonIsClickable(addon)"
          [style.flex]="addon.width ? '1 1 ' + addon.width + 'px' : null"
          [class.ui-inputgroup-addon-set-width]="!!addon.width"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          tabIndex="-1"
        >
          <input
            *ngIf="addonIsCheckbox(addon)"
            type="checkbox"
            name="{{ addon.checkName }}"
            id="{{ addon.checkName }}"
            [formControl]="addon.checkControl"
            [tabIndex]="addon.tabIndex"
            [attr.disabled]="!!disabled ? 'disabled' : null"
          />
          <label *ngIf="addonIsCheckbox(addon)" for="{{ addon.checkName }}">
            <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
            <span *ngIf="addon.label"> {{ addon.label }}</span>
          </label>
          <i
            class="fa fa-fw fa-{{ addon.icon }}"
            *ngIf="!addonIsCheckbox(addon) && addon.icon"
          ></i>
          <span *ngIf="!addonIsCheckbox(addon) && addon.label">{{
            addon.label
          }}</span>
        </span>
        <button
          type="button"
          class="uk-button {{ addon.class }}"
          *ngIf="addonIsClickable(addon)"
          [disabled]="
            !!addon.disabled || (disabled && !addon.enabledInReadOnly)
          "
          (click)="clickAddon(addon, $event)"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          [tabIndex]="addon.tabIndex"
          [class.uk-button-danger]="
            addon.validationControl &&
            (addon.validationControl.touched ||
              addon.validationControl.dirty ||
              submitted) &&
            !addon.validationControl.valid
          "
        >
          <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
          <span *ngIf="addon.label">{{ addon.label }}</span>
        </button>
      </ng-container>
      <p-dropdown
        *ngIf="type == 'select' && !multiple && (items || itemGroups)"
        [options]="itemGroups || items"
        [group]="!!itemGroups"
        [readonly]="readonly"
        [styleClass]="styleClass"
        [inputId]="inputId || id || name"
        [placeholder]="emptyPlaceholder ? '&nbsp;' : (placeholderText | async)"
        [filter]="true"
        [filterBy]="filterBy"
        [name]="name"
        [formControl]="control"
        (onChange)="onSelectChange($event)"
        [showClear]="showClear"
        [selectOnFocus]="true"
        appendTo="body"
        [tabindex]="tabIndex"
        p-keyboard
        [disabled]="disabled"
        (onHide)="onHideDropdown()"
        (dragstart)="preventDrag($event)"
      >
        <ng-template let-item pTemplate="selectedItem">
          <ng-container *ngIf="!selectedItemTemplate">{{
            selectedDisplayTitle(item)
          }}</ng-container>
          <ng-container *ngIf="selectedItemTemplate">
            <ng-container
              *ngTemplateOutlet="
                selectedItemTemplate;
                context: { $implicit: item }
              "
            ></ng-container>
          </ng-container>
        </ng-template>
        <ng-template let-item pTemplate="item">
          <ng-container *ngIf="!itemTemplate">{{
            itemDisplayTitle(item)
          }}</ng-container>
          <ng-container *ngIf="itemTemplate">
            <ng-container
              *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
            ></ng-container>
          </ng-container>
        </ng-template>
      </p-dropdown>
      <ng-container *ngFor="let addon of addonsRight">
        <span
          class="ui-inputgroup-addon {{ addon.class }}"
          *ngIf="!addonIsClickable(addon)"
          [style.flex]="addon.width ? '1 1 ' + addon.width + 'px' : null"
          [class.ui-inputgroup-addon-set-width]="!!addon.width"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          tabIndex="-1"
        >
          <input
            *ngIf="addonIsCheckbox(addon)"
            type="checkbox"
            name="{{ addon.checkName }}"
            id="{{ addon.checkName }}"
            [formControl]="addon.checkControl"
            [tabIndex]="addon.tabIndex"
            [attr.disabled]="!!disabled ? 'disabled' : null"
          />
          <label *ngIf="addonIsCheckbox(addon)" for="{{ addon.checkName }}">
            <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
            <span *ngIf="addon.label"> {{ addon.label }}</span>
          </label>
          <i
            class="fa fa-fw fa-{{ addon.icon }}"
            *ngIf="!addonIsCheckbox(addon) && addon.icon"
          ></i>
          <span *ngIf="!addonIsCheckbox(addon) && addon.label">{{
            addon.label
          }}</span>
        </span>
        <button
          type="button"
          class="uk-button {{ addon.class }}"
          *ngIf="addonIsClickable(addon)"
          [disabled]="
            !!addon.disabled || (disabled && !addon.enabledInReadOnly)
          "
          (click)="clickAddon(addon, $event)"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          [tabIndex]="addon.tabIndex"
          [class.uk-button-danger]="
            addon.validationControl &&
            (addon.validationControl.touched ||
              addon.validationControl.dirty ||
              submitted) &&
            !addon.validationControl.valid
          "
        >
          <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
          <span *ngIf="addon.label">{{ addon.label }}</span>
        </button>
      </ng-container>
    </div>

    <ng-content></ng-content>
    <div *ngIf="showError">
      <p *ngFor="let error of errors">
        <small *ngIf="error == 'maxlength' || error == 'minlength'">
          {{
            'shared.form.validation.' + error
              | translate: { requiredLength: getError(error)?.requiredLength }
          }}
        </small>
        <small *ngIf="error != 'maxlength' && error != 'minlength'">
          {{ 'shared.form.validation.' + error | translate }}
        </small>
      </p>
    </div>
  </div>
  <div
    *ngIf="displayOnly"
    class="uk-form-controls uk-form-controls-text uk-form-controls-text-display-only"
  >
    <div *ngIf="!!displayOnlyValueOverrideText">
      <span>{{ displayOnlyValueOverrideText }}</span>
    </div>
    <div *ngIf="!displayOnlyValueOverrideText">
      <span *ngIf="type == 'text' || type == 'number'">{{
        displayOnlyInputText | async
      }}</span>
      <span *ngIf="type == 'textarea'">{{ displayOnlyTextareaText }}</span>
      <span
        *ngIf="type == 'select' && (!!items || !!itemGroups) && selectedItem"
      >
        <span *ngIf="!selectedItemTemplate">{{
          displayOnlyValueOverrideText || selectedDisplayTitle(selectedItem)
        }}</span>
        <ng-container *ngIf="selectedItemTemplate">
          <ng-container
            *ngTemplateOutlet="
              selectedItemTemplate;
              context: { $implicit: selectedItem }
            "
          ></ng-container>
        </ng-container>
      </span>
      <span *ngIf="type == 'checkbox'">
        <i class="fa fa-fw fa-check-square-o" *ngIf="control.value"></i>
        <i class="fa fa-fw fa-square-o" *ngIf="!control.value"></i>
        {{ labelText | async }}
      </span>
      <span *ngIf="type == 'chips'">{{ control.value.join(', ') }}</span>
    </div>
  </div>
</div>
