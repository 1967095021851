import { throwError as observableThrowError, Observable, Subject } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpResponse,
  HttpParams,
  HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { SessionStorage, LocalStorageService } from 'ngx-webstorage';

import { environment } from 'environments/environment';
import { Role, AdministrationRole } from 'api/enums';
import { TokenDto } from 'api/models/token-dto';
import { UserInfoDto, UserDepartmentDto, UserCompanyDto } from 'api/models';
import {
  fromUnixTime,
  isAfter,
  differenceInMilliseconds,
  subSeconds
} from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { LoginParameterCodec } from './login-parameter-codec';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  public static SKIP_INTERCEPTOR = 'X-Skip-Auth-Interceptor';
  private _userSubject: Subject<TokenDto>;
  private refreshTimer: NodeJS.Timeout;

  public get token(): TokenDto {
    if (!this._token)
      this._token = {
        userId: null,
        authToken: null,
        refreshToken: null,
        expires: null,
        roles: null,
        adminRoles: null
      };

    return this._token;
  }
  public set token(newToken: TokenDto) {
    this._token = newToken;

    if (!this.tokenHasExpired && newToken && newToken.expires != null) {
      if (this.refreshTimer) clearTimeout(this.refreshTimer);
      let expiration = fromUnixTime(this.token.expires);
      let interval = differenceInMilliseconds(
        subSeconds(expiration, 30),
        new Date()
      );
      this.refreshTimer = setTimeout(() => {
        this.refreshToken().subscribe(res => {
          console.debug('Refreshed token before expiration. Got back', res);
        });
      }, interval);
    }
  }

  @SessionStorage('token')
  private _token: TokenDto;

  constructor(
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router
  ) {
    this._userSubject = new Subject<TokenDto>();
  }

  public get authToken(): string {
    if (!this.isAuthenticated) return '';
    return this.token.authToken;
  }

  public get tokenHasExpired(): boolean {
    // TODO: Better verification that we're actually logged in.
    return (
      this.token == null ||
      isAfter(
        fromZonedTime(new Date(), environment.timezone),
        fromUnixTime(this.token.expires)
      )
    );
  }

  public get hasRefreshToken(): boolean {
    return (
      this.token && this.token.refreshToken && this.token.refreshToken !== ''
    );
  }

  public get GetUserChanged(): Observable<TokenDto> {
    return this._userSubject.asObservable();
  }

  logIn(username: string, password: string, rememberMe: boolean) {
    const body = new HttpParams({
      encoder: new LoginParameterCodec()
    })
      .set('grant_type', 'password')
      .set('username', username)
      .set('password', password)
      .set('remember_me', rememberMe.toString());

    return this.http
      .post<TokenDto>(`${environment.apiHost}token`, body, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('X-Requested-With', 'XMLHttpRequest')
          .set(AuthenticationService.SKIP_INTERCEPTOR, 'true')
      })
      .pipe(
        map(response => this.mapLoginResponse(response, rememberMe)),
        catchError(err => this.handleError(err))
      );
  }

  refreshToken() {
    let body = new HttpParams().set('refreshToken', this.token.refreshToken);
    return this.http
      .post<TokenDto>(`${environment.apiHost}refreshToken`, body)
      .pipe(map(response => this.mapLoginResponse(response, true)));
  }

  logOut() {
    this.http
      .post(`${environment.apiHost}api/account/Logout`, null, {
        headers: new HttpHeaders()
          .set('Authorization', `Bearer ${this.authToken}`)
          .set('X-Requested-With', 'XMLHttpRequest')
          .set(AuthenticationService.SKIP_INTERCEPTOR, 'true')
      })
      .subscribe(res => {
        this.token = null;
        this.localStorage.clear('activeCompany');
        this.localStorage.clear('activeDepartment');
        this.localStorage.clear('token');
        this.router.navigate(['/login']);

        this._userSubject.next(this.token);
      });
  }

  isAuthenticated(): boolean {
    if (this.token == null) {
      this.token = this.localStorage.retrieve('token') as TokenDto;
    }

    return !this.tokenHasExpired;
  }

  getUserType(): Role {
    if (this.isAuthenticated()) {
      return this.token.roles;
    } else {
      this.router.navigate(['/login'], {
        queryParams: { returnTo: this.router.routerState.snapshot.url }
      });
      return null;
    }
  }

  hasAnyRole(roles: Role, adminRoles: AdministrationRole): boolean {
    // tslint:disable-next-line: no-bitwise
    return (
      (this.token.roles & roles) > 0 || (this.token.adminRoles & adminRoles) > 0
    );
  }

  getUserInfo(): Observable<UserInfoDto> {
    return this.http
      .get<UserInfoDto>(`${environment.apiHost}api/account/userinfo`)
      .pipe(
        map(json => {
          const userInfo = new UserInfoDto(
            json,
            this.token.roles,
            this.token.adminRoles
          );

          if (json.departments != null) {
            userInfo.departments = [];
            for (const department of json.departments)
              userInfo.departments.push(new UserDepartmentDto(department));
          }

          if (json.mainDepartment != null)
            userInfo.mainDepartment = new UserDepartmentDto(
              json.mainDepartment
            );

          if (json.companies != null) {
            userInfo.companies = new Array<UserCompanyDto>();
            for (const company of json.companies) {
              userInfo.companies.push(new UserCompanyDto(company));
            }
          }

          if (json.caseLengths) userInfo.caseLengths = json.caseLengths;

          if (json.caseDifficulties)
            userInfo.caseDifficulties = json.caseDifficulties;

          return userInfo;
        }),
        catchError(this.handleError)
      );
  }

  private mapLoginResponse(response: TokenDto, rememberMe: boolean) {
    this.token = new TokenDto(response);
    if (rememberMe) {
      this.localStorage.store('token', this.token);
    }

    this._userSubject.next(this.token);

    return response;
  }

  private handleError(error: HttpResponse<any> | any) {
    let errMsg: string;
    if (error instanceof HttpResponse && error != null) {
      const body = error.body || '';
      if (body.error === 'invalid_grant') {
        errMsg = body.error_description;
      } else {
        const err = body.error || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
      }
    } else if (error instanceof HttpErrorResponse && error != null) {
      errMsg = error.error.login_failure;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return observableThrowError(errMsg);
  }
}
