import {
  Input,
  ContentChildren,
  QueryList,
  AfterViewInit,
  Directive,
  ChangeDetectorRef,
  AfterContentInit
} from '@angular/core';
import { UikitFormElementComponent } from './uikit-form-element.component';
import { QuickEntryComponent } from './quickentry.component';
import { Disablable } from './disablable.interface';
import { Observable } from 'rxjs/internal/Observable';
import { EcomFormElementTextComponent } from './ecom-form-element-text.component';
import { EcomFormElementDropdownComponent } from './ecom-form-element-dropdown.component';
import { EcomFormElementTextWithAddonComponent } from './ecom-form-element-text-with-addon.component';
import { CaseRowService } from 'shared/services/case-row.service';

@Directive({
  selector: 'uikit-form'
})
export class UiKitFormComponent implements AfterViewInit {
  @ContentChildren(UikitFormElementComponent, { descendants: true })
  public ukfes: QueryList<UikitFormElementComponent>;
  @ContentChildren(EcomFormElementTextComponent, { descendants: true })
  public efets: QueryList<EcomFormElementTextComponent>;
  @ContentChildren(EcomFormElementTextWithAddonComponent, { descendants: true })
  public efetsa: QueryList<EcomFormElementTextWithAddonComponent>;
  @ContentChildren(EcomFormElementDropdownComponent, { descendants: true })
  public efeds: QueryList<EcomFormElementDropdownComponent>;
  @ContentChildren(QuickEntryComponent, { descendants: true })
  public quickentries: QueryList<QuickEntryComponent>;

  @Input() readOnly;

  constructor(
    private cdr: ChangeDetectorRef,
    private caseRowService: CaseRowService
  ) {}

  ngAfterViewInit(): void {
    this.caseRowService.refreshRow$.subscribe(_ => {
      this.cdr.markForCheck();
    });
    this.cdr.detectChanges;
    this.updateDisabled(this.ukfes);
    this.updateDisabled(this.efeds);
    this.updateDisabled(this.efets);
    this.updateDisabled(this.efetsa);
    this.updateDisabled(this.quickentries);

    this.ukfes.changes.subscribe(elements => {
      this.updateDisabled(elements);
    });
    this.quickentries.changes.subscribe(elements => {
      this.updateDisabled(elements);
    });
    this.efeds.changes.subscribe(elements => {
      this.updateDisabled(elements);
    });
    this.efets.changes.subscribe(elements => {
      this.updateDisabled(elements);
    });
    this.efetsa.changes.subscribe(elements => {
      this.updateDisabled(elements);
    });

    this.caseRowService.refreshReadonly$?.subscribe(readonly => {
      this.updateDisabledOverride(this.ukfes, readonly);
      this.updateDisabledOverride(this.efeds, readonly);
      this.updateDisabledOverride(this.efets, readonly);
      this.updateDisabledOverride(this.efetsa, readonly);
      this.updateDisabledOverride(this.quickentries, readonly);
    });
  }

  updateDisabled(elements: QueryList<Disablable>) {
    if (elements != null) {
      elements.forEach(element => {
        element.disable(this.readOnly);
      });
    }
  }

  updateDisabledOverride(elements: QueryList<Disablable>, readOnly: boolean) {
    if (elements != null) {
      elements.forEach(element => {
        element.disable(readOnly);
      });
    }
  }
}
