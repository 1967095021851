import { Input, Directive, TemplateRef } from '@angular/core';

@Directive({
  selector: '[eTemplate]',
  host: {}
})
export class EcomTemplateDirective {
  @Input() type: string;
  @Input('eTemplate') columnName: string;

  constructor(public template: TemplateRef<any>) {}

  getType(): string {
    return this.columnName;
  }
}
