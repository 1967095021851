import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'default'
})
export class DefaultPipe implements PipeTransform {
  transform(value: any, replacement: string): any {
    return value == null || value === '' ? replacement : value;
  }
}

@Pipe({
  name: 'defaultHtml'
})
export class DefaultHtmlPipe implements PipeTransform {
  constructor(private ts: TranslateService, private dom: DomSanitizer) {}

  transform(value: any, replacement?: string): string | SafeHtml {
    replacement = replacement || this.ts.instant('shared.defaultValue');

    if (value == null || value === '')
      return this.dom.bypassSecurityTrustHtml(
        `<span class='non-active-info'>${replacement}</span>`
      );

    return value;
  }
}
