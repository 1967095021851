import { Component, HostBinding, Input, OnDestroy } from '@angular/core';

import { EcomTabDirective } from './tab.directive';
import { EcomTabsetConfig } from './tabset.config';
// todo: add active event to tab
// todo: fix? mixing static and dynamic tabs position tabs in order of creation

@Component({
  selector: 'ecom-tabset',
  template: `
    <ul [ngClass]="klasses" (click)="$event.preventDefault()">
      <li
        *ngFor="let tabz of tabs"
        [ngClass]="['nav-item', tabz.customClass || '']"
        [class.active]="tabz.active"
        [class.disabled]="tabz.disabled"
      >
        <a
          href="javascript:void(0);"
          class="nav-link"
          [class.active]="tabz.active"
          [class.disabled]="tabz.disabled"
          (click)="tabz.active = true"
        >
          <span [ecomNgTransclude]="tabz.headingRef">{{ tabz.heading }}</span>
          <span *ngIf="tabz.removable">
            <span
              (click)="$event.preventDefault(); removeTab(tabz)"
              class="fa fa-times-circle-o"
            ></span>
          </span>
        </a>
      </li>
    </ul>
    <div [class]="contentClasses">
      <ng-content></ng-content>
    </div>
  `
})
export class EcomTabsetComponent implements OnDestroy {
  /** if true tabs will be placed vertically */
  @Input()
  public get vertical(): boolean {
    return this._vertical;
  }

  public set vertical(value: boolean) {
    this._vertical = value;
  }

  /** if true tabs fill the container and have a consistent width */
  @Input()
  public get justified(): boolean {
    return this._justified;
  }
  public set justified(value: boolean) {
    this._justified = value;
  }

  /** navigation context class: 'tabs' or 'pills' */
  @Input()
  public get type(): string {
    return this._type;
  }
  public set type(value: string) {
    this._type = value;
  }

  @Input() public customClasses: string;
  @Input() public customContainerClasses: string;

  @HostBinding('class.tab-container') public clazz = true;

  public tabs: EcomTabDirective[] = [];
  public get klasses(): string {
    const klass = [];

    klass.push('nav');
    klass.push(`nav-${this.type}`);

    if (this.vertical) klass.push('nav-stacked');

    if (this.justified) klass.push('nav-justified');

    if (this.customClasses) klass.push(this.customClasses);

    return klass.join(' ');
  }

  public get contentClasses(): string {
    const klass = [];

    klass.push('tab-content');

    if (this.customContainerClasses) klass.push(this.customContainerClasses);

    return klass.join(' ');
  }

  protected isDestroyed: boolean;
  protected _vertical: boolean;
  protected _justified: boolean;
  protected _type: string;

  public constructor(config: EcomTabsetConfig) {
    Object.assign(this, config);
  }

  public ngOnDestroy(): void {
    this.isDestroyed = true;
  }

  public addTab(tab: EcomTabDirective): void {
    this.tabs.push(tab);
    tab.active = this.tabs.length === 1 && tab.active !== false;
  }

  public removeTab(
    tab: EcomTabDirective,
    options = { reselect: true, emit: true }
  ): void {
    const index = this.tabs.indexOf(tab);
    if (index === -1 || this.isDestroyed) {
      return;
    }

    // Select a new tab if the tab to be removed is selected and not destroyed
    if (options.reselect && tab.active && this.hasAvailableTabs(index)) {
      const newActiveIndex = this.getClosestTabIndex(index);
      this.tabs[newActiveIndex].active = true;
    }
    if (options.emit) {
      tab.removed.emit(tab);
    }
    this.tabs.splice(index, 1);
    if (tab.elementRef.nativeElement.parentNode) {
      tab.elementRef.nativeElement.parentNode.removeChild(
        tab.elementRef.nativeElement
      );
    }
  }

  protected getClosestTabIndex(index: number): number {
    const tabsLength = this.tabs.length;
    if (!tabsLength) {
      return -1;
    }

    for (let step = 1; step <= tabsLength; step += 1) {
      const prevIndex = index - step;
      const nextIndex = index + step;
      if (this.tabs[prevIndex] && !this.tabs[prevIndex].disabled) {
        return prevIndex;
      }
      if (this.tabs[nextIndex] && !this.tabs[nextIndex].disabled) {
        return nextIndex;
      }
    }

    return -1;
  }

  protected hasAvailableTabs(index: number): boolean {
    const tabsLength = this.tabs.length;
    if (!tabsLength) {
      return false;
    }

    for (let i = 0; i < tabsLength; i += 1) {
      if (!this.tabs[i].disabled && i !== index) {
        return true;
      }
    }
    return false;
  }
}
