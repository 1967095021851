import {
  Component,
  TemplateRef,
  ContentChild,
  Input,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { KeyFilterPattern } from 'primeng/keyfilter';
import { Observable } from 'rxjs';

@Component({
  selector: 'quickentry-column',
  templateUrl: 'quickentry-column.component.html',
  encapsulation: ViewEncapsulation.None
})
export class QuickEntryColumnComponent {
  @Input() header: string | Observable<string>;
  @Input() controlName: string;
  @Input() thClass: string;
  @Input() tdClass: string;
  @Input() customKeyFilter: KeyFilterPattern = null;
  @Input() placeholder = '';
  @Input() labelTooltip: string;
  @Input() filterValue: string;
  @Input() position: () => number = () => 99;

  @ContentChild(TemplateRef)
  public externalTemplate: TemplateRef<any>;

  @ViewChild('internalColumnTemplate', { static: true })
  public columnTemplate: TemplateRef<any>;
}
