import { TransitType } from 'api/transits';
import {
  DocumentDeliveryMethod,
  TransportType,
  ImportExportCountry,
  ClearanceCaseReason,
  ExportLogEntryStatus
} from 'api/enums';

interface TranslationLookupDict {
  [key: string]: string;
}

export class Translations {
  public static Common: TranslationLookupDict = {
    Today: 'idag',
    Loading: 'Laddar...'
  };

  public static ExportCountry: TranslationLookupDict = {
    Sweden: 'Svensk export',
    Norway: 'Norsk export',
    None: 'Ingen export'
  };

  public static ImportCountry: TranslationLookupDict = {
    Sweden: 'Svensk import',
    Norway: 'Norsk import',
    None: 'Ingen import',
    SwedenEU: 'SE import, EU-förtullning'
  };

  public static TransitType: TranslationLookupDict = {
    T1: 'T1 Transitering',
    T2: 'T2 Transitering',
    T2L: 'T2L Transitering',
    None: 'Ingen transitering'
  };

  public static DelegateType: TranslationLookupDict = {
    Direct: 'Direkte',
    Indirect: 'Indirekte'
  };

  public static ClearanceCaseReason: TranslationLookupDict = {
    NormalImport: 'Normal import',
    Return: 'Retursändning',
    Repair: 'Reparation',
    SimplifiedDeclaration: 'Hemtagning',
    Rental: 'Uthyrning'
  };

  public static ExportLogEntryStatus: TranslationLookupDict = {
    Created: 'Export börjat',
    Uploaded: 'Levererad',
    Waiting: 'Väntar',
    Stuck: 'Fastnad',
    Accepted: 'Accepterad',
    Failed: 'Avvisad',
    CustomsRejected: 'Avvisad av tullen',
    CustomsAccepted: 'Accepterad av tullen',
    CustomsReleased: 'Klarerad av tullen'
  };

  public static CaseDifficulties: TranslationLookupDict = {
    None: 'Inte satt',
    Easy: 'Lätt',
    Difficult: 'Svår'
  };

  public static CaseDocumentType: TranslationLookupDict = {
    WorkDocument: 'Underlag',
    CaseDocument: 'Deklaration'
  };

  public static CaseLength: TranslationLookupDict = {
    None: 'Inte satt',
    Short: 'Kort',
    Long: 'Långt'
  };

  public static ClearanceCaseCustomerLogEntry: TranslationLookupDict = {
    Cancelled: 'Kancellerad',
    Created: 'Skapat',
    Registered: 'Registrerat',
    Success: 'Slutfört',
    Processing: 'Handläggs',
    Waiting: 'Inväntar'
  };

  public static UserBarStatus: TranslationLookupDict = {
    Processing: 'In progress',
    Sent: 'Sent',
    Accepted: 'Accepted',
    Released: 'Released',
    Error: 'Error',
    NotInvoiced: 'Ofakturerat'
  };

  public static GoodsLocationType: TranslationLookupDict = {
    A: 'A, Anvisad plats',
    B: 'B, Godkänd plats',
    C: 'C, Godtagen plats'
  };

  public static ClearanceCaseLogEntry: TranslationLookupDict = {
    Cancelled: 'Cancelled',
    Created: 'Created',
    Registered: 'Registered',
    FirstlineProcessing: '',
    FirstlineWaitingForCustomer: 'Waiting',
    ReadyForProcessing: '',
    Processing: 'In progress',
    WaitingForCustomer: 'Waiting',
    Processed: 'Sent',
    ProcessedAwaitingCustoms: 'Sent',
    ProcessedReceivedClearing: 'Received',
    ProcessedAcceptedClearing: 'Accepted',
    ProcessedFailedClearing: 'Error',
    ProcessedCleared: 'Released',
    Completed: 'Closed'
  };

  public static DocumentDeliveryMethod: TranslationLookupDict = {
    None: 'Inte satt',
    Email: 'E-post',
    Sms: 'SMS',
    Fax: 'Fax',
    Pickup: 'Hämtas av kund'
  };

  public static DeliveryCondition: TranslationLookupDict = {
    None: 'None',
    CFR: 'Cost and FReight',
    CIF: 'Cost Insurance and Freight',
    CIP: 'Carriage and Insurance Paid to',
    CPT: 'Carriage Paid To',
    DAF: 'Delivered At Frontier',
    DDP: 'Delivered Duty Paid',
    DDU: 'Delivered Duty Unpaid',
    DES: 'Delivered Ex Ship',
    DEQ: 'Delivered Ex Quay (duty paid)',
    EXW: 'EX Works/EX Warehouse',
    DAP: 'Delivered At Place',
    DAT: 'Delivered At Terminal',
    FAS: 'Free Alongside Ship',
    FCA: 'Free CArrier',
    FOB: 'Free On Board',
    FOT: 'Free On Truck',
    XXX: 'Other'
  };

  public static ContainerLoad: TranslationLookupDict = {
    None: 'None',
    Fcl: 'FCL - Full Container Load',
    Lcl: 'LCL - Less Container Load'
  };

  public static ClearanceCase: TranslationLookupDict = {
    contact: 'Kontaktperson',
    customerContactId: 'Kontaktperson',
    proteriaId: 'ProTeria ID',
    caseNumber: 'Ärendenummer',
    sender: 'Avsändare',
    recipient: 'Mottagare',
    invoiceRecipient: 'Fakturamottagare',
    reference: 'Eget referensnr.',
    ecomId: 'ECOM ID',
    reason: 'Fraktorsak',
    transportType: '',
    transitType: 'Transitering',
    importCountry: 'Import',
    exportCountry: 'Export',
    isEuClearance: 'EU-förtullning',
    carRegistrationNumber: 'Registreringsnr. bil',
    departureUtc: 'Gränspassage datum',
    departureDate: 'Gränspassage datum',
    departureTime: 'Gränspassage tid',
    departureTimeString: 'Gränspassage tid',
    borderId: 'Gränspassage ort',
    igNumber: 'IG-NR',
    goodsNumber: 'Godsnr.',
    invoiceValue: 'Fakturavärde',
    invoiceCurrency: 'Valuta (faktura)',
    invoices: 'Fakturor',
    deliveryCondition: 'Fraktvillkor',
    deliveryCost: 'Fraktkostnad',
    deliveryCurrency: 'Valuta (frakt)',
    documentDeliveryMethod: 'Dokumentlista skickas som',
    documentDeliveryEmail: 'E-postadress',
    documentDeliveryPhoneNumber: 'Telefonnummer',
    documentDeliveryFaxNumber: 'Faxnummer',
    bookingNumberSe: 'Bokningsnummer (SE)',
    bookingNumberNo: 'Bokningsnummer (NO)',
    trailer: 'Trailer',
    containerLoad: 'FCL/LCL',
    containerNumbers: 'Containernummer',
    controlNumber: 'Kontrollnr/tullkreditnr',
    packageCount: 'Kolliantal',
    packageCategory: 'Kollislag',
    netWeight: 'Nettovikt',
    grossWeight: 'Bruttovikt',
    tollPayingOrganizationNumber: 'Betalningsansvarig Tull Org',
    tollPayingOrganizationVatNumber: 'Betalningsansvarig Moms VAT',
    airWayBill: 'H AWB / M AWB',
    transitOffices: 'Transittullkontor',
    transitDestinationOffice: 'Bestämmelsetullkontor',
    transitDepartureOffice: 'Avgångstullkontor',
    documents: 'Dokumenter',
    workDocuments: 'Underlag',
    caseDocuments: 'Deklaration',
    delegateTypeTooltip:
      'Vid direkt ombudskap handlar ombudet i deklarantens namn och för dennes räkning. Det är endast deklaranten som är gäldenär, det vill säga betalningsansvarig för tullskulden. ' +
      'Vid indirekt ombudskap handlar ombudet i eget namn men för en annan persons räkning.Det är alltså ombudet som står som deklarant. ' +
      'Uppdragsgivarens namn ska lämnas i fält 44, Särskilda upplysningar (fri text). Vid indirekt ombudskap är både ombudet och den för vars ' +
      'räkning ombudet agerat gäldenärer, det vill säga att båda är betalningsansvariga för tullskulden. ' +
      'Läs om direkt och indirekt ombudskap i tullkodexen, artikel 18 och om gäldenär i artikel 77.3. '
  };

  public static InvoicingPeriods: TranslationLookupDict = {
    Default: 'Standard',
    DefaultPoC: 'Använd kundens',
    HalfMonthly: 'Halvmånadsvis',
    Monthly: 'Månadsvis',
    Weekly: 'Veckovis',
    Yearly: 'Årsvis',
    ByCase: 'Per ärende',
    Quarterly: 'Kvartalsvis'
  };

  public static TransportType: TranslationLookupDict = {
    Car: 'Bilfrakt',
    Ship: 'Båtfrakt',
    Air: 'Flygfrakt',
    Train: 'Tågfrakt',
    Other: 'Annat tullärende'
  };

  public static DeclarantDefaultValues: TranslationLookupDict = {
    Sender: '* Avsändaren',
    Receiver: '* Mottagaren',
    Exporter: '* Exportören',
    Importer: '* Importören'
  };

  public static Validations: TranslationLookupDict = {
    ErrorTitle: 'Något gick fel',
    ErrorMessage: 'Validering misslyckades'
  };

  public static ClearanceCaseValidationError: TranslationLookupDict = {
    Unknown: 'Okänt fel',
    NoCustomer: 'Kund saknas',
    NoImportExportTransit: 'Ingen import/export/transitering',
    SameImportExportCountry: 'Samma import-/exportland',
    EuClearanceWithoutSwedishImport: 'EU-förtullning utan svensk import',
    NoCarRegistrationNumber: 'Registreringsnummer bil saknas',
    TooLongCarRegistrationNumber: 'För långt registreringsnummer bil',
    NoDepartureTime: 'Avresadatum saknas',
    NoTrailer: 'Trailer saknas',
    NoReference: 'Referensnummer saknas',
    NoBorderName: 'Gränspassage ort saknas',
    NoBookingNumberNo: 'Bokningsnummer saknas',
    NoIgNumber: 'IG-NR saknas',
    NoGoodsNumber: 'Godsnr saknas',
    NoContainerLoad: 'Containerload saknas',
    NoContainerNumbers: 'Containernummer saknas',
    EmptyContainerNumber: 'Containernummer får inte vara blankt',
    NoAirWaybill: 'AWB saknas',
    InvalidPackageCount: 'Felaktig kolliantal',
    NoPackageCategory: 'Kollislag saknas',
    InvalidGrossWeight: 'Felaktig bruttovikt',
    InvalidNetWeight: 'Felaktig nettovikt',
    InvalidInvoiceValue: 'Felaktigt fakturavärde',
    NoInvoiceCurrency: 'Valuta (faktura) saknas',
    NoDeliveryCondition: 'Fraktvillkor saknas',
    InvalidDeliveryCost: 'Felaktig fraktkostnad',
    NoDeliveryCurrency: 'Valuta (frakt) saknas',
    NoControlNumber: 'Kontrollnr/tullkreditnr saknas',
    MissingInvoiceNumber: 'Fakturanr saknas',
    NoInvoices: 'Fakturor saknas',
    InvalidInvoiceDate: 'Felaktigt fakturadatum',
    NoTransitDepartureOffice: 'Avgångstullkontor saknas',
    NoTransitDestinationOffice: 'Bestämmelsetullkontor saknas',
    NoTransitOffices: 'Transittullkontor saknas',
    NoTollPayingOrganizationNumber: 'Betalningsansvarig Tull Org saknas',
    NoTollPayingOrganizationVatNumber: 'Betalningsansvarig Moms VAT saknas',
    NoSender: 'Avsändare saknas',
    NoRecipient: 'Mottagare saknas',
    NoInvoiceRecipient: 'Fakturamottagare saknas',
    MissingSenderRecipientName: 'Namn för avsändare/mottagare saknas',
    MissingSenderRecipientAddress: 'Adress för avsändare/mottagare saknas',
    MissingSenderRecipientOrgNumber:
      'Organisationsnummer för avsändare/mottagare saknas',
    MissingSenderRecipientPostCode: 'Postnummer för avsändare/mottagare saknas',
    MissingSenderRecipientCity: 'Ort för avsändare/mottagare saknas',
    MissingSenderRecipientCountry: 'Land för avsändare/mottagare saknas',
    MissingSenderRecipientProteriaId:
      'ProTeria ID för avsändare/mottagare saknas',
    SenderNotApproved: 'Avsändare inte godkänd',
    RecipientNotApproved: 'Mottagare inte godkänd',
    InvoiceRecipientNotApproved: 'Fakturamottagare inte godkänd',
    CaseDocumentsEmpty: 'Kopplade dokument saknas',
    CaseDocumentsWrongCount:
      'Deklaration behöver lika många dokument som det finns import/exporter',
    CaseDocumentsQueued: 'Det finns dokument som väntar på å laddas upp.'
  };

  public static ProteriaImport: TranslationLookupDict = {
    Header: 'Import till ProTeria misslyckades'
  };

  public static ChangePassword: TranslationLookupDict = {
    IncorrectOldPassword: 'Gammalt lösenord är fel',
    InvalidModelState: 'Fel i formulär',
    PasswordValidationFailed: 'Felaktigt lösenordsformat',
    PasswordTooShort: 'Lösenordet måste vara minst 6 tecken',
    PasswordRequiresUniqueChars: 'Lösenordet behöver fler unika tecken',
    PasswordRequiresNonAlphanumeric:
      'Lösenordet måste innehålla minst ett specialtecken',
    PasswordRequiresLower: 'Lösenordet måste innehålla gemener',
    PasswordRequiresUpper: 'Lösenordet måste innehålla versaler',
    DefaultError: 'Fel vid byte av lösenord'
  };

  public static ViewModes: TranslationLookupDict = {
    Customer: 'Visa som kund',
    FirstLine: 'Visa som firstline',
    CaseManager: 'Visa som handläggare'
  };

  public static CountryCodes: TranslationLookupDict = {
    AD: 'Andorra',
    AE: 'Förenade Arabemiraten',
    AF: 'Afghanistan',
    AG: 'Antigua och Barbuda',
    AI: 'Anguilla',
    AL: 'Albanien',
    AM: 'Armenien',
    AO: 'Angola',
    AQ: 'Antarktis',
    AR: 'Argentina',
    AS: 'Amerikanska Samoa',
    AT: 'Österrike',
    AU: 'Australien',
    AW: 'Aruba',
    AX: 'Åland (Finland)',
    AZ: 'Azerbajdzjan',
    BA: 'Bosnien och Hercegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgien',
    BF: 'Burkina Faso',
    BG: 'Bulgarien',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint-Barthélemy',
    BM: 'Bermudas',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia',
    BQ: 'Bonaire, Sint Eustatius och Saba',
    BR: 'Brasilien',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvetön',
    BW: 'Botswana',
    BY: 'Vitryssland',
    BZ: 'Belize',
    CA: 'Kanada',
    CC: 'Kokosöarna (eller keelingöarna)',
    CD: 'Kongo Demokratiska republiken',
    CF: 'Centralafrikanska republiken',
    CG: 'Kongo (republiken)',
    CH: 'Schweiz',
    CI: 'Elfenbenskusten',
    CK: 'Cooköarna',
    CL: 'Chile',
    CM: 'Kamerun',
    CN: 'Kina',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Kuba',
    CV: 'Kap Verde',
    CW: 'Curaçao',
    CX: 'Julön',
    CY: 'Cypern',
    CZ: 'Tjeckiska Republiken',
    DE: 'Tyskland',
    DJ: 'Djibouti',
    DK: 'Danmark',
    DM: 'Dominica',
    DO: 'Dominikanska republiken',
    DZ: 'Algeriet',
    EC: 'Ecuador',
    EE: 'Estland',
    EG: 'Egypten',
    EH: 'Västsahara',
    ER: 'Eritrea',
    ES: 'Spanien',
    ESCN: 'Kanarieöarna',
    ET: 'Etiopien',
    EU: 'Europeiska unionen',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falklandsöarna',
    FM: 'Mikronesiska federationen',
    FO: 'Färöarna',
    FR: 'Frankrike',
    GA: 'Gabon',
    GB: 'Storbritannien',
    GD: 'Grenada',
    GE: 'Georgien',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Grönland',
    GM: 'Gambia',
    GN: 'Guinea',
    GQ: 'Ekvatorialguinea',
    GR: 'Grekland',
    GS: 'Sydgeorgien och Sydsandwichöarna',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea Bissau',
    GY: 'Guyana',
    HK: 'Hongkong',
    HM: 'Heard- och McDonaldöarna',
    HN: 'Honduras',
    HR: 'Kroatien',
    HT: 'Haiti',
    HU: 'Ungern',
    ID: 'Indonesien',
    IE: 'Irland',
    IL: 'Israel',
    IN: 'Indien',
    IO: 'Brittiska territoriet i Indiska Oceanen',
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Island',
    IT: 'Italien',
    JM: 'Jamaica',
    JO: 'Jordanien',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kirgizistan',
    KH: 'Kambodja (Kampuchea)',
    KI: 'Kiribati',
    KM: 'Komorerna (utom Mayotte)',
    KN: 'S:t Kittis och Nevis',
    KP: 'Nordkorea',
    KR: 'Sydkorea',
    KW: 'Kuwait',
    KY: 'Caymanöarna',
    KZ: 'Kazakstan',
    LA: 'Laos',
    LB: 'Libanon',
    LC: 'S:t Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Litauen',
    LU: 'Luxemburg',
    LV: 'Lettland',
    LY: 'Libyen',
    MA: 'Marocko',
    MD: 'Moldavien',
    ME: 'Montenegro',
    MG: 'Madagaskar',
    MH: 'Marshallöarna',
    MK: 'F.d. jugoslaviska republiken Makedonien',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongoliet',
    MO: 'Macao',
    MP: 'Norra Marianerna',
    MR: 'Mauretanien',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldiverna',
    MW: 'Malawi',
    MX: 'Mexiko',
    MY: 'Malaysia',
    MZ: 'Moçambique',
    NA: 'Namibia',
    NC: 'Nya Kaledonien',
    NE: 'Niger',
    NF: 'Norfolkön',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Nederländerna',
    NO: 'Norge',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niueön',
    NZ: 'Nya Zeeland',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'Franska Polynesien',
    PG: 'Papua Nya Guinea',
    PH: 'Filippinerna',
    PK: 'Pakistan',
    PL: 'Polen',
    PM: 'S:t Pierre och Miquelon',
    PN: 'Pitcairn',
    PS: 'Det ockuperade palestinska territoriet',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    QP: 'Det fria havet (Marint område utanför territorialhaven)',
    QQ: 'Bunkring och underhåll',
    QR: 'Bunkring och underhåll vid handel inom EU',
    QS: 'Bunkring och underhåll vid handel med tredje land',
    QU: 'Ej specificerade länder och områden',
    QV: 'Ej specificerade länder och områden vid handel inom EU',
    QW: 'Ej specificerade länder och områden vid handel med tredje land',
    QX:
      'Länder och områden som av handels- eller försvarsskäl ej specificerats',
    QY:
      'Länder och områden som av handels- eller försvarsskäl inte specificerats vid handel inom EU',
    QZ:
      'Länder och områden som av handels- eller försvarsskäl inte specificerats vid handel med tredjeland',
    RO: 'Rumänien',
    RU: 'Ryska federationen',
    RW: 'Rwanda',
    SA: 'Saudiarabien',
    SB: 'Salomonöarna',
    SC: 'Seychellerna',
    SD: 'Sudan',
    SE: 'Sverige',
    SG: 'Singapore',
    SH: 'Sankt Helena inkl. Ascension och Tristan da Cunha',
    SI: 'Slovenien',
    SK: 'Slovakien',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    SS: 'Sydsudan',
    ST: 'Sao Tomé och Principe',
    SV: 'El Salvador',
    SX: 'Sint-Maarten (nederländska delen)',
    SY: 'Syrien',
    SZ: 'Swaziland',
    TC: 'Turks- och Caicosöarna',
    TD: 'Tchad',
    TF:
      'De franska territorierna i södra Indiska oceanen (Terres australes françaises)',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tadzjikistan',
    TK: 'Tokelau',
    TL: 'Östtimor',
    TM: 'Turkmenistan',
    TN: 'Tunisien',
    TO: 'Tonga',
    TR: 'Turkiet',
    TT: 'Trinidad och Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania',
    UA: 'Ukraina',
    UG: 'Uganda',
    UM: 'Förenta staternas mindre öar',
    US: 'Amerikas förenta stater',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Vatikanstaten',
    VC: 'Saint Vincent och Grenadinerna',
    VE: 'Venezuela',
    VG: 'Brittiska Jungfruöarna',
    VI: 'Amerikanska Jungfruöarna',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis och Futunaöarna',
    WS: 'Samoa',
    XC: 'Ceuta',
    XK:
      'Kosovo (Enligt definitionen i FN:s säkerhetsråds resolution 1244 av den 10 juni 1999)',
    XL: 'Melilla',
    XS: 'Serbien',
    YE: 'Jemen',
    ZA: 'Sydafrika',
    ZM: 'Zambia',
    ZW: 'Zimbabwe'
  };

  public static NonIsoCountryCodes: TranslationLookupDict = {
    EØS: 'Det europeiske økonomiske samarbeidsområdet'
  };

  public static SignalR: TranslationLookupDict = {
    connectionErrorTitle: 'Tappade anslutning till server',
    connectionError:
      'Du ser inte ändringar andra har gjort nu. Försöker återansluta automatiskt',
    disconnectedTitle: 'Tappade anslutning till server',
    disconnectedError:
      'Lyckades inte ansluta till servern. Ändringar som andra har gjort kommer inte att synas. Försök ladda om sidan (F5)',
    reconnectedTitle: 'Förbindelsen till server återställd',
    reconnectedMessage: 'Nu kan du få automatiska uppdateringar igen',
    subscriptionErrorTitle: 'Kunde inte ansluta till kanal',
    subscriptionError:
      'Något gick fel. Automatiska uppdateringar fungerar kanske inte som dom skall. Försök ladda om sidan (F5)'
  };

  public static OverviewRow: TranslationLookupDict = {
    hasUnreadMessages: 'Oläst',
    messagesRead: 'Läst',
    noCaseManager: 'ingen handläggare'
  };

  public static CreateClearanceCaseResult: TranslationLookupDict = {
    Error: 'Fel',
    Success: 'Success',
    RequestValidationFailed: 'Validering misslyckades',
    FileNotFound: 'Hittade inte fil',
    UnknownSender: 'Okänd avsändare',
    UnknownReceiver: 'Okänd mottagare',
    UnknownInvoiceRecipient: 'Okänd fakturamottagare',
    InvalidCompany: 'Ogiltig kund',
    InvalidMainCase: 'Ogiltigt ärende',
    InvalidTransitDepartureOffice: 'Ogiltigt avgångstullkontor',
    InvalidTransitDestinationOffice: 'Ogiltigt bestämmelsetullkontor',
    InvalidTransitOffices: 'Ogiltig transittullkontor',
    DeliveryCurrencyNotFound: 'Hittade inte fraktvaluta',
    InvoiceCurrencyNotFound: 'Hittade inte fakturavaluta',
    InvalidPackageCategory: 'Okänd kollityp',
    InvalidTransactionType: 'Okänd transaktionstyp',
    InvalidTransportType: 'Okänd transporttyp',
    UnknownDeclaration: 'Okänd deklarant'
  };

  public static NorwegianTaxPayerType: TranslationLookupDict = {
    Monthly: 'M - månadskredit',
    Daily: 'D - dagsuppgör',
    Cash: 'K - kontant'
  };

  public static SwedishTaxPayerType: TranslationLookupDict = {
    Cash: 'K - Kontant',
    Declarant: 'O - Ombud',
    Invoice: 'T - Tullräkning',
    newCash: 'A - Kontant',
    newInvoice: 'E - Uppskjuten betalning'
  };

  public static GoodsLocalType: TranslationLookupDict = {
    Type1: 'R - Allmänt tullager typ I',
    Type2: 'S - Allmänt tullager typ II',
    Private: 'U - Privat tullager'
  };

  public static ClearanceCaseLogMessageType: TranslationLookupDict = {
    Normal: 'Normal',
    ChangedAssignee: 'Tok över ärende'
  };
}

window['I18ndatepicker'] = {
  months: [
    'Januari',
    'Februari',
    'Mars',
    'April',
    'Maj',
    'Juni',
    'Juli',
    'Augusti',
    'September',
    'October',
    'November',
    'December'
  ],
  weekdays: ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör']
};

export class DocumentDeliveryMethodLookup {
  public static translate(value: DocumentDeliveryMethod): string {
    return Translations.DeliveryCondition[DocumentDeliveryMethod[value]];
  }
}

export class TransitTypeLookup {
  public static translate(value: TransitType): string {
    return Translations.TransitType[TransitType[value]];
  }
}

export class TransportTypeLookup {
  public static translate(value: TransportType): string {
    return Translations.TransportType[TransportType[value]];
  }
}

export class DeliveryConditionLookup {
  public static translate(value: string): string {
    return Translations.DeliveryCondition[value];
  }
}

export class ImportExportCountryLookup {
  public static translate(
    value: ImportExportCountry,
    direction: 'import' | 'export'
  ): string {
    if (direction === 'import')
      return Translations.ImportCountry[ImportExportCountry[value]];
    else return Translations.ExportCountry[ImportExportCountry[value]];
  }
}

export class ClearanceCaseReasonLookup {
  public static translate(value: ClearanceCaseReason): string {
    return Translations.ClearanceCaseReason[ClearanceCaseReason[value]];
  }
}

export class ExportLogEntryStatusLookup {
  public static translate(value: ExportLogEntryStatus): string {
    return Translations.ExportLogEntryStatus[ExportLogEntryStatus[value]];
  }
}
