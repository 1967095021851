import { Translations } from 'app/translations';
import { CaseLengths } from 'api/enums';

export class CaseLengthLookup {
  public static translate(value: CaseLengths | number): string {
    if (typeof value === 'number')
      return Translations.CaseLength[CaseLengths[value]];

    return Translations.CaseLength[value];
  }
}
