import { ClearanceCaseCustomerStatus, ClearanceCaseStatus } from 'api/enums';
import { SelectItem } from 'shared/select-item.class';

export function getStatusIconName(status: ClearanceCaseStatus): string {
  switch (status) {
    case ClearanceCaseStatus.Cancelled:
      return 'trash';
    case ClearanceCaseStatus.Created:
    case ClearanceCaseStatus.Registered:
      return 'file';
    case ClearanceCaseStatus.Processing:
      return 'refresh';
    case ClearanceCaseStatus.WaitingForCustomer:
    case ClearanceCaseStatus.ProcessedAwaitingCustoms:
      return 'hourglass-half';
    case ClearanceCaseStatus.ProcessedFailedClearing:
      return 'close';
    case ClearanceCaseStatus.ProcessedCleared:
    case ClearanceCaseStatus.Completed:
      return 'check-square-o';
  }
  return '';
}

export function getEcomSelectableStatuses() {
  return [
    ClearanceCaseStatus.Created,
    ClearanceCaseStatus.Registered,

    ClearanceCaseStatus.ProcessedAwaitingCustoms,
    ClearanceCaseStatus.WaitingForCustomer,

    ClearanceCaseStatus.Processing,
    ClearanceCaseStatus.ProcessedAcceptedClearing,
    ClearanceCaseStatus.ProcessedCleared,

    ClearanceCaseStatus.ProcessedFailedClearing,

    ClearanceCaseStatus.Completed
  ];
}

export function getEcomDocumentSelectableStatuses() {
  return [
    ClearanceCaseStatus.Created,

    ClearanceCaseStatus.Processing,
    ClearanceCaseStatus.ProcessedAcceptedClearing,
    ClearanceCaseStatus.ProcessedCleared,
    ClearanceCaseStatus.ProcessedFailedClearing,

    ClearanceCaseStatus.Completed
  ];
}

export function getCustomerSelectableStatuses() {
  return [
    ClearanceCaseCustomerStatus.Created,
    ClearanceCaseCustomerStatus.Registered,

    ClearanceCaseCustomerStatus.Waiting,

    ClearanceCaseCustomerStatus.Processing,
    ClearanceCaseCustomerStatus.Completed
  ];
}

export function getCustomerStatusIconName(
  status: ClearanceCaseCustomerStatus
): string {
  switch (+status) {
    case ClearanceCaseCustomerStatus.Cancelled:
      return 'trash';
    case ClearanceCaseCustomerStatus.Completed:
      return 'check-square-o';
    case ClearanceCaseCustomerStatus.Created:
    case ClearanceCaseCustomerStatus.Registered:
      return 'file';
    case ClearanceCaseCustomerStatus.Processing:
      return 'refresh';
    case ClearanceCaseCustomerStatus.Waiting:
      return 'hourglass-half';
  }
  return '';
}

export function createCustomerStatusValues(): number[] {
  return getCustomerSelectableStatuses().map(value => +value);
}

export function getStatusName(values: SelectItem[], isCustomer: boolean) {
  if (isCustomer) {
    return values.map(item => ClearanceCaseCustomerStatus[+item.value]);
  }
  return values.map(item => ClearanceCaseStatus[+item.value]);
}

export function getCustomerStatusText(
  status: ClearanceCaseCustomerStatus
): string {
  const statusName = ClearanceCaseCustomerStatus[status] || '';
  return !!statusName ? `customerStatus.${statusName.toLowerCase()}` : '';
}

export function getCustomerStatusClass(
  status: ClearanceCaseCustomerStatus
): string {
  // TODO: Need to know colors for customer statuses.
  return '';
}
