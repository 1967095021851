import { Directive, forwardRef } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  UntypedFormGroup,
  ValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

// Checks that at least one clearance type (import/export/transit) is selected
@Directive({
  selector: '[ecomAtLeastOneClearanceType][ngModelGroup]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AtLeastOneClearanceTypeDirective),
      multi: true
    }
  ]
})
export class AtLeastOneClearanceTypeDirective implements Validator {
  public static get validatorFn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return new AtLeastOneClearanceTypeDirective().validate(control);
    };
  }

  validate(group: AbstractControl): ValidationErrors | null {
    if (!(group instanceof UntypedFormGroup)) return null;

    for (const key in group.controls) {
      if (group.controls.hasOwnProperty(key)) {
        const control = group.controls[key];
        if (control.value && control.value.value != 0) {
          return null;
        }
      }
    }

    return { clearanceTypeRequired: true };
  }
}
