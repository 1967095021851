import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'form-tooltip-icon',
  templateUrl: './form-tooltip-icon.component.html',
  styleUrls: ['./form-tooltip-icon.component.css'],
})
export class FormTooltipIconComponent {
  @Input() tooltipText: string;
}
