import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  UntypedFormArray,
  UntypedFormControl
} from '@angular/forms';
import { SelectItem } from 'shared/select-item.class';

@Component({
  selector: 'ecom-select-extra-time-units',
  templateUrl: './select-extra-time-units.component.html'
})
export class SelectExtraTimeUnitsComponent implements OnInit {
  @Input() control: UntypedFormControl;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  public extraTimeUnits: SelectItem[];
  private readonly maxExtraTimeUnits = 10;

  ngOnInit(): void {
    this.extraTimeUnits = this.generateExtraTimeUnitItems();
  }

  private generateExtraTimeUnitItems(): SelectItem[] {
    const items = Array(this.maxExtraTimeUnits)
      .fill(1)
      .map((x, y) => this.generateExtraTimeUnitItem(x + y));
    return [
      new SelectItem(0, 'cases.clearance.invoiceCase.extraTimeUnits.none'),
      ...items
    ];
  }

  private generateExtraTimeUnitItem(value: number): SelectItem {
    const totalMinutes = value * 30;

    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    let result = '';
    if (hours > 0) {
      result += `${hours}h`;
    }
    if (minutes > 0) {
      result += `${minutes} min`;
    }

    return new SelectItem(value, result.trim());
  }

  public onExtraTimeUnitChange(event: any): void {
    this.onChange.emit();
  }
}
