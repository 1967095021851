import { Injectable } from '@angular/core';
import { BaseService } from 'core/base.service';
import { HttpClient } from '@angular/common/http';
import { ErrorService } from 'core/error.service';
import { EditArticleRequest } from 'api/requests/edit-article-request';
import { EditArticleResponse } from 'api/responses/edit-article-response';
import { Observable, Subject } from 'rxjs';
import { EditArticleResult } from 'api/results/edit-article-result.enum';
import { ArticleDto } from 'api/models/article-dto';
import { catchError, tap, map } from 'rxjs/operators';
import { GetArticlesRequest } from 'api/requests/get-articles-request';
import { GetArticlesResponse } from 'api/responses/get-articles-response';
import { GetArticleResponse } from 'api/responses/get-article-response';
import { GetCompanyArticleResponse } from 'api/responses/get-company-article-response';
import { DefaultGetResult } from 'api/results/default-get-result.enum';

@Injectable({ providedIn: 'root' })
export class ArticlesService extends BaseService {
  private _articles$ = new Subject<ArticleDto>();
  public get articles$(): Observable<ArticleDto> {
    return this._articles$;
  }

  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/articles/', errorService);
  }

  public getArticles(
    request?: GetArticlesRequest
  ): Observable<GetArticlesResponse> {
    return this.get<GetArticlesResponse, DefaultGetResult>('', request).pipe(
      map(response => new GetArticlesResponse(response)),
      catchError(error => this.onError(new GetArticlesResponse(error)))
    );
  }

  public getArticle(id: string): Observable<GetArticleResponse> {
    return this.get<GetArticleResponse, DefaultGetResult>(`${id}`).pipe(
      map(response => new GetArticleResponse(response)),
      catchError(error => this.onError(new GetArticleResponse(error)))
    );
  }

  public getCompanyArticles(
    companyId: string
  ): Observable<GetCompanyArticleResponse> {
    return this.get<GetCompanyArticleResponse, DefaultGetResult>(
      `${companyId}`
    ).pipe(
      map(response => new GetCompanyArticleResponse(response)),
      catchError(error => this.onError(new GetCompanyArticleResponse(error)))
    );
  }

  public create(request: EditArticleRequest): Observable<EditArticleResponse> {
    return this.update(null, request);
  }

  public update(
    id: string | null,
    request: EditArticleRequest
  ): Observable<EditArticleResponse> {
    return this.post<EditArticleResponse, EditArticleResult>(
      id ? id : '',
      request
    ).pipe(
      map(response => new EditArticleResponse(response)),
      tap(response => {
        if (response.result === EditArticleResult.Success)
          this._articles$.next(response.article);
      }),
      catchError(error => this.onError(new EditArticleResponse(error)))
    );
  }
}
