import { SwedishTaxPayerType } from 'api/enums';
import { CaseOverviewColumn } from 'api/enums/case-overview-column.enum';
import { FilterMatchMode } from 'primeng/api';
import { OverviewTableColumn } from './overview-table-column';

export class OverviewTableColumns {
  static allColumns: OverviewTableColumn[] = [
    {
      icon: 'user',
      field: 'initials',
      header: '',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      field: 'department',
      header: 'cases.overview.model.department',
      displayValue: item => item.department && item.department.name,
      filterType: 'text',
      filterMatchMode: FilterMatchMode.EQUALS
    },
    {
      field: 'transportType',
      header: 'cases.overview.model.transportType',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      field: 'importDeclarationCode',
      header: 'cases.overview.model.importDeclarationCode',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      field: 'exportDeclarationCode',
      header: 'cases.overview.model.exportDeclarationCode',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      field: 'ecomId',
      header: 'cases.overview.model.ecomId',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.status',
      field: 'status',
      filterMatchMode: FilterMatchMode.EQUALS
    },
    {
      header: 'cases.overview.model.seStatus',
      field: 'seStatus',
      displayValue: item => item.seStatus,
      filterMatchMode: FilterMatchMode.EQUALS
    },
    {
      header: 'cases.overview.model.noStatus',
      field: 'noStatus',
      displayValue: item => item.seStatus,
      filterMatchMode: FilterMatchMode.EQUALS
    },
    {
      header: 'cases.overview.model.invoicingStatus.heading',
      field: 'invoicingStatus',
      filterMatchMode: FilterMatchMode.EQUALS
    },

    {
      header: 'cases.overview.model.createdDate',
      field: 'createdDate',
      filterType: 'date'
    },
    {
      header: 'cases.overview.model.customer',
      field: 'customer',
      displayValue: item => item.company.name,
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.reference',
      field: 'reference',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.invoiceRecipients.heading',
      field: 'invoiceRecipients',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS,
      displayValue: item => item.invoiceRecipientsText
    },
    {
      header: 'cases.overview.model.sender',
      field: 'sender',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.receiver',
      field: 'receiver',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.customsId',
      field: 'customsId',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.sequenceNumber',
      field: 'sequenceNumber',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.lineNumber',
      field: 'lineNumber',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.mrn',
      field: 'mrn',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.hasSlaTime',
      field: 'hasSlaTime',
      disableSort: true
    },
    {
      header: 'cases.overview.model.contact',
      field: 'contact',
      displayValue: item => item.contact && item.contact.name,
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.deliveryConditionCode',
      field: 'deliveryConditionCode',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.transportIdentityArrival',
      field: 'transportIdentityArrival',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.containerNumbers',
      field: 'containerNumbers',
      displayValue: item => item.containerNumbers.join(', '),
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.houseAirWaybill',
      field: 'houseAirWaybill',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.masterAirWaybill',
      field: 'masterAirWaybill',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.swedishTaxPayerType',
      field: 'swedishTaxPayerType',
      displayValue: item =>
        item.swedishTaxPayerType
          ? SwedishTaxPayerType[item.swedishTaxPayerType]
          : '',
      filterMatchMode: FilterMatchMode.EQUALS
    },
    {
      header: 'cases.overview.model.documents',
      field: 'documents',
      filterMatchMode: FilterMatchMode.EQUALS
    },
    {
      header: 'cases.overview.model.invoiceNumber',
      field: 'invoiceNumber',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: 'cases.overview.model.grossWeight',
      field: 'grossWeight',
      filterType: 'text',
      filterMatchMode: FilterMatchMode.CONTAINS
    },
    {
      header: '',
      icon: 'usd',
      field: 'buttons',
      filterMatchMode: FilterMatchMode.EQUALS
    },
    {
      header: '',
      disableSort: true,
      field: 'filler'
    }
  ];
  static advancedColumns = [
    CaseOverviewColumn.TransportType,
    CaseOverviewColumn.ImportDeclarationCode,
    CaseOverviewColumn.ExportDeclarationCode,
    CaseOverviewColumn.EcomId,
    CaseOverviewColumn.SeStatus,
    CaseOverviewColumn.NoStatus,
    CaseOverviewColumn.CreatedDate,
    CaseOverviewColumn.Customer,
    CaseOverviewColumn.Reference,
    CaseOverviewColumn.Mrn,
    CaseOverviewColumn.Sender,
    CaseOverviewColumn.Receiver,
    CaseOverviewColumn.CustomsId,
    CaseOverviewColumn.SequenceNumber,
    CaseOverviewColumn.LineNumber,
    CaseOverviewColumn.HasSlaTime,
    CaseOverviewColumn.Contact,
    CaseOverviewColumn.DeliveryConditionCode,
    CaseOverviewColumn.TransportIdentityArrival,
    CaseOverviewColumn.ContainerNumbers,
    CaseOverviewColumn.HouseAirWaybill,
    CaseOverviewColumn.MasterAirWaybill,
    CaseOverviewColumn.Documents,
    CaseOverviewColumn.InvoiceNumber,
    CaseOverviewColumn.GrossWeight
  ];

  static basicColumns = [
    CaseOverviewColumn.TransportType,
    CaseOverviewColumn.ImportDeclarationCode,
    CaseOverviewColumn.ExportDeclarationCode,
    CaseOverviewColumn.EcomId,
    CaseOverviewColumn.SeStatus,
    CaseOverviewColumn.NoStatus,
    CaseOverviewColumn.CreatedDate,
    CaseOverviewColumn.Customer,
    CaseOverviewColumn.Reference,
    CaseOverviewColumn.Sender,
    CaseOverviewColumn.Receiver,
    CaseOverviewColumn.CustomsId,
    CaseOverviewColumn.SequenceNumber
  ];

  static financeBasicColumns = [
    CaseOverviewColumn.InvoicingStatus,
    CaseOverviewColumn.CreatedDate,
    CaseOverviewColumn.TransportType,
    CaseOverviewColumn.ImportDeclarationCode,
    CaseOverviewColumn.ExportDeclarationCode,
    CaseOverviewColumn.CustomsId,
    CaseOverviewColumn.SequenceNumber,
    CaseOverviewColumn.Mrn,
    CaseOverviewColumn.Reference,
    CaseOverviewColumn.InvoiceRecipients,
    CaseOverviewColumn.Sender,
    CaseOverviewColumn.Receiver
  ];

  static financeAdvancedColumns = [
    CaseOverviewColumn.Department,
    CaseOverviewColumn.InvoicingStatus,
    CaseOverviewColumn.CreatedDate,
    CaseOverviewColumn.TransportType,
    CaseOverviewColumn.ImportDeclarationCode,
    CaseOverviewColumn.ExportDeclarationCode,
    CaseOverviewColumn.CustomsId,
    CaseOverviewColumn.SequenceNumber,
    CaseOverviewColumn.LineNumber,
    CaseOverviewColumn.Mrn,
    CaseOverviewColumn.Reference,
    CaseOverviewColumn.InvoiceRecipients,
    CaseOverviewColumn.Sender,
    CaseOverviewColumn.Receiver,
    CaseOverviewColumn.SwedishTaxPayerType,
    CaseOverviewColumn.InvoiceNumber
    // TODO: Transitguarantee
  ];

  static getColumnName(column: CaseOverviewColumn): string {
    var columnName = CaseOverviewColumn[column].toString();
    return columnName.charAt(0).toLowerCase() + columnName.slice(1);
  }
}
