import { SelectItemGroup as PrimeNgSelectItemGroup } from 'primeng/api/selectitemgroup';
import { SelectItem } from './select-item.class';

export class SelectItemGroup implements PrimeNgSelectItemGroup {
  public label: string;
  public value?: any;
  public items: SelectItem[];

  public constructor(label: string, items: SelectItem[], value?: any) {
    this.label = label;
    this.items = items;
    this.value = value;
  }
}
