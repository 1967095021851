import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { FileUploader, FileUploaderOptions } from 'ng2-file-upload';

import { AuthenticationService } from 'core/authentication.service';
import { environment } from 'environments/environment';
import { DocumentDto, FileDto } from 'api/models';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ecom-upload-panel',
  templateUrl: './upload-panel.component.html'
})
export class EcomUploadPanelComponent implements OnInit {
  @Output() onFileSuccess: EventEmitter<FileDto> = new EventEmitter<FileDto>();
  @Output() onCommentUpdate: EventEmitter<DocumentDto> =
    new EventEmitter<DocumentDto>();
  @Output() onDocumentDelete: EventEmitter<DocumentDto> =
    new EventEmitter<DocumentDto>();
  @Input() uploadPath: string;
  @Input() existingFiles: any[] = [];
  @Input() title: string = 'Bifoga tulldokument';
  @Input() showComments: boolean = false;
  @Input() showDelete: boolean = false;
  @Input() confirmationKey: string | undefined;
  tooLargeFiles: any[] = [];
  uploader: FileUploader;
  options: FileUploaderOptions;
  commentByDocumentId: Map<string, string> = new Map<string, string>();

  constructor(
    private authService: AuthenticationService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.options = {
      autoUpload: true,
      url: `${environment.apiHost}${this.uploadPath.replace(/^\//, '')}`,
      authToken: `Bearer ${this.authService.token.authToken}`,
      maxFileSize: environment.maxFileSize
    };

    this.uploader = new FileUploader(this.options);

    this.uploader.onSuccessItem = (
      _item: any,
      response: string,
      _status: number,
      _headers: any
    ) => {
      this.onFileSuccess.emit(JSON.parse(response));
    };

    this.uploader.onWhenAddingFileFailed = (
      item: any,
      filter: any,
      _options: any
    ) => {
      if (filter.name === 'fileSize') {
        this.tooLargeFiles.push(item);
      }
    };

    this.existingFiles.forEach((document: DocumentDto) => {
      this.commentByDocumentId.set(document.file?.id, document.comment);
    });
  }

  fileOver(event: any) {
    if (this.uploader.autoUpload !== event) {
      this.options.autoUpload = event;
      this.uploader.setOptions(this.options);
    }
  }

  get queueLength(): number {
    return this.uploader.getNotUploadedItems().length;
  }

  updateComment(item: DocumentDto) {
    this.onCommentUpdate.emit(item);
  }

  confirmDelete(document: DocumentDto) {
    this.confirmationService.confirm({
      key: this.confirmationKey,
      message: this.translateService.instant(
        'admin.customerCompanies.document.upload.confirm.message'
      ),
      acceptLabel: this.translateService.instant('shared.yes'),
      rejectLabel: this.translateService.instant('shared.no'),
      accept: () => this.deleteFile(document)
    });
  }

  deleteFile(document: DocumentDto) {
    this.existingFiles = this.existingFiles.filter(d => d.id !== document.id);
    this.onDocumentDelete.emit(document);
  }
}
