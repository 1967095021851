import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AdministrationRole, Role } from 'api/enums';
import { AuthenticationService } from 'core/authentication.service';

@Directive({
  selector: '[requireRoles]'
})
export class RequireRolesDirective implements OnInit {
  @Input('requireRoles')
  roles: Role[] = [];
  @Input()
  adminRoles: AdministrationRole[] = [];
  displayStyle: any;

  constructor(
    private element: ElementRef,
    private readonly auth: AuthenticationService
  ) {
    this.displayStyle = this.element.nativeElement.style
      ? this.element.nativeElement.style.display
      : '';
    this.element.nativeElement.style.display = 'none';
  }

  ngOnInit(): void {
    if (!this.auth.hasAnyRole(this.mapRoles(), this.mapAdminRoles())) {
      this.element.nativeElement.style.display = 'none';
    } else {
      this.element.nativeElement.style.display = this.displayStyle;
    }
  }

  private mapRoles(): Role {
    return (this.roles || []).reduce((roles, role) => roles | role, 0);
  }

  private mapAdminRoles(): AdministrationRole {
    return (this.adminRoles || []).reduce((roles, role) => roles | role, 0);
  }
}
