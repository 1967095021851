import { Injectable, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActorTypes } from 'api/enums';
import { DocumentClassification } from 'api/enums/document-classification.enum';
import { Observable, ReplaySubject, Subject } from 'rxjs';

import { SelectItem } from 'shared/select-item.class';
import { BaseRepositoryService } from '../base-repository.service';
import { ActorDto } from 'api/models';
import { concat, sortBy } from 'lodash-es';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { toCamelCase } from 'app/utils';

@Injectable()
export class SharedDataRepository
  extends BaseRepositoryService
  implements OnDestroy {
  private ngUnsubscribe$: Subject<any> = new Subject();
  actorTypeItem$: Observable<SelectItem[]>;
  documentClassifications$: Observable<SelectItem[]>;

  private _actors: ActorDto[] = [];

  public get actors(): ActorDto[] {
    return this._actors;
  }

  public set actors(value: ActorDto[]) {
    this._actors = sortBy(value, sr => sr.name.toLocaleLowerCase());
    this.actors$.next(this._actors);
  }

  updateActor(actor: ActorDto) {
    const existing = this.actors.find(s => s.id === actor.id);

    if (existing) {
      Object.assign(existing, actor);
    } else {
      this._actors = concat(this._actors, actor);
    }

    this._actors = sortBy(this._actors, sr => sr.name.toLocaleLowerCase());
    this.actors$.next(this._actors);
  }

  actors$: Subject<ActorDto[]> = new ReplaySubject();
  sender$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isSender)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  senderItems$ = this.sender$.pipe(
    map(sr => sr.map(s => new SelectItem(s.id, s.name))),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  receiver$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isReceiver)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  receiverItems$ = this.receiver$.pipe(
    map(sr => sr.map(s => new SelectItem(s.id, s.name))),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  invoiceRecipient$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isInvoice)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  invoiceRecipientItems$ = this.invoiceRecipient$.pipe(
    map(sr => sr.map(s => new SelectItem(s.id, s.name))),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  declarant$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isDeclarant)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  declarantItems$ = this.declarant$.pipe(
    map(sr =>
      sr.map(
        s =>
          new SelectItem(s.id, s.name, '', s.organizationNumber + s.eoriNumber)
      )
    ),
    map(sr => [
      new SelectItem('0', '* Avsändaren'),
      new SelectItem('1', '* Mottagaren'),
      ...sr
    ]),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  delegate$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isDelegate)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  exporter$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isExporter)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  importer$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isImporter)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  consolidator$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isConsolidator)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  shipper$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isShipper)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  manufacturer$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isManufacturer)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  warehouseOwner$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isWarehouseOwner)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  guarantee$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isGuarantee)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  customsPayer$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isCustomsPayer)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  buyer$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isBuyer)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );
  seller$ = this.actors$.pipe(
    map(sr => sr.filter(s => s.isSeller)),
    shareReplay(),
    takeUntil(this.ngUnsubscribe$)
  );

  constructor(translate: TranslateService) {
    super(translate);

    this.actorTypeItem$ = this.translateSelectItems([
      new SelectItem(
        ActorTypes.Sender,
        'cases.clearance.actor.model.type.sender'
      ),
      new SelectItem(
        ActorTypes.Receiver,
        'cases.clearance.actor.model.type.receiver'
      ),
      new SelectItem(
        ActorTypes.Invoice,
        'cases.clearance.actor.model.type.invoice'
      ),
      new SelectItem(
        ActorTypes.Declarant,
        'cases.clearance.actor.model.type.declarant'
      ),
      new SelectItem(
        ActorTypes.Delegate,
        'cases.clearance.actor.model.type.delegate'
      ),
      new SelectItem(
        ActorTypes.Exporter,
        'cases.clearance.actor.model.type.exporter'
      ),
      new SelectItem(
        ActorTypes.Importer,
        'cases.clearance.actor.model.type.importer'
      ),
      new SelectItem(
        ActorTypes.Seller,
        'cases.clearance.actor.model.type.seller'
      ),
      new SelectItem(
        ActorTypes.Buyer,
        'cases.clearance.actor.model.type.buyer'
      ),
      new SelectItem(
        ActorTypes.Consolidator,
        'cases.clearance.actor.model.type.consolidator'
      ),
      new SelectItem(
        ActorTypes.Shipper,
        'cases.clearance.actor.model.type.shipper'
      ),
      new SelectItem(
        ActorTypes.Manufacturer,
        'cases.clearance.actor.model.type.manufacturer'
      ),
      new SelectItem(
        ActorTypes.WarehouseOwner,
        'cases.clearance.actor.model.type.warehouseOwner'
      ),
      new SelectItem(
        ActorTypes.Guarantee,
        'cases.clearance.actor.model.type.guarantee'
      ),
      new SelectItem(
        ActorTypes.CustomsPayer,
        'cases.clearance.actor.model.type.customsPayer'
      )
    ]);

    this.documentClassifications$ = this.translateSelectItems([
      new SelectItem(
        DocumentClassification.ShippingLetter,
        'documents.documentClassifications.shippingletter'
      ),
      new SelectItem(
        DocumentClassification.Import,
        'documents.documentClassifications.import'
      ),
      new SelectItem(
        DocumentClassification.Export,
        'documents.documentClassifications.export'
      ),
      new SelectItem(
        DocumentClassification.ProformaInvoice,
        'documents.documentClassifications.proformainvoice'
      ),
      new SelectItem(
        DocumentClassification.Invoice,
        'documents.documentClassifications.invoice'
      ),
      new SelectItem(
        DocumentClassification.GoodsList,
        'documents.documentClassifications.goodslist'
      )
    ]);
  }
  getActor$(id: string): Observable<ActorDto> {
    return this.actors$.pipe(
      map(actors => actors.find(actor => actor.id === id))
    );
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
