<div
  class="uk-form-stacked {{ validationStatusClass }}"
  [class.uk-form-nolabel]
  *ngIf="!hidden"
>
  <label>
    {{ labelText | async }}
    <span
      *ngIf="isRequired && !displayOnly && !hideValidation"
      class="required-star"
      >*</span
    >
  </label>
  <form-tooltip-icon *ngIf="labelTooltip" [tooltipText]="labelTooltip">
  </form-tooltip-icon>
  <div *ngIf="!displayOnly">
    <div class="ui-inputgroup {{ inputClass }}" *ngIf="typeIsInputGroup">
      <ng-container>
        <input
          name="{{ name }}"
          id="{{ inputId || id || name }}"
          [formControl]="control"
          [canCalculate]="canCalculate"
          [inputFormat]="format"
          type="{{ type }}"
          [placeholder]="placeholderText | async"
          [attr.disabled]="!!disabled || control.disabled ? 'disabled' : null"
          [readonly]="readonly"
          [autocomplete]="autocomplete"
          class="uk-input {{ inputClass }}"
          [tabIndex]="tabIndex"
          (dragstart)="preventDrag($event)"
        />
      </ng-container>
    </div>

    <ng-content></ng-content>
    <div *ngIf="showError">
      <p *ngFor="let error of errors">
        <small *ngIf="error == 'maxlength' || error == 'minlength'">
          {{
            'shared.form.validation.' + error
              | translate: { requiredLength: getError(error)?.requiredLength }
          }}
        </small>
        <small *ngIf="error != 'maxlength' && error != 'minlength'">
          {{ 'shared.form.validation.' + error | translate }}
        </small>
      </p>
    </div>
  </div>
  <div
    *ngIf="displayOnly"
    class="uk-form-controls uk-form-controls-text uk-form-controls-text-display-only"
  >
    <div *ngIf="!!displayOnlyValueOverrideText">
      <span>{{ displayOnlyValueOverrideText }}</span>
    </div>
    <div *ngIf="!displayOnlyValueOverrideText">
      <span *ngIf="type == 'text' || type == 'number'">{{
        displayOnlyInputText | async
      }}</span>
      <span *ngIf="type == 'textarea'">{{ displayOnlyTextareaText }}</span>
      <span
        *ngIf="type == 'select' && (!!items || !!itemGroups) && selectedItem"
      >
        <span *ngIf="!selectedItemTemplate">{{
          displayOnlyValueOverrideText || selectedDisplayTitle(selectedItem)
        }}</span>
        <ng-container *ngIf="selectedItemTemplate">
          <ng-container
            *ngTemplateOutlet="
              selectedItemTemplate;
              context: { $implicit: selectedItem }
            "
          ></ng-container>
        </ng-container>
      </span>
      <span *ngIf="type == 'checkbox'">
        <i class="fa fa-fw fa-check-square-o" *ngIf="control.value"></i>
        <i class="fa fa-fw fa-square-o" *ngIf="!control.value"></i>
        {{ labelText | async }}
      </span>
      <span *ngIf="type == 'chips'">{{ control.value.join(', ') }}</span>
    </div>
  </div>
</div>
