import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup
} from '@angular/forms';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Collapse } from 'shared/animations/collapse.animation';
import { Disablable } from './disablable.interface';
import { allValuesEmpty } from './helpers';
import { QuickEntryBaseComponent } from './quickentry-base.component';

@Component({
  selector: 'quickentry',
  templateUrl: 'quickentry.component.html',
  styleUrls: ['./quickentry.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [Collapse()]
})
export class QuickEntryComponent
  extends QuickEntryBaseComponent
  implements OnInit, OnDestroy, Disablable, OnChanges, AfterViewInit {
  @Input() newRowFunc: () => UntypedFormGroup;
  @Input() isValidRowFunc: (row: UntypedFormGroup) => boolean;
  @Input() refresh: Observable<any>;
  @Input() touched: Boolean;
  @Input() set formArray(value: UntypedFormArray) {
    this.formArrayExternal = value;

    this.initInternalFormArray();
  }
  @Input() set refreshTrigger(value: any) {
    this.initInternalFormArray();
  }
  @Input() canRemoveRowFunc: (row: UntypedFormGroup) => boolean = row => true;

  formArrayExternal: UntypedFormArray;
  formArrayInternal: UntypedFormArray;

  initialized: boolean = false;

  constructor(private fb: UntypedFormBuilder, cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {
    this.initInternalFormArray();
  }

  setupListener() {
    this.ngUnsubscribe$.next();

    if (!!this.refresh) {
      this.refresh.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(() => {
        this.initInternalFormArray();
        this.cdr.markForCheck();
      });
    }

    this.formArrayInternal.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => {
        if (this.lastGroup) {
          if (this.isValidRow(this.lastGroup)) {
            // Add a new row if the last one is a valid row
            this.addNewRow();
          } else {
            // If the last two rows are empty we can remove the last one
            if (this.formArrayInternal.controls.length > 1) {
              if (
                !this.isValidRow(
                  this.formArrayInternal.controls[
                    this.formArrayInternal.controls.length - 2
                  ] as UntypedFormGroup
                )
              ) {
                this.removeRow(this.formArrayInternal.controls.length - 1);
              }
            }

            // Reset validation on empty rows
            for (let group of this.formArrayInternal.controls) {
              if (allValuesEmpty(group as UntypedFormGroup)) {
                for (let control in (group as UntypedFormGroup).controls) {
                  if (
                    (group as UntypedFormGroup).get(control).touched ||
                    (group as UntypedFormGroup).get(control).dirty
                  ) {
                    group.reset({ onlySelf: true, emitEvent: true });
                    return;
                  }
                }
              }
            }
          }
        }

        // Update the actual formarray
        this.formArrayExternal.controls = this.formArrayInternal.controls.slice(
          0,
          -1
        );
        this.formArrayExternal.updateValueAndValidity();
      });
  }

  initInternalFormArray() {
    this.formArrayInternal = this.fb.array([
      ...this.formArrayExternal.controls
    ]);

    this.addNewRow();

    if (this.touched) {
      this.formArrayInternal.markAllAsTouched();
    }
    this.formArrayInternal.updateValueAndValidity();

    this.setupListener();
  }

  isValidRow(row: UntypedFormGroup): boolean {
    if (!!this.isValidRowFunc) {
      return this.isValidRowFunc(row);
    }

    return !allValuesEmpty(row);
  }

  addNewRow() {
    if (this.newRowFunc) {
      let newRow = this.newRowFunc();
      if (this.isValidRow(newRow)) throw 'New row is already valid';
      this.formArrayInternal.push(newRow);
    }
  }

  get lastGroup(): UntypedFormGroup {
    if (
      this.formArrayInternal &&
      this.formArrayInternal.controls &&
      this.formArrayInternal.controls.length > 0
    ) {
      return this.formArrayInternal.controls[
        this.formArrayInternal.controls.length - 1
      ] as UntypedFormGroup;
    }

    return null;
  }

  canRemoveRow(row: UntypedFormGroup): boolean {
    return !this.displayOnly && !this.disabled && this.canRemoveRowFunc(row);
  }

  removeRow(i: number) {
    if (
      this.formArrayInternal &&
      this.formArrayInternal.controls &&
      this.formArrayInternal.controls.length > i
    ) {
      this.formArrayInternal.controls.splice(i, 1);
    }

    // Update the actual formarray
    this.formArrayExternal.controls = this.formArrayInternal.controls.slice(
      0,
      -1
    );
    this.formArrayExternal.updateValueAndValidity();
  }
}
