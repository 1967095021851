import {
  AfterViewInit,
  Directive,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';
import UIkit from 'uikit';
import { BaseModalLayoutComponent } from './base-modal-layout.component';

@Directive()
export abstract class BaseModal implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(BaseModalLayoutComponent) layout: BaseModalLayoutComponent;

  protected _identifier: string;
  protected serviceDestroyCallback: (id: string) => void;

  public abstract title: string | Observable<string>;
  public abstract bgclose: boolean;
  public abstract keyboard: boolean;

  private modal: UIkit.UIkitModalElement;
  private showAfterViewInitialized = false;

  public get identifier(): string {
    return this._identifier;
  }

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    const modalElement = this.layout.modalDiv.nativeElement;

    this.modal = UIkit.modal(modalElement, {
      stack: true,
      'bg-close': this.bgclose,
      'esc-close': this.keyboard
    });

    const util = UIkit.util as any;
    util.on(modalElement, 'hide', () => this.onHide());
    util.on(modalElement, 'show', () => this.onShow());
    util.on(modalElement, 'hidden', () => this.onHidden());
    util.on(modalElement, 'shown', () => this.onShown());

    if (this.showAfterViewInitialized) {
      this.showModal();
    }
  }

  ngOnDestroy() {}

  initializeComponent(
    identifier: string,
    serviceDestroyCallback: (id: string) => void
  ) {
    this._identifier = identifier;
    this.serviceDestroyCallback = serviceDestroyCallback;
  }

  showModal() {
    if (this.modal) {
      this.modal.show();
    } else {
      this.showAfterViewInitialized = true;
    }
  }

  hideModal(): void {
    if (this.modal) {
      this.modal.hide();
    }
  }

  protected onShow(): void {}

  protected onHide(): void {}

  protected onShown(): void {}

  protected onHidden(): void {
    this.serviceDestroyCallback(this._identifier);
  }

  childInitialized() {}
}
