<ul class="uk-pagination" [ngClass]="classMap">
  <li
    class="uk-pagination-first"
    *ngIf="boundaryLinks"
    [class.uk-disabled]="noPrevious() || disabled"
  >
    <a href (click)="selectPage(1, $event)" [innerHTML]="getText('first')"></a>
  </li>

  <li
    class="uk-pagination-previous"
    *ngIf="directionLinks"
    [class.uk-disabled]="noPrevious() || disabled"
  >
    <a
      href
      (click)="selectPage(page - 1, $event)"
      [innerHTML]="getText('previous')"
    ></a>
  </li>

  <li
    *ngFor="let pg of pages"
    [class.uk-active]="pg.active"
    [class.uk-disabled]="disabled && !pg.active"
  >
    <span *ngIf="pg.active" [innerHTML]="pg.text"></span>
    <a
      href
      (click)="selectPage(pg.number, $event)"
      [innerHTML]="pg.text"
      *ngIf="!pg.active"
    ></a>
  </li>

  <li
    class="uk-pagination-next"
    *ngIf="directionLinks"
    [class.uk-disabled]="noNext() || disabled"
  >
    <a
      href
      (click)="selectPage(page + 1, $event)"
      [innerHTML]="getText('next')"
    ></a>
  </li>

  <li
    class="uk-pagination-last"
    *ngIf="boundaryLinks"
    [class.uk-disabled]="noNext() || disabled"
  >
    <a
      href
      (click)="selectPage(totalPages, $event)"
      [innerHTML]="getText('last')"
    ></a>
  </li>
</ul>
