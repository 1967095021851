import {
  AfterViewInit,
  ContentChildren,
  HostBinding,
  Input,
  OnDestroy,
  QueryList,
  SimpleChanges,
  Directive,
  ChangeDetectorRef
} from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { sortBy } from 'lodash-es';
import { Observable, of, Subject } from 'rxjs';
import { delay, startWith, takeUntil } from 'rxjs/operators';

import { QuickEntryColumnComponent } from './quickentry-column.component';
import { QuickEntryFilter } from './quickentry-row-filter';

@Directive()
export abstract class QuickEntryBaseComponent
  implements OnDestroy, AfterViewInit {
  @HostBinding('class') hostClass = 'quickentry';
  @ContentChildren(QuickEntryColumnComponent)
  public columns: QueryList<QuickEntryColumnComponent>;

  @Input() disabled: boolean;
  @Input() displayOnly = false;
  @Input() rowFilter: QuickEntryFilter;
  @Input() sortChanged$: Observable<any>;
  @Input() trClassFunc: (row: UntypedFormGroup) => string = row => '';
  @Input() trToolTipFunc: (row: UntypedFormGroup) => string = row => '';
  @Input() sticky: 'none' | 'top' | 'toolbar' = 'none';
  constructor(public cdr: ChangeDetectorRef) {}

  abstract formArrayInternal: UntypedFormArray;
  orderedColumns: QuickEntryColumnComponent[] = [];

  get rows(): UntypedFormGroup[] {
    const rows = this.formArrayInternal.controls as UntypedFormGroup[];

    return !!this.rowFilter
      ? rows.filter(row => this.rowFilter.showRow(row))
      : rows;
  }

  protected readonly ngUnsubscribe$ = new Subject();

  ngAfterViewInit(): void {
    if (!this.columns) {
      return;
    }

    setTimeout(() => {
      this.sortColumns();
    }, 0);

    this.addChangeListener(this.columns.changes);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.sortChanged$ && changes.sortChanged$.currentValue) {
      this.addChangeListener(this.sortChanged$);
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  isRequired(control: AbstractControl): boolean {
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator.required) {
        return true;
      }
    }

    return false;
  }

  getColumnTitleText(column: QuickEntryColumnComponent): Observable<string> {
    if (typeof column.header === 'string') return of(column.header);

    return column.header;
  }

  public disable(disable: boolean) {
    this.disabled = disable;
  }

  private addChangeListener(changes$: Observable<any>) {
    changes$
      .pipe(startWith(), takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this.sortColumns());
  }

  private sortColumns() {
    const arr = this.columns.toArray();
    this.orderedColumns = sortBy(arr, c => {
      const position = c.position && c.position();
      return position >= 0 ? position : 99;
    });
    this.cdr.markForCheck();
  }
}
