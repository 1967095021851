import { CustomerUserDto } from 'api/models';

export class CustomerUser {
  public id: string;
  public phoneNumber: string;
  public email: string;
  public name: string;
  public initials: string;
  public active: boolean;

  constructor(json: CustomerUserDto) {
    this.id = json.id;
    this.phoneNumber = json.phoneNumber;
    this.email = json.email;
    this.name = json.name;
    this.initials = json.initials;
    this.active = json.active;
  }
}
