import { FilterService } from 'primeng/api';
import { ObjectUtils } from 'primeng/utils';

export class DropdownFilterService extends FilterService {
  constructor(private readonly filterByFirstMatch: boolean) {
    super();
  }

  override filter(
    value: any[],
    fields: any[],
    filterValue: any,
    filterMatchMode: string,
    filterLocale?: string
  ) {
    if (!this.filterByFirstMatch) {
      return super.filter(
        value,
        fields,
        filterValue,
        filterMatchMode,
        filterLocale
      );
    }

    let filteredItems: any[] = [];

    if (value) {
      for (let field of fields) {
        for (let item of value) {
          const fieldValue = ObjectUtils.resolveFieldData(item, field);
          if (
            this.filters[filterMatchMode](fieldValue, filterValue, filterLocale)
          ) {
            filteredItems.push(item);
          }
        }
        if (filteredItems.length > 0) {
          return filteredItems;
        }
      }
    }

    return filteredItems;
  }
}
