<div
  class="uk-form-stacked {{ validationStatusClass }}"
  [class.uk-form-nolabel]
  *ngIf="(control || type == 'errors') && name && !hidden"
>
  <label *ngIf="type == 'checkbox' && !noLabel">{{ groupLabel }}</label>
  <label
    for="{{ name }}"
    *ngIf="type != 'checkbox' && type != 'errors' && !noLabel"
  >
    <span *ngIf="!!flag" class="flag-icon flag-icon-{{ flag }}"></span>
    {{ labelText | async }}
    <span
      *ngIf="isRequired && !displayOnly && !hideValidation"
      class="required-star"
      >*</span
    >
  </label>
  <form-tooltip-icon *ngIf="labelTooltip" [tooltipText]="labelTooltip">
  </form-tooltip-icon>
  <div
    *ngIf="!displayOnly"
    class=""
    [class.uk-form-controls-check]="type == 'checkbox'"
  >
    <div class="ui-inputgroup {{ inputClass }}" *ngIf="typeIsInputGroup">
      <ng-container *ngFor="let addon of addonsLeft">
        <span
          class="ui-inputgroup-addon {{ addon.class }}"
          *ngIf="!addonIsClickable(addon)"
          [style.flex]="addon.width ? '1 1 ' + addon.width + 'px' : null"
          [class.ui-inputgroup-addon-set-width]="!!addon.width"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          tabIndex="-1"
        >
          <input
            *ngIf="addonIsCheckbox(addon)"
            type="checkbox"
            name="{{ addon.checkName }}"
            id="{{ addon.checkName }}"
            [formControl]="addon.checkControl"
            [tabIndex]="addon.tabIndex"
            [attr.disabled]="!!disabled ? 'disabled' : null"
            (dragstart)="preventDrag($event)"
          />
          <label *ngIf="addonIsCheckbox(addon)" for="{{ addon.checkName }}">
            <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
            <span *ngIf="addon.label"> {{ addon.label }}</span>
          </label>
          <i
            class="fa fa-fw fa-{{ addon.icon }}"
            *ngIf="!addonIsCheckbox(addon) && addon.icon"
          ></i>
          <span *ngIf="!addonIsCheckbox(addon) && addon.label">{{
            addon.label
          }}</span>
        </span>
        <button
          type="button"
          class="uk-button {{ addon.class }}"
          *ngIf="addonIsClickable(addon)"
          [disabled]="
            !!addon.disabled || (disabled && !addon.enabledInReadOnly)
          "
          (click)="clickAddon(addon, $event)"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          [tabIndex]="addon.tabIndex"
          [class.uk-button-danger]="
            addon.validationControl &&
            (addon.validationControl.touched ||
              addon.validationControl.dirty ||
              submitted) &&
            !addon.validationControl.valid
          "
        >
          <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
          <span *ngIf="addon.label">{{ addon.label }}</span>
        </button>
      </ng-container>
      <ng-container *ngIf="typeIsInput">
        <ng-container *ngIf="!isAutoComplete">
          <input
            name="{{ name }}"
            id="{{ inputId || id || name }}"
            [formControl]="control"
            [inputFormat]="format"
            type="{{ type }}"
            [placeholder]="placeholderText | async"
            [attr.disabled]="!!disabled || control.disabled ? 'disabled' : null"
            [readonly]="readonly"
            [autocomplete]="autocomplete"
            [canCalculate]="canCalculate"
            class="uk-input {{ inputClass }}"
            [tabIndex]="tabIndex"
            (dragstart)="preventDrag($event)"
          />
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let addon of addonsRight">
        <span
          class="ui-inputgroup-addon {{ addon.class }}"
          *ngIf="!addonIsClickable(addon)"
          [style.flex]="addon.width ? '1 1 ' + addon.width + 'px' : null"
          [class.ui-inputgroup-addon-set-width]="!!addon.width"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          tabIndex="-1"
        >
          <input
            *ngIf="addonIsCheckbox(addon)"
            type="checkbox"
            name="{{ addon.checkName }}"
            id="{{ addon.checkName }}"
            [formControl]="addon.checkControl"
            [tabIndex]="addon.tabIndex"
            [attr.disabled]="!!disabled ? 'disabled' : null"
            (dragstart)="preventDrag($event)"
          />
          <label *ngIf="addonIsCheckbox(addon)" for="{{ addon.checkName }}">
            <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
            <span *ngIf="addon.label"> {{ addon.label }}</span>
          </label>
          <i
            class="fa fa-fw fa-{{ addon.icon }}"
            *ngIf="!addonIsCheckbox(addon) && addon.icon"
          ></i>
          <span *ngIf="!addonIsCheckbox(addon) && addon.label">{{
            addon.label
          }}</span>
        </span>
        <button
          type="button"
          class="uk-button {{ addon.class }}"
          *ngIf="addonIsClickable(addon)"
          [disabled]="
            !!addon.disabled || (disabled && !addon.enabledInReadOnly)
          "
          (click)="clickAddon(addon, $event)"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          [tabIndex]="addon.tabIndex"
          [class.uk-button-danger]="
            addon.validationControl &&
            (addon.validationControl.touched ||
              addon.validationControl.dirty ||
              submitted) &&
            !addon.validationControl.valid
          "
        >
          <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
          <span *ngIf="addon.label">{{ addon.label }}</span>
        </button>
      </ng-container>
    </div>
    <ng-content></ng-content>
    <div *ngIf="showError">
      <p *ngFor="let error of errors">
        <small *ngIf="error == 'maxlength' || error == 'minlength'">
          {{
            'shared.form.validation.' + error
              | translate: { requiredLength: getError(error)?.requiredLength }
          }}
        </small>
        <small *ngIf="error != 'maxlength' && error != 'minlength'">
          {{ 'shared.form.validation.' + error | translate }}
        </small>
      </p>
    </div>
    <div *ngIf="showWarning">
      <p *ngFor="let warning of warnings">
        <small>
          {{ 'shared.form.validation.' + warning | translate }}
        </small>
      </p>
    </div>
    <p *ngFor="let helpText of helpTexts" class="uk-form-help-block">
      {{ helpText | translate }}
    </p>
  </div>
</div>
