import { Observable } from 'rxjs';

export class ConfirmConfig {
  title: string;
  message: string;
  confirmText: string;
  cancelText: string;
  confirmIcon = 'fa fa-check';
  cancelIcon = '';
  confirmClass = 'uk-button-primary';
  cancelClass = 'uk-button-default';
  onConfirm: () => void = () => {};
  onCancel: () => void = () => {};
}
