import {
  Injectable,
  ViewContainerRef,
  Type,
  ComponentRef
} from '@angular/core';
import { BaseModal } from './base-modal.compenent';
import { Guid } from 'guid-typescript';

@Injectable({ providedIn: 'root' })
export class IOModalService {
  public viewContainerRef: ViewContainerRef;
  public modals: { [id: string]: ComponentRef<any> } = {};

  constructor() {}

  create<T extends BaseModal>(component: Type<T>, initialData: any): T {
    const componentRef = this.viewContainerRef.createComponent(component);

    Object.assign(componentRef.instance, initialData || {});

    const identifier = Guid.create().toString();
    componentRef.instance.initializeComponent(identifier, (id: string) => {
      this.modals[id]?.destroy();
      delete this.modals[id];
    });

    this.modals[identifier] = componentRef;

    return componentRef.instance;
  }

  setViewContainerRef(viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
}
