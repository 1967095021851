import { OnInit, OnDestroy, AfterViewInit, Directive } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BasePopover implements OnInit, OnDestroy, AfterViewInit {
  protected _identifier: string;
  protected serviceDestroyCallback: (id: string) => void;

  public get identifier(): string {
    return this._identifier;
  }

  abstract getPanel(): OverlayPanel;

  private ngUnsubscribe$ = new Subject();

  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  initializeComponent(
    identifier: string,
    serviceDestroyCallback: (id: string) => void
  ) {
    this._identifier = identifier;
    this.serviceDestroyCallback = serviceDestroyCallback;

    this.getPanel()
      .onShow.pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this._onShow());
    this.getPanel()
      .onHide.pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => this._onHide());
  }

  show(originalEvent: any, target?: any) {
    this.getPanel().show(originalEvent, target);
  }

  hide() {
    this.getPanel().hide();
  }

  protected _onShow(): void {}

  protected _onHide(): void {
    this.serviceDestroyCallback(this._identifier);
  }
}
