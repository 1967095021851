import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { GetCaseRowColumnSettingsResponse } from 'api/responses';
import { GetCaseRowColumnSettingsResult } from 'api/results';
import { CaseRowColumn } from 'api/enums';
import {
  GetCaseOverviewColumnSettingsRequest,
  SetCaseOverviewFilterSettingsRequest,
  SetCaseRowColumnSettingsRequest
} from 'api/requests';
import { CaseOverviewColumn } from 'api/enums';
import { SetCaseOverviewColumnSettingsRequest } from 'api/requests';
import { GetCaseOverviewColumnSettingsResponse } from 'api/responses';
import { GetCaseOverviewColumnSettingsResult } from 'api/results';
import { ColumnSettingsViewMode } from 'api/enums';
import { CaseOverviewType } from 'cases-overview/models';
import { CaseOverviewColumnSettingsDto } from 'api/models';

@Injectable({ providedIn: 'root' })
export class SettingsService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/settings/', errorService);
  }

  getCaseRowColumns(): Observable<GetCaseRowColumnSettingsResponse> {
    return this.get<
      GetCaseRowColumnSettingsResponse,
      GetCaseRowColumnSettingsResult
    >('caserowcolumns').pipe(
      map(response => response.body),
      catchError(error => this.onError(error))
    );
  }

  setCaseRowColumns(
    columns: CaseRowColumn[],
    viewMode: ColumnSettingsViewMode,
    editColumns: boolean = false
  ) {
    const request: SetCaseRowColumnSettingsRequest = {
      columns: columns.map(column => ({
        column: column,
        position: columns.indexOf(column) // Ignored by the API, the order of the columns is used
      })),
      viewMode: viewMode,
      editColumns
    };

    return this.put<
      GetCaseRowColumnSettingsResponse,
      GetCaseRowColumnSettingsResult
    >('caserowcolumns', request).pipe(
      map(response => response.body),
      catchError(error => this.onError(error))
    );
  }

  getCaseOverviewColumns(
    request: GetCaseOverviewColumnSettingsRequest
  ): Observable<GetCaseOverviewColumnSettingsResponse> {
    return this.post<
      GetCaseOverviewColumnSettingsResponse,
      GetCaseOverviewColumnSettingsResult
    >('caseoverviewcolumns', request).pipe(
      map(response => response.body),
      catchError(error => this.onError(error))
    );
  }

  setCaseOverviewColumns(
    columns: CaseOverviewColumn[],
    viewMode: ColumnSettingsViewMode,
    type: CaseOverviewType,
    editColumns: boolean = false,
    filterId: string = null
  ): Observable<GetCaseOverviewColumnSettingsResponse> {
    const request: SetCaseOverviewColumnSettingsRequest = {
      columns: columns.map(column => ({
        column: column,
        position: columns.indexOf(column) // Ignored by the API, the order of the columns is used
      })),
      caseViewMode: type == CaseOverviewType.Cases ? viewMode : null,
      financialViewMode: type == CaseOverviewType.Finance ? viewMode : null,
      editColumns: editColumns,
      caseOverviewColumnSettingsToUseId: filterId
    };

    return this.put<
      GetCaseOverviewColumnSettingsResponse,
      GetCaseOverviewColumnSettingsResult
    >('caseoverviewcolumns', request).pipe(
      map(response => response.body),
      catchError(error => this.onError(error))
    );
  }

  setCaseOverviewFilters(request: SetCaseOverviewFilterSettingsRequest) {
    return this.put<
      GetCaseOverviewColumnSettingsResponse,
      GetCaseOverviewColumnSettingsResult
    >('caseoverviewfilter', request).pipe(
      map(response => response.body),
      catchError(error => this.onError(error))
    );
  }

  deleteCaseOverviewFilter(id: string) {
    return this.delete<
      GetCaseOverviewColumnSettingsResponse,
      GetCaseOverviewColumnSettingsResult
    >(`caseoverviewfilter/${id}`).pipe(
      map(response => response.body),
      catchError(error => this.onError(error))
    );
  }
}
