<div
  class="uk-form-stacked {{ validationStatusClass }}"
  [class.uk-form-nolabel]
  *ngIf="(control || type == 'errors') && name && !hidden"
  [@collapse]
>
  <label *ngIf="type == 'checkbox' && !noLabel">{{ groupLabel }}</label>
  <label
    for="{{ name }}"
    *ngIf="type != 'checkbox' && type != 'errors' && !noLabel"
  >
    <span *ngIf="!!flag" class="flag-icon flag-icon-{{ flag }}"></span>
    {{ labelText | async }}
    <span
      *ngIf="isRequired && !displayOnly && !hideValidation"
      class="required-star"
      >*</span
    >
  </label>
  <form-tooltip-icon *ngIf="labelTooltip" [tooltipText]="labelTooltip">
  </form-tooltip-icon>
  <div
    *ngIf="!displayOnly"
    class=""
    [class.uk-form-controls-check]="type == 'checkbox'"
  >
    <p-chips
      *ngIf="type == 'chips'"
      inputId="{{ inputId || id || name }}"
      id="{{ inputId || id || name }}"
      [formControl]="control"
      [placeholder]="placeholderText | async"
      [addOnBlur]="true"
      [addOnTab]="true"
      inputStyleClass="uk-input"
      [tabindex]="tabIndex"
      [disabled]="disabled"
    ></p-chips>
    <div class="ui-inputgroup {{ inputClass }}" *ngIf="typeIsInputGroup">
      <ng-container *ngFor="let addon of addonsLeft">
        <span
          class="ui-inputgroup-addon {{ addon.class }}"
          *ngIf="!addonIsClickable(addon)"
          [style.flex]="addon.width ? '1 1 ' + addon.width + 'px' : null"
          [class.ui-inputgroup-addon-set-width]="!!addon.width"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          tabIndex="-1"
        >
          <input
            *ngIf="addonIsCheckbox(addon)"
            type="checkbox"
            name="{{ addon.checkName }}"
            id="{{ addon.checkName }}"
            [formControl]="addon.checkControl"
            [tabIndex]="addon.tabIndex"
            [attr.disabled]="!!disabled ? 'disabled' : null"
            (dragstart)="preventDrag($event)"
          />
          <label *ngIf="addonIsCheckbox(addon)" for="{{ addon.checkName }}">
            <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
            <span *ngIf="addon.label"> {{ addon.label }}</span>
          </label>
          <i
            class="fa fa-fw fa-{{ addon.icon }}"
            *ngIf="!addonIsCheckbox(addon) && addon.icon"
          ></i>
          <span *ngIf="!addonIsCheckbox(addon) && addon.label">{{
            addon.label
          }}</span>
        </span>
        <button
          type="button"
          class="uk-button {{ addon.class }}"
          *ngIf="addonIsClickable(addon)"
          [disabled]="
            !!addon.disabled || (disabled && !addon.enabledInReadOnly)
          "
          (click)="clickAddon(addon, $event)"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          [tabIndex]="addon.tabIndex"
          [class.uk-button-danger]="
            addon.validationControl &&
            (addon.validationControl.touched ||
              addon.validationControl.dirty ||
              submitted) &&
            !addon.validationControl.valid
          "
        >
          <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
          <span *ngIf="addon.label">{{ addon.label }}</span>
        </button>
      </ng-container>
      <p-calendar
        *ngIf="type == 'date'"
        [readonlyInput]="readOnlyInput"
        [placeholder]="placeholderText | async"
        [formControl]="control"
        [locale]="calendarLocale"
        [showWeek]="showWeek"
        [dateFormat]="dateformat"
        inputId="{{ inputId || id || name }}"
        appendTo="body"
        (onShow)="calendarShown($event)"
        [disabled]="disabled"
        [inputStyleClass]="inputStyleClass ? inputStyleClass : 'uk-input'"
        [tabindex]="tabIndex"
        p-keyboard
        [styleClass]="styleClass"
        selectOtherMonths="true"
        [firstDayOfWeek]="calendarLocale.firstDayOfWeek"
        (dragstart)="preventDrag($event)"
        (onInput)="emitOnCalendarInput($event)"
      >
      </p-calendar>
      <ng-container *ngIf="typeIsInput">
        <ng-container *ngIf="!isAutoComplete">
          <input
            *ngIf="!customKeyFilter"
            name="{{ name }}"
            id="{{ inputId || id || name }}"
            [formControl]="control"
            [inputFormat]="format"
            type="{{ type }}"
            [placeholder]="placeholderText | async"
            [attr.disabled]="!!disabled || control.disabled ? 'disabled' : null"
            [readonly]="readonly"
            [autocomplete]="autocomplete"
            [canCalculate]="canCalculate"
            class="uk-input {{ inputClass }}"
            [tabIndex]="tabIndex"
            (dragstart)="preventDrag($event)"
          />
          <input
            *ngIf="!!customKeyFilter"
            name="{{ name }}"
            id="{{ inputId || id || name }}"
            [formControl]="control"
            [pKeyFilter]="!!customKeyFilter ? customKeyFilter : undefined"
            type="{{ type }}"
            [placeholder]="placeholderText | async"
            [attr.disabled]="!!disabled || control.disabled ? 'disabled' : null"
            [readonly]="readonly"
            [autocomplete]="autocomplete"
            class="uk-input {{ inputClass }}"
            [tabIndex]="tabIndex"
            (dragstart)="preventDrag($event)"
          />
        </ng-container>
        <ng-container *ngIf="isAutoComplete">
          <p-autoComplete
            *ngIf="!customKeyFilter"
            [disabled]="disabled"
            id="{{ inputId || id || name }}"
            [suggestions]="autoCompleteSuggestions"
            inputStyleClass="uk-input"
            (completeMethod)="autoCompleteMethod($event.query)"
            appendTo="body"
            [formControl]="control"
            type="{{ type }}"
            [placeholder]="placeholderText | async"
            [tabindex]="tabIndex"
            class="uk-width-1-1"
          >
            <ng-template let-item pTemplate="selectedItem">
              <ng-container *ngIf="!selectedItemTemplate">{{
                selectedDisplayTitle(item)
              }}</ng-container>
              <ng-container *ngIf="selectedItemTemplate">
                <ng-container
                  *ngTemplateOutlet="
                    selectedItemTemplate;
                    context: { $implicit: item }
                  "
                ></ng-container>
              </ng-container>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <ng-container *ngIf="!itemTemplate">{{
                itemDisplayTitle(item)
              }}</ng-container>
              <ng-container *ngIf="itemTemplate">
                <ng-container
                  *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
                ></ng-container>
              </ng-container>
            </ng-template>
          </p-autoComplete>
          <p-autoComplete
            *ngIf="!!customKeyFilter"
            id="{{ inputId || id || name }}"
            [suggestions]="autoCompleteSuggestions"
            inputStyleClass="uk-input"
            (completeMethod)="autoCompleteMethod($event.query)"
            appendTo="body"
            [formControl]="control"
            type="{{ type }}"
            [placeholder]="placeholderText | async"
            [pKeyFilter]="customKeyFilter"
            [tabindex]="tabIndex ? tabIndex : -1"
            [disabled]="disabled"
          >
            <ng-template let-item pTemplate="selectedItem">
              <ng-container *ngIf="!selectedItemTemplate">{{
                selectedDisplayTitle(item)
              }}</ng-container>
              <ng-container *ngIf="selectedItemTemplate">
                <ng-container
                  *ngTemplateOutlet="
                    selectedItemTemplate;
                    context: { $implicit: item }
                  "
                ></ng-container>
              </ng-container>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <ng-container *ngIf="!itemTemplate">{{
                itemDisplayTitle(item)
              }}</ng-container>
              <ng-container *ngIf="itemTemplate">
                <ng-container
                  *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
                ></ng-container>
              </ng-container>
            </ng-template>
          </p-autoComplete>
        </ng-container>
      </ng-container>
      <ng-container *ngFor="let addon of addonsRight">
        <span
          class="ui-inputgroup-addon {{ addon.class }}"
          *ngIf="!addonIsClickable(addon)"
          [style.flex]="addon.width ? '1 1 ' + addon.width + 'px' : null"
          [class.ui-inputgroup-addon-set-width]="!!addon.width"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          tabIndex="-1"
        >
          <input
            *ngIf="addonIsCheckbox(addon)"
            type="checkbox"
            name="{{ addon.checkName }}"
            id="{{ addon.checkName }}"
            [formControl]="addon.checkControl"
            [tabIndex]="addon.tabIndex"
            [attr.disabled]="!!disabled ? 'disabled' : null"
            (dragstart)="preventDrag($event)"
          />
          <label *ngIf="addonIsCheckbox(addon)" for="{{ addon.checkName }}">
            <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
            <span *ngIf="addon.label"> {{ addon.label }}</span>
          </label>
          <i
            class="fa fa-fw fa-{{ addon.icon }}"
            *ngIf="!addonIsCheckbox(addon) && addon.icon"
          ></i>
          <span *ngIf="!addonIsCheckbox(addon) && addon.label">{{
            addon.label
          }}</span>
        </span>
        <button
          type="button"
          class="uk-button {{ addon.class }}"
          *ngIf="addonIsClickable(addon)"
          [disabled]="
            !!addon.disabled || (disabled && !addon.enabledInReadOnly)
          "
          (click)="clickAddon(addon, $event)"
          data-uk-tooltip="{pos:'top'}"
          [title]="tooltipText(addon) | async"
          [tabIndex]="addon.tabIndex"
          [class.uk-button-danger]="
            addon.validationControl &&
            (addon.validationControl.touched ||
              addon.validationControl.dirty ||
              submitted) &&
            !addon.validationControl.valid
          "
        >
          <i class="fa fa-fw fa-{{ addon.icon }}" *ngIf="addon.icon"></i>
          <span *ngIf="addon.label">{{ addon.label }}</span>
        </button>
      </ng-container>
    </div>
    <p-inputSwitch
      *ngIf="type == 'switch'"
      [formControl]="control"
      (onChange)="onSelectChange($event)"
      [styleClass]="styleClass"
      [tabindex]="tabIndex"
      [disabled]="disabled"
    ></p-inputSwitch>
    <p-listbox
      *ngIf="type == 'listbox' && items"
      [options]="items"
      [formControl]="control"
      [styleClass]="styleClass"
      (onChange)="onSelectChange($event)"
      [multiple]="multiple"
      [checkbox]="multiple"
      [showToggleAll]="false"
      [styleClass]="inputClass"
      [tabindex]="tabIndex"
      [disabled]="disabled"
    ></p-listbox>
    <p-selectButton
      *ngIf="type == 'selectbutton' && items"
      [options]="items"
      [formControl]="control"
      [disabled]="disabled"
      (onChange)="onSelectChange($event)"
      [multiple]="multiple"
      [styleClass]="styleClass"
      [tabindex]="tabIndex"
    >
      <ng-template let-item pTemplate="item">
        <ng-container *ngIf="!itemTemplate">
          <span class="ui-button-text ui-clickable ng-star-inserted">{{
            itemDisplayTitle(item)
          }}</span>
        </ng-container>
        <ng-container *ngIf="itemTemplate">
          <ng-container
            *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"
          ></ng-container>
        </ng-container>
      </ng-template>
    </p-selectButton>
    <textarea
      *ngIf="type == 'textarea'"
      name="{{ name }}"
      id="{{ inputId || id || name }}"
      [formControl]="control"
      [attr.disabled]="!!disabled || control.disabled ? 'disabled' : null"
      [placeholder]="placeholderText | async"
      class="uk-textarea {{ inputClass }} {{ inputStyleClass }}"
      [tabIndex]="tabIndex"
    ></textarea>
    <input
      *ngIf="type == 'checkbox'"
      type="checkbox"
      name="{{ name }}"
      id="{{ inputId || id || name }}"
      [formControl]="control"
      [tabIndex]="tabIndex"
      [attr.disabled]="!!disabled || control.disabled ? 'disabled' : null"
      (dragstart)="preventDrag($event)"
    />
    <label *ngIf="type == 'checkbox'" for="{{ name }}">
      {{ labelText | async }}</label
    >
    <ng-content></ng-content>
    <div *ngIf="showError">
      <p *ngFor="let error of errors">
        <small *ngIf="error == 'maxlength' || error == 'minlength'">
          {{
            'shared.form.validation.' + error
              | translate: { requiredLength: getError(error)?.requiredLength }
          }}
        </small>
        <small *ngIf="error != 'maxlength' && error != 'minlength'">
          {{ 'shared.form.validation.' + error | translate }}
        </small>
      </p>
    </div>
    <div *ngIf="showWarning">
      <p *ngFor="let warning of warnings">
        <small>
          {{ 'shared.form.validation.' + warning | translate }}
        </small>
      </p>
    </div>
    <p *ngFor="let helpText of helpTexts" class="uk-form-help-block">
      {{ helpText | translate }}
    </p>
  </div>
  <div
    *ngIf="displayOnly"
    class="uk-form-controls uk-form-controls-text uk-form-controls-text-display-only"
  >
    <div *ngIf="!!displayOnlyValueOverrideText">
      <span>{{ displayOnlyValueOverrideText }}</span>
    </div>
    <div *ngIf="!displayOnlyValueOverrideText">
      <span *ngIf="type == 'text' || type == 'number'">{{
        displayOnlyInputText | async
      }}</span>
      <span *ngIf="type == 'textarea'">{{ displayOnlyTextareaText }}</span>
      <span *ngIf="type == 'checkbox'">
        <i class="fa fa-fw fa-check-square-o" *ngIf="control.value"></i>
        <i class="fa fa-fw fa-square-o" *ngIf="!control.value"></i>
        {{ labelText | async }}
      </span>
      <span *ngIf="type == 'chips'">{{ control.value.join(', ') }}</span>
    </div>
  </div>
</div>
