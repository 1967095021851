import { Component, Input } from '@angular/core';
import md5 from 'md5';

@Component({
  selector: 'gravatar',
  template: `<img
    class="my-gravatar"
    [width]="size"
    [height]="size"
    [src]="gravatarUrl"
  />`
})
export class GravatarComponent {
  @Input() defaultStyle = 'retro';
  @Input() email: string;
  @Input() size = 80;

  get gravatarUrl(): string {
    const defaultStyle = encodeURIComponent(this.defaultStyle);
    return `https://secure.gravatar.com/avatar/${md5(
      (this.email || '').toLowerCase().trim()
    )}?d=${defaultStyle}&s=${this.size}`.replace(/\s/g, '');
  }
}
