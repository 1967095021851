<p-overlayPanel #confirmPopover appendTo="body">
  <div><strong>{{ config.title | translate }}</strong></div>
  <div class="confirm-message">{{ config.message | translate }}</div>
  <div>
    <a class="uk-button {{ config.confirmClass }}" (click)="onConfirm()">
      <i *ngIf="config.confirmIcon" class="{{ config.confirmIcon }}"></i>
      {{ config.confirmText | translate }}
    </a>
    <a class="uk-button {{ config.cancelClass }}" (click)="onCancel()">
      <i *ngIf="config.cancelIcon" class="{{ config.cancelIcon }}"></i>
      {{ config.cancelText | translate }}
    </a>
  </div>
</p-overlayPanel>
