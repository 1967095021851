<ecom-form-element-dropdown
  type="select"
  name="extraTime"
  [showClear]="true"
  [control]="control"
  [items]="extraTimeUnits"
  [label]="'cases.clearance.invoiceCase.extraTimeUnits' | translate"
  (onChange)="onExtraTimeUnitChange($event)"
>
  <ng-template let-item pTemplate="selectedItem">
    {{ item.label | translate }}
  </ng-template>
  <ng-template let-item pTemplate="item">
    {{ item.label | translate }}
  </ng-template>
</ecom-form-element-dropdown>
