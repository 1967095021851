import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, shareReplay } from 'rxjs/operators';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { GetPackageCategoriesResponse } from 'api/responses';
import { GetPackageCategoriesResult } from 'api/results';

@Injectable()
export class PackageCategoriesService extends BaseService {
  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/cases/', errorService);
  }

  getAll(_forceRefresh?: boolean): Observable<GetPackageCategoriesResponse> {
    // TODO: Since this list is large and rarely changes it should be cached.
    return this.get<GetPackageCategoriesResponse, GetPackageCategoriesResult>(
      'packagecategories'
    ).pipe(
      map(response => new GetPackageCategoriesResponse(response)),
      catchError(error =>
        this.onError(new GetPackageCategoriesResponse(error))
      ),
      shareReplay()
    );
  }
}
