import { WindowCommand } from './window-command';
import { environment } from 'environments/environment';

export class OpenUrlCommand extends WindowCommand {
  public url: string;

  public constructor(url: string) {
    super('open-uri');
    this.url = this.formatUrl(url);
  }

  public get data() {
    return {
      url: this.url
    };
  }

  private formatUrl(input: string): string {
    if (input.startsWith('http://')) {
      // FUCK!
      return `${environment.windowProxy}/${encodeURIComponent(input)}`;
    }

    return input;
  }
}
