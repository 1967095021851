import {
  UntypedFormGroup,
  UntypedFormControl,
  UntypedFormArray,
  AbstractControl,
  Validators
} from '@angular/forms';
import { InvoicesFormArray } from 'cases/models/forms/invoices.form-array';

export function validateAllFormFields(formGroup: UntypedFormGroup) {
  Object.keys(formGroup.controls).forEach(field => {
    const control = formGroup.get(field);
    if (control instanceof UntypedFormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof UntypedFormGroup) {
      validateAllFormFields(control);
    } else if (control instanceof InvoicesFormArray) {
      control.setValidators(Validators.required);
      control.updateValueAndValidity({
        onlySelf: true,
        emitEvent: false
      });
      control.markAllAsTouched();
    } else if (control instanceof UntypedFormArray) {
      for (let c of (control as UntypedFormArray).controls) {
        if (c instanceof UntypedFormControl) {
          c.markAsTouched({ onlySelf: true });
        } else if (c instanceof UntypedFormGroup) {
          validateAllFormFields(c);
        }
      }
    }
  });
}

export function allValuesEmpty(group: UntypedFormGroup): boolean {
  for (let control in group.controls) {
    if (group.get(control).value) {
      return false;
    }
  }

  return true;
}

export function getFormErrors(form: AbstractControl) {
  if (form instanceof UntypedFormControl) {
    // Return FormControl errors or null
    return form.errors || null;
  }
  if (form instanceof UntypedFormGroup || form instanceof UntypedFormArray) {
    const groupErrors = form.errors;
    // Form group can contain errors itself, in that case add'em
    const formErrors = groupErrors ? { groupErrors } : {};
    Object.keys(form.controls).forEach(key => {
      // Recursive call of the FormGroup fields
      const error = getFormErrors(form.get(key));
      if (error !== null) {
        // Only add error if not null
        formErrors[key] = error;
      }
    });
    // Return FormGroup errors or null
    return Object.keys(formErrors).length > 0 ? formErrors : null;
  }

  return null;
}
