import {
  OnInit,
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ContentChildren,
  QueryList,
  AfterContentInit,
  TemplateRef,
  ChangeDetectionStrategy
} from '@angular/core';
import { ITableConfig, ITableConfigColumn } from './models';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { BasePaginationRequest } from 'api/shared/base-pagination-request';
import { EcomTemplateDirective } from 'shared/ecom-template.directive';

@Component({
  selector: 'pagination-table',
  templateUrl: './pagination-table.component.html',
  styleUrls: ['./pagination-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationTableComponent
  implements OnInit, OnDestroy, AfterContentInit {
  formSubscription: Subscription;

  @Input() set config(value: ITableConfig) {
    this.cfg = value;

    if (this.form !== this.cfg.form) {
      if (this.formSubscription) {
        this.formSubscription.unsubscribe();
      }

      this.form = this.cfg.form;

      if (!this.pageNumber) {
        this.form.addControl('pageNumber', new UntypedFormControl(1));
      }
      if (!this.pageSize) {
        this.form.addControl('pageSize', new UntypedFormControl(this.initialPageSize));
      }
      if (!this.sorting) {
        this.form.addControl(
          'sorting',
          new UntypedFormControl(this.cfg.defaultSortingField)
        );
      }
      if (!this.sortingOrderDescending) {
        this.form.addControl('sortingOrderDescending', new UntypedFormControl(false));
      }
      this.formSubscription = this.form.valueChanges.subscribe(() =>
        this.onFormChanges()
      );
    }
  }
  @Input() scrollable: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() emitFormValueChanges = false;
  @Input() sticky: 'none' | 'top' | 'toolbar' = 'none';
  @Input() initialPageSize: number = 25;

  ngAfterContentInit() {
    this.templates.forEach((item: EcomTemplateDirective) => {
      this.templateRefs[item.getType()] = item.template;
    });
  }

  @ContentChildren(EcomTemplateDirective) templates: QueryList<any>;
  private templateRefs: { [columnName: string]: TemplateRef<any> } = {};
  @Input() items: any[];
  @Input() valignTop: boolean;
  @Input() trackBy: (index: number, item: any) => string;

  @Output()
  formChanged: EventEmitter<BasePaginationRequest> = new EventEmitter<
    BasePaginationRequest
  >();

  form: UntypedFormGroup;
  public get sorting() {
    return this.form.get('sorting');
  }
  public get sortingOrderDescending() {
    return this.form.get('sortingOrderDescending');
  }
  public get pageNumber() {
    return this.form.get('pageNumber');
  }
  public get pageSize() {
    return this.form.get('pageSize');
  }

  public hasTemplate(column: ITableConfigColumn): boolean {
    return !!this.templateFor(column);
  }

  public templateFor(column: ITableConfigColumn): TemplateRef<any> {
    if (this.templateRefs[column.field]) return this.templateRefs[column.field];
    return null;
  }

  cfg: ITableConfig;

  selectedItems: any[] = [];

  get allItems(): any[] {
    if (this.isSelectable) {
      this.selectedItems.concat(this.items.filter(i => !this.isSelected(i)));
    }

    return this.items;
  }

  get isSelectable(): boolean {
    return !!this.cfg.selectable && !!this.cfg.selectable.itemEquals;
  }

  public isSelected(item: any) {
    if (this.isSelectable) {
      return this.selectedItems.find(i =>
        this.cfg.selectable.itemEquals(i, item)
      );
    }

    return false;
  }

  public get sortingObject() {
    return {
      field: this.sorting.value,
      orderDescending: this.sortingOrderDescending.value
    };
  }

  pageChanged(event) {
    this.form.patchValue(
      Object.assign({}, this.form.value, { pageNumber: event.page }),
      { emitEvent: this.emitFormValueChanges }
    );
    if (!this.emitFormValueChanges) {
      this.onFormChanges();
    }
  }

  public get hasRowClickEvent(): boolean {
    return !!this.cfg.onRowClick;
  }

  public get hasMainColumn(): boolean {
    return (
      this.cfg.columns.filter(
        c => !!c.tdClass && c.tdClass.indexOf('mainCol') >= 0
      ).length > 0
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.formSubscription.unsubscribe();
  }

  changeSorting(sort: string) {
    const descending =
      this.sorting.value === sort ? !this.sortingOrderDescending.value : false;
    this.form.patchValue(
      {
        pageNumber: 1,
        sorting: sort,
        sortingOrderDescending: descending
      },
      { emitEvent: this.emitFormValueChanges }
    );
    if (!this.emitFormValueChanges) {
      this.onFormChanges();
    }
  }

  getSortingIconClass(sort: string) {
    const value = this.form.value;
    return {
      'fa-sort': value.sorting !== sort,
      'fa-sort-up': value.sorting === sort && value.sortingOrderDescending,
      'fa-sort-down': value.sorting === sort && !value.sortingOrderDescending
    };
  }

  getThClass(sort: string) {
    return {
      active: this.form.value.sorting === sort
    };
  }
  public onRowClick(event: MouseEvent, item: any): void {
    if (this.isSelectable) {
      if (this.isSelected(item)) {
        this.selectedItems.forEach((r, i) => {
          if (this.cfg.selectable.itemEquals(r, item))
            this.selectedItems.splice(i, 1);
        });
      } else {
        this.selectedItems.push(item);
      }
    }

    if (!this.hasRowClickEvent) {
      return;
    }

    let element = <HTMLElement>event.srcElement;

    while (element) {
      if (element.nodeName.toLowerCase() === 'a') {
        return;
      }

      element = <HTMLElement>element.parentElement;
    }

    this.cfg.onRowClick(item);
  }

  onFormChanges() {
    this.formChanged.emit({
      page: this.pageNumber.value,
      pageSize: this.pageSize.value,
      sortingDescending: this.sortingOrderDescending.value,
      sortingField: this.sorting.value
    });
  }

  hasEnabledMenuItems(item: any): boolean {
    return (
      this.cfg &&
      this.cfg.menuItems &&
      this.cfg.menuItems.some(
        menuItem => !menuItem.condition || menuItem.condition(item)
      )
    );
  }
}
