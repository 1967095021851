import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ClearanceCaseInvoiceDto } from 'api/models';

export class InvoiceForm extends UntypedFormGroup {
  constructor(source: ClearanceCaseInvoiceDto = null) {
    super({
      id: new UntypedFormControl(source ? source.id : null),
      invoiceNumber: new UntypedFormControl(
        (source ? source.invoiceNumber : '') || '',
        Validators.required
      ),
      invoiceDate: new UntypedFormControl(
        source ? source.invoiceDate : null,
        Validators.required
      ),
      attachedDocumentSubtypeId: new UntypedFormControl(
        source ? source.attachedDocumentSubtypeId : null
      ),
      isInvoiceNumberRequired: new UntypedFormControl(
        source ? source.isInvoiceNumberRequired : false
      )
    });
  }
}
