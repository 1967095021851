import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { CaseRowColumn } from 'api/enums';
import { ClearanceCaseRowForm } from 'cases/models/forms';
import { TableLazyLoadEvent } from 'primeng/table';
import { equals } from 'shared/busy/util';

@Injectable()
export class PaginationService {
  public first: number = 0;
  public numberOfRows: number = 25;
  public descending: boolean;
  public isLoading: boolean = false;
  public sortField: string;
  public oldValues: any[];

  public sortStringField(rowsToSort: any[], field: string) {
    return this.descending
      ? rowsToSort.sort((a, b) =>
          a.get(field).value.localeCompare(b.get(field).value)
        )
      : rowsToSort.sort((a, b) =>
          b.fDescription.value.localeCompare(a.get(field).value)
        );
  }

  public setEventValues(event: TableLazyLoadEvent) {
    this.first = event?.first ?? this.first;
    this.numberOfRows = event?.rows ?? this.numberOfRows;
    this.descending = event?.sortOrder != 1 ? true : false;
    this.sortField = event?.sortField as string ?? this.sortField;
  }

  public updateIsLoading(rows: any[]) {
    this.isLoading = !equals(this.oldValues, rows);
  }

  public getIsLoading(rows: any[]) {
    return equals(this.oldValues, rows);
  }

  public sortNumberField(rowsToSort: any[], field: string) {
    return this.descending
      ? rowsToSort.sort((a, b) => a.get(field).value - b.get(field).value)
      : rowsToSort.sort((a, b) => b.get(field).value - a.get(field).value);
  }

  updateMetaValues(rows: any[], loading: boolean) {
    this.isLoading = loading;
    this.oldValues = rows;
  }

  public getRows(array: any[]) {
    return array.slice(this.first, this.totalRows);
  }

  get totalRows() {
    return this.first + (this.numberOfRows - 1);
  }
}

@Injectable()
export class PaginationRowService extends PaginationService {
  public sortFields(formArray: ClearanceCaseRowForm[]) {
    switch (this.sortField.toString()) {
      case 'index':
        return this.descending ? formArray : formArray.reverse();
      case CaseRowColumn.StatNumberSe.toString():
        return this.sortNumberField(formArray, 'statNumberSe');
      case CaseRowColumn.StatNumberNo.toString():
        return this.sortNumberField(formArray, 'statNumberNo');
      case CaseRowColumn.Description.toString():
        return this.sortStringField(formArray, 'description');
      case CaseRowColumn.GoodsMarking.toString():
        return this.sortStringField(formArray, 'goodsMarking');
      case CaseRowColumn.GrossWeight.toString():
        return this.sortNumberField(formArray, 'grossWeight');
      case CaseRowColumn.NetWeight.toString():
        return this.sortNumberField(formArray, 'netWeight');
      case CaseRowColumn.Price.toString():
        return this.sortNumberField(formArray, 'price');
      case CaseRowColumn.PackageCount.toString():
        return this.sortNumberField(formArray, 'packageCount');
      case CaseRowColumn.OtherQuantity.toString():
        return this.sortNumberField(formArray, 'otherQuantity');
      case CaseRowColumn.OtherQuantityNo.toString():
        return this.sortStringField(formArray, 'otherQuantityNo');
      case CaseRowColumn.OriginCountryCode.toString():
        return this.sortStringField(formArray, 'originCountryCode');
      case CaseRowColumn.ChassisNumber.toString():
        return this.sortStringField(formArray, 'chassisNumber');
      default:
        return formArray;
    }
  }

  public searchFilter(
    rows: ClearanceCaseRowForm[],
    searchField: string
  ): ClearanceCaseRowForm[] {
    return rows.filter(
      r =>
        r.isInitialized &&
        ((r.value.description.toLocaleLowerCase() as string).includes(
          searchField.toLocaleLowerCase()
        ) ||
          (r.value.statNumberSe.toLocaleLowerCase() as string).includes(
            searchField.toLocaleLowerCase()
          ) ||
          (r.value.originCountryCode.toLocaleLowerCase() as string).includes(
            searchField.toLocaleLowerCase()
          ) ||
          (r.value.statNumberNo.toLocaleLowerCase() as string).includes(
            searchField.toLocaleLowerCase()
          ))
    );
  }
}
