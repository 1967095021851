import { Directive, forwardRef } from '@angular/core';
import {
  NG_VALIDATORS,
  Validator,
  UntypedFormGroup,
  ValidatorFn,
  AbstractControl,
  ValidationErrors
} from '@angular/forms';

// Checks import and export countries are not the same if they are selected.
@Directive({
  selector: '[ecomDifferentImportExportCountry][ngModelGroup]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DifferentImportExportCountryDirective),
      multi: true
    }
  ]
})
export class DifferentImportExportCountryDirective implements Validator {
  public static get validatorFn(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return new DifferentImportExportCountryDirective().validate(control);
    };
  }

  validate(group: AbstractControl): { [index: string]: any } {
    if (!(group instanceof UntypedFormGroup)) return null;

    const importTypeControl = group.controls['importType'];
    const exportTypeControl = group.controls['exportType'];

    if (importTypeControl == null || exportTypeControl == null) {
      return null;
    }

    let importType = importTypeControl.value;
    let exportType = exportTypeControl.value;

    if (importType === 0 || exportType === 0 || importType !== exportType) {
      return null;
    }

    return { sameImportExportCountry: true };
  }
}
