export abstract class WindowCommand {
  commandType: string;

  constructor(commandType: string) {
    this.commandType = commandType;
  }

  public abstract get data();

  public toMessage() {
    return {
      command: this.commandType,
      data: this.data
    };
  }
}
