<form [formGroup]="form" class="uk-form" *ngIf="!!form">
  <div class="uk-card uk-card-default uk-padding-small uk-margin">
    <uikit-form-element
      name="name"
      [control]="form.fName"
      label="{{ 'cases.clearance.actor.model.name' | translate }}"
    ></uikit-form-element>
    <uikit-form-element
      name="organizationNumber"
      [control]="form.fOrganizationNumber"
      label="{{ 'cases.clearance.actor.model.organizationNumber' | translate }}"
      size="sm"
    ></uikit-form-element>
    <uikit-form-element
      name="eoriNumber"
      [control]="form.fEoriNumber"
      label="{{ 'cases.clearance.actor.model.eoriNumber' | translate }}"
      size="sm"
    ></uikit-form-element>
    <uikit-form-element
      *ngIf="fullEdit"
      name="declareVat"
      type="checkbox"
      [control]="form.fDeclareVat"
      label="{{ 'cases.clearance.actor.model.declareVat' | translate }}"
      size="sm"
    ></uikit-form-element>
    <uikit-form-element
      *ngIf="fullEdit"
      name="tollCredit"
      [control]="form.fTollCredit"
      label="{{ 'cases.clearance.actor.model.tollCredit' | translate }}"
    ></uikit-form-element>
    <uikit-form-element
      *ngIf="fullEdit"
      name="hasTollCredit"
      type="checkbox"
      [control]="form.fHasTollCredit"
      label="{{ 'cases.clearance.actor.model.hasTollCredit' | translate }}"
    ></uikit-form-element>
    <uikit-form-element
      *ngIf='fullEdit'
      name='isExternallyAvailable'
      type='checkbox'
      [control]='form.fIsExternallyAvailable'
      label="{{'cases.clearance.actor.model.isExternallyAvailable' | translate }}"
    >
    </uikit-form-element>
  </div>
  <div class="uk-card uk-card-default uk-padding-small uk-margin">
    <uikit-form-element
      name="address"
      [control]="form.fAddress"
      label="{{ 'cases.clearance.actor.model.address' | translate }}"
    ></uikit-form-element>
    <uikit-form-element
      name="postCode"
      [control]="form.fPostCode"
      label="{{ 'cases.clearance.actor.model.postCode' | translate }}"
      size="xs"
    ></uikit-form-element>
    <uikit-form-element
      name="city"
      [control]="form.fCity"
      label="{{ 'cases.clearance.actor.model.city' | translate }}"
      size="sm"
    ></uikit-form-element>
    <ecom-form-element-dropdown
      type="select"
      [items]="countryCodeItems"
      name="countryCode"
      [showClear]="true"
      [control]="form.fCountryCode"
      label="{{ 'cases.clearance.actor.model.countryCode' | translate }}"
      placeholder="{{
        'cases.clearance.actor.model.countryCode.placeholder' | translate
      }}"
    ></ecom-form-element-dropdown>
  </div>
  <div class="uk-card uk-card-default uk-padding-small uk-margin">
    <uikit-form-element
      name="contactName"
      [control]="form.fContactName"
      label="{{ 'cases.clearance.actor.model.contactName' | translate }}"
    ></uikit-form-element>
    <uikit-form-element
      name="contactPhoneNumber"
      [control]="form.fContactPhoneNumber"
      label="{{ 'cases.clearance.actor.model.contactPhoneNumber' | translate }}"
    ></uikit-form-element>
    <uikit-form-element
      name="contactEmail"
      [control]="form.fContactEmail"
      label="{{ 'cases.clearance.actor.model.contactEmail' | translate }}"
    ></uikit-form-element>
  </div>
  <div class="uk-card uk-card-default uk-padding-small uk-margin">
    <uikit-form-element
      *ngIf="fullEdit"
      type="textarea"
      name="caseManagementNote"
      size="lg"
      inputStyleClass="comment-textarea"
      [control]="form.fCasemanagementNote"
      label="{{ 'cases.clearance.actor.model.caseManagementNote' | translate }}"
    ></uikit-form-element>
    <uikit-form-element
      *ngIf="fullEdit"
      type="textarea"
      name="financialsNote"
      size="lg"
      inputStyleClass="comment-textarea"
      [control]="form.fFinancialsNote"
      label="{{ 'cases.clearance.actor.model.financialsNote' | translate }}"
    ></uikit-form-element>
  </div>
  <div class="uk-card uk-card-default uk-padding-small uk-margin">
    <uikit-form-element
      type="selectbutton"
      [items]="repository.actorTypeItem$ | async"
      name="types"
      [control]="form.fTypes"
      label="{{ 'cases.clearance.actor.model.type' | translate }}"
      [multiple]="true"
    ></uikit-form-element>
    <uikit-form-element type="errors" [control]="form" name="errors">
    </uikit-form-element>
  </div>
  <div class="uk-card uk-card-default uk-padding-small uk-margin">
    <h4>
      {{ 'cases.clearance.actor.mailRecipient.title' | translate }}
    </h4>
    <div formArrayName="mailRecipients">
      <div
        class="uk-padding-small uk-margin"
        *ngFor="let item of form.fMailRecipients.controls; let i = index"
      >
        <uikit-form-element
          name="'mailAddress[' + i + ']'"
          [control]="getRecipientAddressControl(item)"
          [addons]="[{ icon: 'trash', action: 'removeMailRecipient' }]"
          (onAddonAction)="removeMailRecipient(i)"
          label="{{
            'cases.clearance.actor.mailRecipient.model.mailAddress' | translate
          }}"
          size="lg"
        ></uikit-form-element>
        <uikit-form-element
          type="selectbutton"
          name="'documentsToReceive[' + i + ']'"
          [items]="repository.documentClassifications$ | async"
          [multiple]="true"
          [control]="getRecipientDocumentsControl(item)"
          label="{{
            'cases.clearance.actor.model.documentsToReceive' | translate
          }}"
        ></uikit-form-element>
      </div>
      <a
        *ngIf="!isReadOnly"
        class="uk-button uk-margin-small-top"
        (click)="addMailRecipient()"
        >{{ 'cases.clearance.actor.mailRecipient.addNew' | translate }}
      </a>
    </div>
    <p *ngIf="form.get('id').value">
      {{ 'cases.clearance.actor.model.id' | translate }}:
      {{ form.get('id').value }}
    </p>
  </div>

  <div
    *ngIf="this.needsApproval"
    class="uk-card uk-card-default uk-padding-small uk-margin"
  >
    <h4>
      {{ 'cases.clearance.actor.externallycreated.title' | translate }}
    </h4>
    <p class="uk-text-small">
      {{ 'cases.clearance.actor.externallycreated.description' | translate }}
    </p>
    <a
      *ngIf="this.form.get('needsApproval').value"
      class="uk-button uk-margin-small-top"
      (click)="approveActor()"
      >{{ 'cases.clearance.actor.externallycreated.approve' | translate }}
    </a>
    <p *ngIf="!this.form.get('needsApproval').value" class="uk-text-success">
      {{ 'cases.clearance.actor.externallycreated.success' | translate }}
    </p>
  </div>
</form>
