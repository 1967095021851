import { GetPaginatedCasesResponse } from 'api/responses/get-paginated-cases-response';
import { OverviewClearanceCase } from './overview-clearance-case.model';

export class OverviewResponseData {
  cases: OverviewClearanceCase[];
  public countCreated: number = 0;
  public countRegistered: number = 0;
  public countWaiting: number = 0;
  public countUnread: number = 0;
  public countProcessing: number = 0;
  public countUninvoiced: number = 0;
  public pageSize: number = 0;
  public totalCount: number = 0;

  constructor(response: GetPaginatedCasesResponse, isCustomer: boolean) {
    this.cases = response.cases.items.map(
      clearanceCase => new OverviewClearanceCase(clearanceCase, isCustomer)
    );

    this.countCreated = response.countCreated;
    this.countRegistered = response.countRegistered;
    this.countWaiting = response.countWaiting;
    this.countUnread = response.countUnread;
    this.countProcessing = response.countProcessing;
    this.countUninvoiced = response.countUninvoiced;
    this.pageSize = response.pageSize;
    this.totalCount = response.totalCount;
  }
}
