import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError, Subject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

import { BaseService } from 'core/base.service';
import { ErrorService } from 'core/error.service';
import { IError } from 'core/ierror.interface';
import {
  GetActorsResponse,
  GetActorResponse,
  EditActorResponse
} from 'api/responses';
import { ActorTypes } from 'api/enums';
import { ActorDto, ActorOverviewDto } from 'api/models';
import { EditActorResult, GetActorResult, GetActorsResult } from 'api/results';
import { EditActorRequest } from 'api/requests/edit-actor-request';
import { GetActorsRequest } from 'api/admin';
import { PaginatedListGeneric } from 'api/shared';

@Injectable()
export class ActorsService extends BaseService {
  private _actors$ = new Subject<ActorDto>();
  public get actors$(): Observable<ActorDto> {
    return this._actors$;
  }

  constructor(http: HttpClient, errorService: ErrorService) {
    super(http, 'api/actors/', errorService);
  }

  getAllForCompany(
    companyId: string,
    type: ActorTypes = ActorTypes.None
  ): Observable<GetActorsResponse> {
    return this.get<GetActorsResponse, GetActorsResult>(
      `company/${companyId}?type=${type}`
    ).pipe(
      map(response => new GetActorsResponse(response)),
      catchError(error => this.onError(new GetActorsResponse(error)))
    );
  }

  getAll(type: ActorTypes = ActorTypes.None): Observable<GetActorsResponse> {
    return this.get<GetActorsResponse, GetActorsResult>(`?type=${type}`).pipe(
      map(response => new GetActorsResponse(response)),
      catchError(error => this.onError(new GetActorsResponse(error)))
    );
  }

  getPaginatedActors(request: GetActorsRequest) {
    return this.http
      .post<PaginatedListGeneric<ActorOverviewDto>>(
        `${this.apiWithPrefix}pagination`,
        request
      )
      .pipe(catchError(error => this.onError(error)));
  }

  getSingle(actorId: string): Observable<GetActorResponse> {
    return this.get<GetActorResponse, GetActorResult>(actorId).pipe(
      map(response => new GetActorResponse(response)),
      catchError(error => this.onError(new GetActorResponse(error)))
    );
  }

  getActorDataFromProteria(actorId: number): Observable<GetActorResponse> {
    return this.get<GetActorResponse, GetActorResult>(
      `proteria/${actorId}`
    ).pipe(
      map(response => new GetActorResponse(response)),
      catchError(error =>
        this.onGetActorDataFromProteriaError(new GetActorResponse(error))
      )
    );
  }

  create(request: EditActorRequest): Observable<EditActorResponse> {
    return this.post<EditActorResponse, EditActorResult>('', request).pipe(
      map(response => new EditActorResponse(response)),
      tap(response => {
        if (response.result === EditActorResult.Success)
          this._actors$.next(response.actor);
      }),
      catchError(error => this.onError(new EditActorResponse(error)))
    );
  }

  update(
    actorId: string,
    request: EditActorRequest
  ): Observable<EditActorResponse> {
    return this.put<EditActorResponse, EditActorResult>(actorId, request).pipe(
      map(response => new EditActorResponse(response)),
      tap(response => {
        if (response.result === EditActorResult.Success)
          this._actors$.next(response.actor);
      }),
      catchError(error => this.onError(new EditActorResponse(error)))
    );
  }

  cancel(actorId: string): Observable<boolean> {
    return this.http
      .delete(`${this.apiWithPrefix}${actorId}`)
      .pipe(map(() => true));
  }

  private onGetActorDataFromProteriaError(response: GetActorResponse) {
    const error: IError = {
      message: response.error,
      validationErrors: response.requestValidationErrors
    };

    if (response.hasOwnProperty('httpStatus')) {
      error.httpStatus = response['httpStatus'];
      error.httpStatusText = response['httpStatusText'];

      if (error.httpStatus === 503) {
        error.title = error.title || 'Kommunikationsfel';
        error.message =
          error.message ||
          'Fick inget svar från ProTeria. Försök igen lite senare';
      }

      // Only send an error if it's different from Bad Request
      // Bad Request gets handled by the component
      if (error.httpStatus !== 400) this.errorService.next(error);
    }

    return throwError(response);
  }
}
