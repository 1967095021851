import { UserDepartmentDto } from 'api/models';

export class OverviewDepartment {
  constructor(department: UserDepartmentDto) {
    this.department = department;
    this.isActive = false;
    this.isLoading = false;
    this.gotUpdated = false;
    this.unreadCaseCount = 0;
  }

  department: UserDepartmentDto;
  isActive: boolean;
  isLoading: boolean;
  gotUpdated: boolean;
  unreadCaseCount: number;

  get hasUnreadCases(): boolean {
    return this.unreadCaseCount > 0;
  }
}
